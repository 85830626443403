// Material Components
// Colors
import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';

// Styles
import { Theme } from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';

import { ApplicationStyles, Fonts, Metrics } from '../../Theme';

const styles = (theme: Theme) => createStyles({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: Fonts.fontSizeLarge,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    ...ApplicationStyles.flexRowAlignCenter,
  },
  margin: {
    margin: theme.spacing(1),
  },
  snackbar: {
    marginTop: Metrics.largeXLMarginOrPaddingBase,
  },
});

export default styles;
