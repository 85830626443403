// Libraries
import { Reducer } from 'redux';

// Constants
import Constants from '../../Utils/Constants';
import { VitalSignsActionsTypes } from './VitalSignsActions';

const initialState: IVitalSignsReducerType = {
  lastAction: null,
  vitalSignsData: [],
  currentPage: 0,
  rowsPerPage: Constants.limits.page_limit,
  totalSigns: 0,
  searchTerm: null,
};

const reducer: Reducer<IVitalSignsReducerType, IAction> = (state = initialState, action: IAction): IVitalSignsReducerType => {
  switch (action.type) {
    case VitalSignsActionsTypes.SET_LAST_ACTION:
      return {
        ...state,
        lastAction: action.value,
      };
    case VitalSignsActionsTypes.SET_VITAL_SIGNS_DATA:
      return {
        ...state,
        vitalSignsData: action.value.data,
        totalSigns: action.value.count,
      };
    case VitalSignsActionsTypes.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.value,
      };
    case VitalSignsActionsTypes.SET_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.value,
      };
    case VitalSignsActionsTypes.SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
