import { to } from 'await-to-js';
import { cloneDeep, each, find, findIndex, isUndefined, pullAt } from 'lodash';
import { batch } from 'react-redux';

// Utils
import { dispatchLoadingTable, dispatchShowDialog, dispatchShowSpinner } from '../App/AppUtils';
import { concatResponse, dispatchDataResponse, parsingDates, validateErrorFromClientRequest } from '../ReducersUtils';
import { setMeasureOptions } from './Utils';

// External Actions
import { set_loading_dropdown } from '../App/AppActions';

// Service
import executeRequest from '../../Services/ServiceBase';

export enum AnthropometricMeasureActionTypes {
  SET_ANTHROPOMETRIC_MEASURE = 'anthropometric_measure/SET_ANTHROPOMETRIC_MEASURE',
  SET_CURRENT_MEASURE = 'anthropometric_measure/SET_CURRENT_MEASURE',
  SET_CURRENT_PAGE = 'anthropometric_measure/SET_CURRENT_PAGE',
  SET_LAST_ACTION = 'anthropometric_measure/SET_LAST_ACTION',
  SET_MEASURE_OPTIONS = 'anthropometric_measure/SET_MEASURE_OPTIONS',
  SET_MEASURE_REPONSE = 'anthropometric_measure/SET_MEASURE_REPONSE',
  SET_ROWS_PER_PAGE = 'anthropometric_measure/SET_ROWS_PER_PAGE',
  SET_SEARCH_MEASURE_TERM = 'anthropometric_measure/SET_SEARCH_MEASURE_TERM',
  SET_SEARCH_TERM = 'anthropometric_measure/SET_SEARCH_TERM',
  SET_SELECTED_SEARCH_MEASURE = 'anthropometric_measure/SET_SELECTED_SEARCH_MEASURE',
  SET_DEFAULT_LOOKUP = 'anthropometric_measure/SET_DEFAULT_LOOKUP',
}

export const set_current_page = (page: number): IAction => ({ type: AnthropometricMeasureActionTypes.SET_CURRENT_PAGE, value: page });
export const set_measure_options = (value: IOptionsData[]): IAction => ({ type: AnthropometricMeasureActionTypes.SET_MEASURE_OPTIONS, value });
export const set_rows_per_page = (value: number): IAction => ({ type: AnthropometricMeasureActionTypes.SET_ROWS_PER_PAGE, value });
export const set_search_term = (value: string): IAction => ({ type: AnthropometricMeasureActionTypes.SET_SEARCH_TERM, value });
export const set_search_measure_term = (value: string): IAction => ({ type: AnthropometricMeasureActionTypes.SET_SEARCH_MEASURE_TERM, value });

export const set_current_measure = (value: IAnthropometricMeasureData): IAction => ({
  type: AnthropometricMeasureActionTypes.SET_CURRENT_MEASURE,
  value,
});

export const set_anthropometric_measures = (value: { data: IAnthropometricMeasureData[], count: number }): IAction => ({
  type: AnthropometricMeasureActionTypes.SET_ANTHROPOMETRIC_MEASURE,
  value,
});

export const set_last_action = (value: AnthropometricMeasureActionTypes): IAction => ({
  type: AnthropometricMeasureActionTypes.SET_LAST_ACTION,
  value,
});

export const set_measure_reponse = (value: IMeasureOptionsResponse[]): IAction => ({
  type: AnthropometricMeasureActionTypes.SET_MEASURE_REPONSE,
  value,
});

export const set_default_lookup = (value: boolean, updateLocalFilter?: boolean): IThunkResult => {
  return async (dispatch, getState) => {
    batch(() => {
      if (updateLocalFilter) {
        const measuresResponse = getState().anthropometricMeasure.measuresResponse;
        const doctorLookUpDefault = value;

        setMeasureOptions(measuresResponse, doctorLookUpDefault, dispatch, set_measure_options);
      }

      dispatch({ type: AnthropometricMeasureActionTypes.SET_DEFAULT_LOOKUP, value });
    });
  };
};

export const set_selected_search_measure = (value: IOptionsData): IThunkResult => {
  return async (dispatch, getState) => {
    batch(() => {
      const selectedMeasure: IMeasureOptionsResponse = find(getState().anthropometricMeasure.measuresResponse,
        { anthropometricMeasureId: value ? value.value : null },
      );

      // Set measureUnit to current anthropometricMeasure
      if (selectedMeasure) {
        const currentAnthropometricMeasure: Partial<IAnthropometricMeasureData> = getState().anthropometricMeasure.currentAnthropometricMeasure || {};
        currentAnthropometricMeasure.measureUnit = selectedMeasure.measureUnit;
        dispatch(set_current_measure(currentAnthropometricMeasure as IAnthropometricMeasureData));
      }

      dispatch({ type: AnthropometricMeasureActionTypes.SET_SELECTED_SEARCH_MEASURE, value });
      dispatch(set_search_measure_term(value ? value.label : null));
    });
  };
};

export const get_attention_anthropometric_measures = (paginationObj: IPaginationObject): IThunkResult => {
  return async (dispatch, getState) => {

    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    let data: IAnthropometricMeasureData[] = [];
    let anthropometricMeasureCount: number = -1;

    let hasDoctorRecords = false;

    if (paginationObj.doRequest) {
      dispatchLoadingTable(dispatch, true);

      const currentData = getState().drugFormulation.previewFormulations;
      const page = paginationObj.reset ? 0 : currentData.length;

      const currentPatient = getState().patient.currentPatient;
      const currentDoctor = getState().medicalHistory.currentDoctor;

      const params = {
        attention_number: currentPatient.attentionNumber,
        user_name: loggedUser.firstName,
        doctor_id: currentDoctor.value,
      };

      const [error, anthropometricMeasuresResponse] = await to<IAnthropometricResponse>(
        executeRequest(`attention_anthropometric_measure`, params, 'get'),
      );
      const errorParsed = validateErrorFromClientRequest(error, anthropometricMeasuresResponse);

      if (errorParsed) {
        dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
        return;
      }

      hasDoctorRecords = anthropometricMeasuresResponse.hasSetUpRegisters;

      const anthropometricMeasures = anthropometricMeasuresResponse.anthropometricMeasures;
      anthropometricMeasureCount = anthropometricMeasures.length;
      const parsedData: IAnthropometricMeasureData[] = parsingDates(anthropometricMeasures, ['date']);
      data = concatResponse(currentData, parsedData, page);
    }

    if (paginationObj.page === 0) {
      batch(() => {
        dispatch(set_default_lookup(hasDoctorRecords)); // For knowing if there are pre-configured doctor records
        dispatch(get_measures());
      });
    }

    dispatchDataResponse(dispatch, {
      setCurrentPageDispatch: set_current_page,
      setRowsPerPageDispatch: set_rows_per_page,
      setDataDispatch: set_anthropometric_measures,
    }, paginationObj, data, anthropometricMeasureCount);
  };
};

export const get_measures = (mergeDoctorValues?: boolean): IThunkResult => {
  return async (dispatch, getState) => {
    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    dispatch(set_loading_dropdown(true));

    // const anthropometricMeasure = getState().anthropometricMeasure;
    const params: any = {
      // filter: anthropometricMeasure.searchMeasureTerm,
      filter: '',
      user_name: loggedUser.firstName,
    };

    if (mergeDoctorValues) {
      const currentDoctor = getState().medicalHistory.currentDoctor;
      params.doctor_id = currentDoctor.value;
    }

    let measuresOptionsResponse: IMeasureOptionsResponse[] = null;
    let error: Error = null;

    [error, measuresOptionsResponse] = await to(
      executeRequest(`anthropometric_measures`, params, 'get'),
    );
    const errorParsed = validateErrorFromClientRequest(error, measuresOptionsResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    /**
     * The idea is get all the data and after that recursively get all pre-configured values by doctor.
     * With both request i can join it and execute the filters locally and
     * prevent request with each button press in dynamicLookUp componente
     */
    if (!mergeDoctorValues) {
      dispatch(set_measure_reponse(measuresOptionsResponse));
      return dispatch(get_measures(true));
    } else {
      const measuresResponse = getState().anthropometricMeasure.measuresResponse;

      /**
       * Loop the previous measures loaded from the first request and verify if
       * each record exist in the pre-configured values by doctor getted in last request response.
       */
      each(measuresResponse, (eachMeasure) => {
        const foundMeasure = find(measuresOptionsResponse, { anthropometricMeasureId: eachMeasure.anthropometricMeasureId });
        if (foundMeasure) { // If the measure is found in the pre-configured values, set a new key to identify it.
          eachMeasure.preConfiguredByDoctor = true;
        }
      });

      measuresOptionsResponse = measuresResponse; // Update the last response with all records loaded and edited
    }

    const doctorLookUpDefault = getState().anthropometricMeasure.doctorLookUpDefault;

    batch(() => {
      dispatch(set_loading_dropdown(false));
      setMeasureOptions(measuresOptionsResponse, doctorLookUpDefault, dispatch, set_measure_options);
      dispatch(set_measure_reponse(measuresOptionsResponse));
    });
  };
};

const allInfoIsValid = (dispatch: IThunkDispatch, anthropometricMeasure: IAnthropometricMeasureReducerType): boolean => {
  const currentAnthropometricMeasure = anthropometricMeasure.currentAnthropometricMeasure;

  const msgPrefix = 'Por favor digite';
  if (!currentAnthropometricMeasure) {
    dispatchShowDialog(dispatch, true, `${msgPrefix} toda la información`, 'warning');
    return false;
  }
  else if (!currentAnthropometricMeasure.measure) {
    dispatchShowDialog(dispatch, true, `${msgPrefix} la medida`, 'warning');
    return false;
  }
  // else if (!currentAnthropometricMeasure.observation) {
  //   dispatchShowDialog(dispatch, true, `${msgPrefix} la observacion`, 'warning');
  //   return false;
  // }
  else if (!anthropometricMeasure.measureSelected) {
    dispatchShowDialog(dispatch, true, `Por favor seleccione la medida`, 'warning');
    return false;
  }

  return true;
};

export const save_anthropometric_measure = (anthropometricMeasureEdit?: IAnthropometricMeasureData): IThunkResult => {
  return async (dispatch, getState) => {

    if (getState().patient.currentPatient.attentionStatus === 'C') {
      return dispatchShowDialog(dispatch, true, 'Válido solo para citas abiertas', 'warning');
    }

    const anthropometricMeasure = getState().anthropometricMeasure;
    const anthropometricMeasures = anthropometricMeasure.anthropometricMeasures;

    let index;
    if (anthropometricMeasureEdit) {
      index = findIndex(anthropometricMeasures, { consecutive: anthropometricMeasureEdit.consecutive });
    }

    const isNewRecord = isUndefined(index);
    const currentAnthropometricMeasure = isNewRecord ? anthropometricMeasure.currentAnthropometricMeasure : anthropometricMeasures[index];
    if (isNewRecord) {
      if (!allInfoIsValid(dispatch, anthropometricMeasure)) {
        return;
      }
    }

    const params: IObj = {
      anthropometric_measure_id: isNewRecord ? anthropometricMeasure.measureSelected.value : currentAnthropometricMeasure.anthropometricMeasureId,
      measure: currentAnthropometricMeasure.measure,
      observation: currentAnthropometricMeasure.observation,
    };

    if (!isNewRecord) {
      params.consecutive = currentAnthropometricMeasure.consecutive;
    }

    const loggedUser = getState().user.data;
    const currentDoctor = getState().medicalHistory.currentDoctor;
    const currentPatient = getState().patient.currentPatient;

    // Default Values
    params.attention_number = currentPatient.attentionNumber;
    params.doctor_id = currentDoctor.value;
    params.user_name = loggedUser.firstName;

    dispatchShowSpinner(dispatch, true);

    const [error, createAnthropometricMeasureResponse] = await to(executeRequest(`create_attention_anthropomorphic_measure`, params, 'post'));
    const errorParsed = validateErrorFromClientRequest(error, createAnthropometricMeasureResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    dispatchShowDialog(dispatch, true, 'Registro guardado existosamente', 'success');

    batch(() => {
      if (isNewRecord) {
        dispatch(set_current_measure(null));
        dispatch(set_selected_search_measure(null));
        dispatch(get_attention_anthropometric_measures({
          page: 0,
          rowsPerPage: anthropometricMeasure.rowsPerPage,
          doRequest: true,
          reset: true,
        }));
      }
    });
  };
};

export const delete_anthrometric_measure = (anthropometricMeasureDelete: IAnthropometricMeasureData): IThunkResult => {
  return async (dispatch, getState) => {

    if (getState().patient.currentPatient.attentionStatus === 'C') {
      return dispatchShowDialog(dispatch, true, 'Válido solo para citas abiertas', 'warning');
    }

    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    dispatchShowSpinner(dispatch, true);

    const params = {
      consecutive: anthropometricMeasureDelete.consecutive,
      user_name: loggedUser.firstName,
    };

    const [error, deleteMedicineResponse] = await to<IPreviewDiagnosticNotes>(
      executeRequest(`delete_attention_anthropometric_measure`, params, 'post'),
    );
    const errorParsed = validateErrorFromClientRequest(error, deleteMedicineResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    dispatchShowDialog(dispatch, true, 'Registro eliminado existosamente', 'success');
    const anthropometricMeasure = cloneDeep(getState().anthropometricMeasure);
    const anthropometricMeasures = anthropometricMeasure.anthropometricMeasures;

    const index = findIndex(anthropometricMeasures, { consecutive: anthropometricMeasureDelete.consecutive });
    if (index !== -1) {
      pullAt(anthropometricMeasures, index);
      dispatch(set_anthropometric_measures({ data: anthropometricMeasures, count: anthropometricMeasures.length }));
    }
  };
};
