import { batch } from 'react-redux';

// Third Party modules
import { to } from 'await-to-js';
import { find, map, pullAt } from 'lodash';

// Utils
import { copyObject } from '../../Utils';
import Constants from '../../Utils/Constants';
import { dispatchShowDialog, dispatchShowSpinner } from '../App/AppUtils';
import { validateErrorFromClientRequest } from '../ReducersUtils';

// Service
import executeRequest from '../../Services/ServiceBase';

export enum SystemReviewActionTypes {
  SET_LAST_ACTION = 'systemReview/SET_LAST_ACTION',
  SET_BODY_SYSTEMS = 'systemReview/SET_BODY_SYSTEMS',
  SET_CURRENT_BODY_SYSTEM = 'systemReview/SET_CURRENT_BODY_SYSTEM',
  SET_MEDICAL_REVIEW = 'systemReview/SET_MEDICAL_REVIEW',
  SET_SYMPTOMS = 'systemReview/SET_SYMPTOMS',
}

export const set_last_action = (value: SystemReviewActionTypes): IAction => ({ type: SystemReviewActionTypes.SET_LAST_ACTION, value });
export const set_body_systems = (value: IOptionsData[]): IAction => ({ type: SystemReviewActionTypes.SET_BODY_SYSTEMS, value });
export const set_medical_review = (value: string): IAction => ({ type: SystemReviewActionTypes.SET_MEDICAL_REVIEW, value });
export const set_symptoms = (value: ISymptom[]): IAction => ({ type: SystemReviewActionTypes.SET_SYMPTOMS, value });

export const get_body_systems = (): IThunkResult => {
  return async (dispatch, getState) => {

    dispatchShowSpinner(dispatch, true);

    const loggedUser = getState().user.data;

    const params = {
      userName: loggedUser.firstName,
    };

    const [error, bodySystemsResponse] = await to<IBodySytems[]>(executeRequest(`body_systems`, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, bodySystemsResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    const bodySystemOptions: IOptionsData[] = [{ label: 'Seleccione un sistema del cuerpo', value: 'default' }];

    map(bodySystemsResponse, (bodySystem: IBodySytems) => {
      bodySystemOptions.push({
        label: bodySystem.bodySystem,
        value: bodySystem.bodySystemId,
      });
    });

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(set_body_systems(bodySystemOptions));
    });
  };
};

export const get_medical_check_with_symptoms = (): IThunkResult => {
  return async (dispatch, getState) => {

    dispatchShowSpinner(dispatch, true);

    const loggedUser = getState().user.data;
    const currentPatient = getState().patient.currentPatient;

    const params = {
      doctorName: loggedUser.firstName,
      attentionNumber: currentPatient.attentionNumber,
    };

    const [error, bodySystemsResponse] = await to<IMedicalCheck>(executeRequest(`medical_check_with_symptoms`, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, bodySystemsResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(set_medical_review(bodySystemsResponse.medicalCheckDescription));
      dispatch(set_symptoms(bodySystemsResponse.symptoms));
    });
  };
};

export const create_medical_review = (): IThunkResult => {
  return async (dispatch, getState) => {

    if (getState().patient.currentPatient.attentionStatus === 'C') {
      return dispatchShowDialog(dispatch, true, 'Válido solo para citas abiertas', 'warning');
    }

    dispatchShowSpinner(dispatch, true);

    const loggedUser = getState().user.data;
    const currentDoctor = getState().medicalHistory.currentDoctor;
    const medicalReview = getState().systemReview.medicalReview;
    const currentPatient = getState().patient.currentPatient;

    const params = {
      attention_number: currentPatient.attentionNumber,
      description: medicalReview,
      doctor: currentDoctor ? currentDoctor.value : null,
      doctor_name: loggedUser.firstName,
    };

    const [error, bodySystemsResponse] = await to<IMedicalCheck>(executeRequest(`create_medical_check`, params, 'post'));
    const errorParsed = validateErrorFromClientRequest(error, bodySystemsResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    dispatchShowDialog(dispatch, true, 'Revisión guardada existosamente', 'success');

    batch(() => {
      dispatchShowSpinner(dispatch, false);
    });
  };
};

export const set_current_body_system = (value: IOptionsData): IThunkResult => {
  return async (dispatch, getState) => {
    batch(() => {
      dispatch({ type: SystemReviewActionTypes.SET_CURRENT_BODY_SYSTEM, value });
      if (value) {
        dispatch(get_symptoms_by_system(value));
      }
    });
  };
};

/**
 * get symptoms by system
 * @param currentBodySystem value=id, label=name
 */
export const get_symptoms_by_system = (currentBodySystem: IOptionsData): IThunkResult => {
  return async (dispatch, getState) => {
    dispatchShowSpinner(dispatch, true);

    const loggedUser = getState().user.data;

    const params = {
      user_name: loggedUser.firstName,
      body_system_id: currentBodySystem.value,
    };

    const [error, symptomsResponse] = await to<ISymptom[]>(executeRequest(`symptoms`, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, symptomsResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    const attemptionSymptoms = copyObject(getState().systemReview.attentionSymptoms) || [];

    const bodySystemSymptom: IOptionsData[] = [{ label: 'Seleccione un sintoma', value: 'default' }];

    map(symptomsResponse, (symptomResponse: ISymptom) => {
      bodySystemSymptom.push({
        value: symptomResponse.symptomId,
        label: symptomResponse.symptomName,
      });
    });

    attemptionSymptoms.unshift({
      bodySystemId: currentBodySystem.value,
      bodySystemName: currentBodySystem.label,
      symptomsInSystem: bodySystemSymptom,
      isNew: true,
    });

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(set_symptoms(attemptionSymptoms));
    });
  };
};

export const save_medical_check_symptom = (attemptionSymptomIndex: number): IThunkResult => {
  return async (dispatch, getState) => {

    if (getState().patient.currentPatient.attentionStatus === 'C') {
      return dispatchShowDialog(dispatch, true, 'Válido solo para citas abiertas', 'warning');
    }

    dispatchShowSpinner(dispatch, true);

    const attentionSymptoms = copyObject(getState().systemReview.attentionSymptoms);
    const attemptionSymptomToSave = attentionSymptoms[attemptionSymptomIndex];

    const isNew = attemptionSymptomToSave.isNew;
    const hasSymtomsInSystem = attemptionSymptomToSave.symptomsInSystem && attemptionSymptomToSave.symptomsInSystem.length;
    const hasSymtomsSelected = attemptionSymptomToSave.symptomsInSystemSelected && attemptionSymptomToSave.symptomsInSystemSelected.length;

    const symptomIds: string[] = [];

    // Remove new attetionSympton with select component from array
    pullAt(attentionSymptoms, attemptionSymptomIndex);

    if (isNew && hasSymtomsInSystem && hasSymtomsSelected) {
      for (const symptomSelected of attemptionSymptomToSave.symptomsInSystemSelected) {
        const symptomId = symptomSelected.value;
        const symptomName = symptomSelected.label;

        const attentionSymptom = find(attentionSymptoms, { symptomId });

        if (attentionSymptom) {
          dispatchShowDialog(dispatch, true, Constants.alert_messages.symptom_already_exist, 'warning');
          return;
        }

        if (!symptomId) {
          dispatchShowDialog(dispatch, true, Constants.alert_messages.no_symptoms, 'warning');
          return;
        }

        // Set sympton id
        symptomIds.push(symptomId);

        // Set new attentionSymptom
        attentionSymptoms.unshift({
          bodySystemId: attemptionSymptomToSave.bodySystemId,
          bodySystemName: attemptionSymptomToSave.bodySystemName,
          symptomId,
          symptomName,
          isNew: false,
          symptomsInSystemSelected: null,
        });
      }
    }

    const loggedUser = getState().user.data;

    const currentDoctor = getState().medicalHistory.currentDoctor;
    const currentPatient = getState().patient.currentPatient;

    const params = {
      attention_number: currentPatient.attentionNumber,
      doctor: currentDoctor ? currentDoctor.value : null,
      doctor_name: loggedUser.firstName,
      // observation: attemptionSymptomToSave.symptomDescription,
      symptom_ids: symptomIds,
    };

    const [error, symptomsResponse] = await to<ISymptom[]>(executeRequest(`save_medical_check_symptom`, params, 'post'));
    const errorParsed = validateErrorFromClientRequest(error, symptomsResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    dispatchShowDialog(dispatch, true, 'Sintoma guardado existosamente', 'success');

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(set_symptoms(attentionSymptoms));
      dispatch(set_current_body_system(null));
    });
  };
};

export const delete_medical_check_symptom = (index: number): IThunkResult => {
  return async (dispatch, getState) => {

    if (getState().patient.currentPatient.attentionStatus === 'C') {
      return dispatchShowDialog(dispatch, true, 'Válido solo para citas abiertas', 'warning');
    }

    const attentionSymptoms = copyObject(getState().systemReview.attentionSymptoms);
    const attentionSymptom = attentionSymptoms[index];

    if (attentionSymptom.isNew) {
      pullAt(attentionSymptoms, index);
      dispatch(set_symptoms(attentionSymptoms));
      return;
    }

    dispatchShowSpinner(dispatch, true);

    const loggedUser = getState().user.data;
    const currentPatient = getState().patient.currentPatient;

    const params = {
      attention_number: currentPatient.attentionNumber,
      symptom_id: attentionSymptom.symptomId,
      doctor_name: loggedUser.firstName,
    };

    const [error, bodySystemsResponse] = await to<IMedicalCheck>(executeRequest(`delete_medical_check_symptom`, params, 'post'));
    const errorParsed = validateErrorFromClientRequest(error, bodySystemsResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    dispatchShowDialog(dispatch, true, 'Revisión eliminada existosamente', 'success');

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      pullAt(attentionSymptoms, index);
      dispatch(set_symptoms(attentionSymptoms));
      dispatch(set_current_body_system(null));
    });
  };
};
