import { Theme } from '@material-ui/core';
import createStyles from '@material-ui/core/styles/createStyles';

import { ApplicationStyles, Colors } from '../../Theme';

const styles = (theme: Theme) => createStyles({
  ...ApplicationStyles,
  colorDivider: {
    background: Colors.drawerDivider,
  },
  drawerContent: {
    overflow: 'auto',
  },
  drawerFooter: {
    ...ApplicationStyles.drawerWidth,
    position: 'absolute',
    bottom: 0,
  },
  drawerPaper: {
    ...ApplicationStyles.drawerWidth,
    background: Colors.drawerBackgroundColor,
    position: 'fixed',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    position: 'fixed',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...customThemas(theme).widthSmallDrawer,
  },
  drawerPaperResponsive: {
    ...ApplicationStyles.drawerWidth,
    display: 'none',
    background: Colors.drawerBackgroundColor,
    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
  },
  drawerPaperResponsiveShow: {
    display: 'flex',
  },
});

const customThemas = (theme: any) => {
  return {
    widthSmallDrawer: {
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
  };
};

export default styles;
