import { batch } from 'react-redux';

// Third Party modules
import { to } from 'await-to-js';
import { cloneDeep, find, findIndex, isUndefined, map, pullAt } from 'lodash';

// Utils
// import Constants from '../../Utils/Constants';
import { dispatchLoadingTable, dispatchShowDialog, dispatchShowSpinner } from '../App/AppUtils';
import { concatResponse, dispatchDataResponse, parsingDates, validateErrorFromClientRequest } from '../ReducersUtils';

// External Actions
import { set_loading_dropdown } from '../App/AppActions';

// Service
import executeRequest from '../../Services/ServiceBase';

export enum DrugFormulationActionTypes {
  SET_LAST_ACTION = 'drugFormulation/SET_LAST_ACTION',
  SET_DRUG_TYPE = 'drugFormulation/SET_DRUG_TYPE',
  SET_CURRENT_DRUG = 'drugFormulation/SET_CURRENT_DRUG',
  SET_PREVIEW_FORMULATIONS = 'drugFormulation/SET_PREVIEW_FORMULATIONS',
  SET_MEDICINE_OPTIONS = 'drugFormulation/SET_MEDICINE_OPTIONS',
  SET_SELECTED_SEARCH_DRUG = 'drugFormulation/SET_SELECTED_SEARCH_DRUG',
  SET_SEARCH_DRUG_TERM = 'drugFormulation/SET_SEARCH_DRUG_TERM',
  SET_MEDICINE_REPONSE = 'drugFormulation/SET_MEDICINE_REPONSE',
  SET_CURRENT_PAGE = 'drugFormulation/SET_CURRENT_PAGE',
  SET_ROWS_PER_PAGE = 'drugFormulation/SET_ROWS_PER_PAGE',
  SET_SEARCH_TERM = 'drugFormulation/SET_SEARCH_TERM',
  SET_DEFAULT_LOOKUP = 'drugFormulation/SET_DEFAULT_LOOKUP',
  SET_HISTORICAL_MEDICINES = 'drugFormulation/SET_HISTORICAL_MEDICINES',
  SET_MEDICINES_REPLICATED = 'drugFormulation/SET_MEDICINES_REPLICATED',
}

export const set_last_action = (value?: DrugFormulationActionTypes): IAction => ({ type: DrugFormulationActionTypes.SET_LAST_ACTION, value });
export const set_drug_type = (value: MedicationType): IAction => ({ type: DrugFormulationActionTypes.SET_DRUG_TYPE, value });
export const set_current_drug = (value: IDrugFormulationData): IAction => ({ type: DrugFormulationActionTypes.SET_CURRENT_DRUG, value });

export const set_preview_formulations
  = (value: { data: IDrugFormulationData[], count: number }): IAction => ({ type: DrugFormulationActionTypes.SET_PREVIEW_FORMULATIONS, value });
export const set_medicine_options = (value: IOptionsData[]): IAction => ({ type: DrugFormulationActionTypes.SET_MEDICINE_OPTIONS, value });

export const set_search_drug_term = (value: string): IAction => ({ type: DrugFormulationActionTypes.SET_SEARCH_DRUG_TERM, value });
export const set_medicine_reponse = (value: IMedicineOptionResponse[]): IAction => ({ type: DrugFormulationActionTypes.SET_MEDICINE_REPONSE, value });

export const set_current_page = (page: number): IAction => ({ type: DrugFormulationActionTypes.SET_CURRENT_PAGE, value: page });
export const set_rows_per_page = (value: number): IAction => ({ type: DrugFormulationActionTypes.SET_ROWS_PER_PAGE, value });
export const set_search_term = (value: string): IAction => ({ type: DrugFormulationActionTypes.SET_SEARCH_TERM, value });

export const set_default_lookup = (value: boolean): IAction => ({ type: DrugFormulationActionTypes.SET_DEFAULT_LOOKUP, value });

export const set_historical_medicines =
  (value: IDrugFormulationData[]): IAction => ({ type: DrugFormulationActionTypes.SET_HISTORICAL_MEDICINES, value });

export const set_selected_search_drug = (value: IOptionsData): IThunkResult => {
  return async (dispatch, getState) => {
    batch(() => {
      const selectedDrug: IMedicineOptionResponse = find(getState().drugFormulation.medicinesResponse, { medicineId: value ? value.value : null });

      if (selectedDrug) {
        const currentDrugFomulation: Partial<IDrugFormulationData> = getState().drugFormulation.currentDrugFomulation || {};
        currentDrugFomulation.medicineName = selectedDrug.medicineName;
        dispatch(set_current_drug(currentDrugFomulation as IDrugFormulationData));
      }

      dispatch({ type: DrugFormulationActionTypes.SET_SELECTED_SEARCH_DRUG, value });
      dispatch(set_search_drug_term(value ? value.label : null));
    });
  };
};

export const get_attention_medicines = (paginationObj: IPaginationObject): IThunkResult => {
  return async (dispatch, getState) => {

    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    let data: IDrugFormulationData[] = [];
    let formulationCount: number = -1;

    let hasDoctorRecords = false;

    if (paginationObj.doRequest) {
      dispatchLoadingTable(dispatch, true);

      const currentData = getState().drugFormulation.previewFormulations;
      const page = paginationObj.reset ? 0 : currentData.length;

      const currentPatient = getState().patient.currentPatient;
      const currentDoctor = getState().medicalHistory.currentDoctor;

      const params = {
        attention_number: currentPatient.attentionNumber,
        user_name: loggedUser.firstName,
        doctor_id: currentDoctor.value,
      };

      const [error, previewDrugsResponse] = await to<IDrugFormulationResponse>(executeRequest(`attention_medicines`, params, 'get'));
      const errorParsed = validateErrorFromClientRequest(error, previewDrugsResponse);

      if (errorParsed) {
        dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
        return;
      }

      hasDoctorRecords = previewDrugsResponse.hasSetUpRegisters;

      const attentionMedicines = previewDrugsResponse.attentionMedicines;
      formulationCount = attentionMedicines.length;
      const parsedData: IDrugFormulationData[] = parsingDates(attentionMedicines, ['attentionMedicineDate']);
      data = concatResponse(currentData, parsedData, page);
    }

    if (paginationObj.page === 0) {
      dispatch(set_default_lookup(hasDoctorRecords)); // For knowing if there are pre-configured doctor records
    }

    dispatchDataResponse(dispatch, {
      setCurrentPageDispatch: set_current_page,
      setRowsPerPageDispatch: set_rows_per_page,
      setDataDispatch: set_preview_formulations,
    }, paginationObj, data, formulationCount);
  };
};

export const get_drugs = (): IThunkResult => {
  return async (dispatch, getState) => {

    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    const drugFormulation = getState().drugFormulation;
    if (!drugFormulation.medicationType) {
      dispatchShowDialog(dispatch, true, 'Seleccione un tipo de medicamento', 'warning');
      return;
    }

    const maxCharacters = 4;
    const isDoctorFilterSelected = getState().drugFormulation.doctorLookUpDefault;
    if (!isDoctorFilterSelected && (!drugFormulation.searchDrugTerm || drugFormulation.searchDrugTerm.length < maxCharacters)) {
      dispatchShowDialog(dispatch, true, `Escriba ${maxCharacters} caracteres como mínimo para buscar`, 'warning');
      return;
    }

    dispatch(set_loading_dropdown(true));

    const claseId = drugFormulation.medicationType === 'comercial' ? 'COM' : 'POS';

    const params: any = {
      distribution_clase_id: claseId,
      filter: drugFormulation.searchDrugTerm || '',
      user_name: loggedUser.firstName,
    };

    if (drugFormulation.doctorLookUpDefault) {
      const currentDoctor = getState().medicalHistory.currentDoctor;
      params.doctor_id = currentDoctor.value;
    }

    const [error, drugsOptionsResponse] = await to<IMedicineOptionResponse[]>(executeRequest(`medicines_by_filters`, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, drugsOptionsResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    const drugsOptions: IOptionsData[] = [{ label: 'Seleccione un medicamento', value: 'default' }];

    map(drugsOptionsResponse, (drug: IMedicineOptionResponse) => {
      drugsOptions.push({
        value: drug.medicineId,
        label: `${drug.medicineName} - ${drug.commercialDescription || 'No Info'}`,
      });
    });

    batch(() => {
      dispatch(set_loading_dropdown(false));
      dispatch(set_medicine_options(drugsOptions));
      dispatch(set_medicine_reponse(drugsOptionsResponse));
    });
  };
};

const allInfoIsValid = (dispatch: IThunkDispatch, drugFormulation: IDrugFormulationReducerType): boolean => {
  const currentDrugFomulation = drugFormulation.currentDrugFomulation;

  const msgPrefix = 'Por favor digite';
  if (!currentDrugFomulation) {
    dispatchShowDialog(dispatch, true, `${msgPrefix} toda la información`, 'warning');
    return false;
  }

  if (!currentDrugFomulation.dose) {
    dispatchShowDialog(dispatch, true, `${msgPrefix} la dosis`, 'warning');
    return false;
  }

  if (!currentDrugFomulation.quantity) {
    dispatchShowDialog(dispatch, true, `${msgPrefix} la cantidad`, 'warning');
    return false;
  }

  if (!currentDrugFomulation.measure) {
    dispatchShowDialog(dispatch, true, `${msgPrefix} la unidad de medida`, 'warning');
    return false;
  }

  if (!currentDrugFomulation.administrationMethod) {
    dispatchShowDialog(dispatch, true, `${msgPrefix} la forma de administrar`, 'warning');
    return false;
  }

  if (!currentDrugFomulation.treatmentDuration) {
    dispatchShowDialog(dispatch, true, `${msgPrefix} la duración del tratamiento`, 'warning');
    return false;
  }

  if (!currentDrugFomulation.frecuency) {
    dispatchShowDialog(dispatch, true, `${msgPrefix} la frecuencia`, 'warning');
    return false;
  }

  if (!drugFormulation.medicineSelected) {
    dispatchShowDialog(dispatch, true, `Por favor seleccione el medicamento`, 'warning');
    return false;
  }

  return true;
};

export const save_medicine = (drugFormulationEdit?: IDrugFormulationData): IThunkResult => {
  return async (dispatch, getState) => {

    if (getState().patient.currentPatient.attentionStatus === 'C') {
      return dispatchShowDialog(dispatch, true, 'Válido solo para citas abiertas', 'warning');
    }

    const drugFormulation = getState().drugFormulation;
    const previewFormulations = drugFormulation.previewFormulations;

    let index;
    if (drugFormulationEdit) {
      index = findIndex(previewFormulations, { consecutive: drugFormulationEdit.consecutive });
    }

    const isNewRecord = isUndefined(index);
    const currentDrugFomulation = isNewRecord ? drugFormulation.currentDrugFomulation : previewFormulations[index];
    if (isNewRecord) {
      if (!allInfoIsValid(dispatch, drugFormulation)) {
        return;
      }
    }

    const params: IObj = {
      frecuency: currentDrugFomulation.frecuency,
      medicine_id: isNewRecord ? drugFormulation.medicineSelected.value : currentDrugFomulation.medicineId,
      observations: currentDrugFomulation.observations || '',
      dose: currentDrugFomulation.dose,
      measure: currentDrugFomulation.measure,
      quantity: currentDrugFomulation.quantity,
      // total_quantity: currentDrugFomulation.totalQuantity,
      // treatment_days: currentDrugFomulation.treatmentDays,
      treatment_duration: currentDrugFomulation.treatmentDuration,
      administration_method: currentDrugFomulation.administrationMethod,
    };

    if (!isNewRecord) {
      params.consecutive = currentDrugFomulation.consecutive;
    }

    const loggedUser = getState().user.data;
    const currentDoctor = getState().medicalHistory.currentDoctor;
    const currentPatient = getState().patient.currentPatient;

    // Default Values
    params.attention_number = currentPatient.attentionNumber;
    params.doctor_id = currentDoctor.value;
    params.user_name = loggedUser.firstName;

    dispatchShowSpinner(dispatch, true);

    const [error, createDrugResponse] = await to(executeRequest(`create_attention_medicines`, params, 'post'));
    const errorParsed = validateErrorFromClientRequest(error, createDrugResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    dispatchShowDialog(dispatch, true, 'Registro guardado existosamente', 'success');

    batch(() => {
      if (isNewRecord) {
        dispatch(set_current_drug(null));
        dispatch(set_selected_search_drug(null));
        dispatch(set_drug_type('comercial')); // Because is the default value
        dispatch(get_attention_medicines({
          page: 0,
          rowsPerPage: drugFormulation.rowsPerPage,
          doRequest: true,
          reset: true,
        }));
      }
    });
  };
};

export const delete_medicine = (drugFormulationDelete: IDrugFormulationData): IThunkResult => {
  return async (dispatch, getState) => {

    if (getState().patient.currentPatient.attentionStatus === 'C') {
      return dispatchShowDialog(dispatch, true, 'Válido solo para citas abiertas', 'warning');
    }

    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    dispatchShowSpinner(dispatch, true);

    const params = {
      consecutive: drugFormulationDelete.consecutive,
      user_name: loggedUser.firstName,
    };

    const [error, deleteMedicineResponse] = await to<IPreviewDiagnosticNotes>(executeRequest(`delete_attention_medicine`, params, 'post'));
    const errorParsed = validateErrorFromClientRequest(error, deleteMedicineResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    dispatchShowDialog(dispatch, true, 'Registro eliminado existosamente', 'success');
    const drugFormulation = cloneDeep(getState().drugFormulation);
    const previewFormulations = drugFormulation.previewFormulations;

    const index = findIndex(previewFormulations, { consecutive: drugFormulationDelete.consecutive });
    if (index !== -1) {
      pullAt(previewFormulations, index);
      dispatch(set_preview_formulations({ data: previewFormulations, count: previewFormulations.length }));
    }
  };
};

export const get_medicines_historic = (): IThunkResult => {
  return async (dispatch, getState) => {

    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    dispatchShowSpinner(dispatch, true);

    const currentPatient = getState().patient.currentPatient;
    const params: any = {
      patient_id: currentPatient.patientUserId,
      user_name: loggedUser.firstName,
    };

    const [error, historicalResponse] = await to<IDrugFormulationData[]>(executeRequest(`attention_medicines_historic`, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, historicalResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(set_historical_medicines(historicalResponse));
    });
  };
};

export const replicate_medicines = (historicAttentionNumber: number): IThunkResult => {
  return async (dispatch, getState) => {

    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    dispatchShowSpinner(dispatch, true);

    const currentDoctor = getState().medicalHistory.currentDoctor;
    const currentPatient = getState().patient.currentPatient;

    const params: any = {
      historic_attention_number: historicAttentionNumber,
      doctor_id: currentDoctor.value,
      attention_number: currentPatient.attentionNumber,
      user_name: loggedUser.firstName,
    };

    const [error, historicalResponse] = await to<IDrugFormulationData[]>(executeRequest(`replicate_medicines`, params, 'post'));
    const errorParsed = validateErrorFromClientRequest(error, historicalResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(set_last_action(DrugFormulationActionTypes.SET_MEDICINES_REPLICATED));
    });
  };
};
