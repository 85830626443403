import { batch } from 'react-redux';

// Third Party modules
import { to } from 'await-to-js';
import { each, filter } from 'lodash';

// Utils
import { copyObject } from '../../Utils';
import { dispatchLoadingTable, dispatchShowDialog, dispatchShowSpinner } from '../App/AppUtils';
import { concatResponse, dispatchDataResponse, validateErrorFromClientRequest } from '../ReducersUtils';

// Service
import executeRequest from '../../Services/ServiceBase';

export enum VitalSignsActionsTypes {
  SET_LAST_ACTION = 'VitalSigns/SET_LAST_ACTION',
  SET_VITAL_SIGNS_DATA = 'VitalSigns/SET_VITAL_SIGNS_DATA',
  SET_CURRENT_PAGE = 'VitalSigns/SET_CURRENT_PAGE',
  SET_ROWS_PER_PAGE = 'VitalSigns/SET_ROWS_PER_PAGE',
  SET_SEARCH_TERM = 'VitalSigns/SET_SEARCH_TERM',
}

export const set_last_action = (value: VitalSignsActionsTypes): IAction => ({ type: VitalSignsActionsTypes.SET_LAST_ACTION, value });

export const set_vital_signs_data
  = (value: { data: IVitalSignData[], count: number }): IAction => ({ type: VitalSignsActionsTypes.SET_VITAL_SIGNS_DATA, value });

export const set_current_page = (page: number): IAction => ({ type: VitalSignsActionsTypes.SET_CURRENT_PAGE, value: page });
export const set_rows_per_page = (value: number): IAction => ({ type: VitalSignsActionsTypes.SET_ROWS_PER_PAGE, value });
export const set_search_term = (value: string): IAction => ({ type: VitalSignsActionsTypes.SET_SEARCH_TERM, value });

export const get_vital_signs = (paginationObj: IPaginationObject): IThunkResult => {
  return async (dispatch, getState) => {

    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    let data: IVitalSignData[] = [];
    let signsCount: number = -1;

    if (paginationObj.doRequest) {
      dispatchLoadingTable(dispatch, true);

      const cupReducer = getState().cup;
      const currentData = cupReducer.previewCups;
      const page = paginationObj.reset ? 0 : currentData.length;

      const currentPatient = getState().patient.currentPatient;
      const currentDoctor = getState().medicalHistory.currentDoctor;

      const params = {
        attention_number: currentPatient.attentionNumber,
        user_name: loggedUser.firstName,
        doctor_id: currentDoctor.value,
      };

      const [error, vitalSignsResponse] = await to<IVitalSignData[]>(executeRequest(`attention_vital_signs`, params, 'get'));
      const errorParsed = validateErrorFromClientRequest(error, vitalSignsResponse);

      if (errorParsed) {
        dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
        return;
      }

      signsCount = vitalSignsResponse.length;
      data = concatResponse(currentData, vitalSignsResponse, page);
    }

    dispatchDataResponse(dispatch, {
      setCurrentPageDispatch: set_current_page,
      setRowsPerPageDispatch: set_rows_per_page,
      setDataDispatch: set_vital_signs_data,
    }, paginationObj, data, signsCount);
  };
};

export const create_vital_sign = (): IThunkResult => {
  return async (dispatch, getState) => {

    if (getState().patient.currentPatient.attentionStatus === 'C') {
      return dispatchShowDialog(dispatch, true, 'Válido solo para citas abiertas', 'warning');
    }

    dispatchShowSpinner(dispatch, true);

    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    const allVitalSigns = getState().vitalSigns.vitalSignsData;
    const editedVitalSigns: IVitalSignData[] = filter(allVitalSigns, { edited: true });

    if (!editedVitalSigns.length) {
      dispatchShowDialog(dispatch, true, 'No hay cambios', 'info');
      return;
    }

    const vitalSignValues: IVitalSaveInfo[] = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < editedVitalSigns.length; i++) {
      const eachVital = editedVitalSigns[i];

      vitalSignValues.push({
        isEdit: eachVital.isEdit,
        measure_value1: eachVital.measure1,
        measure_value2: eachVital.measure2,
        multiple_mesuare: eachVital.multipleMeasure,
        vital_sign_id: eachVital.vitalSignId,
      });
    }

    const currentPatient = getState().patient.currentPatient;

    const params = {
      attention_number: currentPatient.attentionNumber,
      user_name: loggedUser.firstName,
      vital_sign_values: vitalSignValues,
    };

    const [error, vitalSignsSaveResponse] = await to(executeRequest(`create_vital_signs`, params, 'post'));
    const errorParsed = validateErrorFromClientRequest(error, vitalSignsSaveResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    // Restoring edited and isEdit
    const vitalSignsCopy = copyObject(getState().vitalSigns.vitalSignsData);
    each(vitalSignsCopy, (vitalSign) => {
      vitalSign.edited = false;
      vitalSign.isEdit = false;
    });

    batch(() => {
      dispatchShowDialog(dispatch, true, 'Información guardada existosamente', 'success');
      dispatch(set_vital_signs_data({ data: vitalSignsCopy, count: vitalSignsCopy.length }));
    });
  };
};
