// Libraries
import { Reducer } from 'redux';

// Constants
import { MedicalDisabilityActionTypes } from './MedicalDisabilityActions';

const initialState: IMedicalDisabilityReducerType = {
  currentOrigin: {},
  disabilityOrigings: [],
  lastAction: null,
  medicalDisabilityData: {},
};

const reducer: Reducer<IMedicalDisabilityReducerType, IAction> = (state = initialState, action: IAction): IMedicalDisabilityReducerType => {
  switch (action.type) {
    case MedicalDisabilityActionTypes.SET_LAST_ACTION:
      return {
        ...state,
        lastAction: action.value,
      };
    case MedicalDisabilityActionTypes.SET_DISABILITY_ORIGINGS:
      return {
        ...state,
        disabilityOrigings: action.value,
      };
    case MedicalDisabilityActionTypes.SET_CURRENT_ORIGIN:
      return {
        ...state,
        currentOrigin: action.value,
      };
      case MedicalDisabilityActionTypes.SET_MEDICAL_DISABILITY:
        return {
          ...state,
          medicalDisabilityData: action.value,
        };
    default:
      return state;
  }
};

export default reducer;
