import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';

const colors = {
  basicGray: grey[300],
  black: 'black',
  blueButton: '#3696B3',
  blueHoverButton: blue[900],
  darkApp: '#262f3d',
  darkGray: grey[700],
  error: red[500],
  errorDark: red[800],
  errorLight: red[300],
  gray: grey[400],
  green: green[600],
  greenApple: '#45bd69',
  greenSuccessDark: green[700],
  greenSuccessLight: green[300],
  leftColor: '#FF9933',
  lightBlue: '#2196f3',
  lightGray: grey[200],
  lightSilver: '#A3A3A3',
  lightYellow: '#fff0c8',
  orangeVivid: '#FFB90D',
  orange: '#D86519',
  orangeBase: '#ffa500',
  pastelBlue: '#C0D6E3',
  primaryMain: blue[600],
  redBittersweet: '#f76161',
  rightColor: '#006FC0',
  silver: '#F7F7F7',
  superLightGray: grey[100],
  transparent: 'transparent',
  transparentDarkGray: '#80808094',
  transparentWhite: '#FAFAFA',
  white: '#ffffff',
  // drawer
  drawerBackgroundColor: '#262f3d',
  drawerChildItemTextColor: 'rgba(255,255,255,0.7)',
  drawerDivider: 'rgba(255,255,255,0.2)',
  drawerHoverBackgroundColor: 'rgba(255,255,255,0.1)',
  drawerListItemExpandedBackgroundColor: '#19212b',
  drawerListItemExpandedTextColor: '#4fc3f7',
  drawerListItemTextColor: 'white',
  // Table
  backgroundDivider: '#8080801a',
  dragAndDropTableBorderColor: '1px solid rgba(224, 224, 224, 1)',
  // Workers
  rating: '#f3cc10',
  shapeBlue: '#507ABE',
  tableHeadersReview: '#DCCEEF',
};

export default colors;
