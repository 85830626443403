// Material
import { Theme } from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';

import { ApplicationStyles, Metrics } from '../../Theme';

const drawerWidth = 257;

const styles = (theme: Theme) => createStyles({
  appBar: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
    ...customThemas(theme).leftSpaceSmallDrawer,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    ...customThemas(theme).widthNormalDrawer,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    ...ApplicationStyles.flexRowAlignCenter,
    ...ApplicationStyles.flexEnd,
    padding: '0 12px',
    ...theme.mixins.toolbar,
    height: 72,
  },
  bigAvatar: {
    width: Metrics.images.medium,
    height: Metrics.images.medium,
  },
  logo: {
    // width: 198,
    height: 50,
    objectFit: 'contain',
    marginRight: Metrics.marginOrPaddingBase,
  },
  doorImg: {
    height: 32,
  },
});

const customThemas = (theme: Theme) => {
  return {
    leftSpaceSmallDrawer: {
      width: `calc(100% - ${theme.spacing(7)}px)`,
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${theme.spacing(9)}px)`,
        marginLeft: theme.spacing(9),
      },
      [theme.breakpoints.down('sm')]: {
        width: `calc(100%)`,
        marginLeft: 0,
      },
    },
    widthNormalDrawer: {
      width: `calc(100%)`,
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
      [theme.breakpoints.down('sm')]: {
        width: `calc(100%)`,
        marginLeft: 0,
      },
    },
  };
};

export default styles;
