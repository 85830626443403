// Libraries
import { Reducer } from 'redux';

// Utils
import Constants from '../../Utils/Constants';

// Constants
import { ExamsRevAndDiagTestActionTypes } from './ExamsRevAndDiagTestActions';

const initialState: IExamsRevAndDiagTestReducerType = {
  lastAction: null,
  pendingExams: [],
  currentPendingExam: null,
  currentPage: 0,
  rowsPerPage: Constants.limits.page_limit,
  totalPendingExams: 0,
  searchTerm: null,
  historicalExams: [],
  examList: [],
};

const reducer: Reducer<IExamsRevAndDiagTestReducerType, IAction> = (state = initialState, action: IAction): IExamsRevAndDiagTestReducerType => {
  switch (action.type) {
    case ExamsRevAndDiagTestActionTypes.SET_LAST_ACTION:
      return {
        ...state,
        lastAction: action.value,
      };
    case ExamsRevAndDiagTestActionTypes.SET_PENDING_EXAMS:
      return {
        ...state,
        pendingExams: action.value.data,
        totalPendingExams: action.value.count,
      };
    case ExamsRevAndDiagTestActionTypes.SET_CURRENT_PENDING_EXAM:
      return {
        ...state,
        currentPendingExam: action.value,
      };
    case ExamsRevAndDiagTestActionTypes.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.value,
      };
    case ExamsRevAndDiagTestActionTypes.SET_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.value,
      };
    case ExamsRevAndDiagTestActionTypes.SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.value,
      };
    case ExamsRevAndDiagTestActionTypes.SET_HISTORICAL_EXAMS:
      return {
        ...state,
        historicalExams: action.value,
      };
    case ExamsRevAndDiagTestActionTypes.SET_PATIENTS_EXAMS_LIST:
      return {
        ...state,
        examList: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
