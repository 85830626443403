// Libraries
import { Reducer } from 'redux';

// Constants
import { ManagementPlanActionTypes } from './ManagementPlanActions';

const initialState: IManagementPlanReducerType = {
  lastAction: null,
  plan: null,
  previewPlans: [],
};

const reducer: Reducer<IManagementPlanReducerType, IAction> = (state = initialState, action: IAction): IManagementPlanReducerType => {
  switch (action.type) {
    case ManagementPlanActionTypes.SET_LAST_ACTION:
      return {
        ...state,
        lastAction: action.value,
      };
    case ManagementPlanActionTypes.SET_MANAGEMENT_PLAN:
      return {
        ...state,
        plan: action.value,
      };
    case ManagementPlanActionTypes.SET_PREVIEW_PLANS:
      return {
        ...state,
        previewPlans: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
