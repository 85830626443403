// Libraries
import to from 'await-to-js';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { mapKeys } from 'lodash';

import Constants from '../Utils/Constants';

const URL_BASE = process.env.BACKEND_URL;

const headers: any = {
  'Accept': 'application/json,',
  'Content-Type': 'application/json;charset=UTF-8',
};

/**
 * This function build your request. Support external and formData request too.
 * @param endpoint: Route to the request
 * @param params: Request params
 * @param method: post | get | put | delete
 * @param isExternalRequest: validate if is external route o internal
 * @param timeout: Time in Seconds
 * @param formDataFile: Upload image with formData
 */
const executeRequest: ExecuteRequestFuncType = async (endpoint, params, method = 'post', isExternalRequest = false, timeout = 10, formDataFile?) => {
  const options: AxiosRequestConfig = {
    method,
    timeout: timeout * 1000,
  };

  let url: string = isExternalRequest ? endpoint : `${URL_BASE}/api/${endpoint}`;

  if (method.toLowerCase() !== 'get') {
    headers['Content-Type'] = 'application/json;charset=UTF-8';
    options.data = JSON.stringify(params);

    if (formDataFile) {
      const finalData = new FormData();
      finalData.append('file', formDataFile);
      finalData.append('data', options.data);
      options.data = finalData;

      delete headers['Content-Type']; // The secret of form-data uploading files
    }
  }
  else if (params) {
    url += '?';
    url += concateGetParamsToUrl(params);
  }

  options.headers = headers;

  if (params.token) {
    options.headers.Authorization = params.token;
    delete params.token;
  }

  options.url = url;

  // const [err, response] = await to<IFetchResponse>(fetch(url, options));
  const [err, response] = await to<AxiosResponse, AxiosError>(axios(options));

  const finalError = centralizeErrorMsg(err, response);
  if (finalError) {
    throw finalError;
  }

  let finalResponse = null;
  if (response) {
    finalResponse = response.data;
  }

  return finalResponse;
};

/**
 * Centralize errors.
 * @param error Axios error Response with status
 * @param successResponse For validating additional errors in response
 */
const centralizeErrorMsg = (error: AxiosError, successResponse: AxiosResponse): IDialogWindowTypes => {
  const alertWindow: IDialogWindowTypes = { // Managing Error alerts
    show: true,
    type: 'error',
    title: 'Error',
  };

  if (error) {
    let serviceError = Constants.alert_messages.request_error;
    const response = error.response;
    if (response) {
      const errorStatus = (response && response.status) ? `${response.status} - ` : '';

      serviceError = response.statusText ? response.statusText : serviceError;
      if (typeof response.data === 'object' && response.data.error) {

        let errorMessage = response.data.error.message;
        if (errorMessage === 'SQL SERVER ERROR') {
          errorMessage = Constants.alert_messages.request_error_friendly;
        }

        serviceError = `${errorStatus}${errorMessage}`;
      }
    }

    alertWindow.message = serviceError;
    return alertWindow;
  }

  if (successResponse.status !== 200) {
    alertWindow.message = parsingStatusCodeMessages(successResponse.status);
    return alertWindow;
  }

  return null;
};

const parsingStatusCodeMessages = (statusCode: number): string => {
  let statusError = Constants.alert_messages.request_error;

  switch (statusCode) {
    case 204:
      statusError = Constants.alert_messages.record_not_found;
      break;
    default:
      break;
  }

  return statusError;
};

const concateGetParamsToUrl = (params: any): string => {
  const str: string[] = [];
  mapKeys(params, (value, key) => {
    return str.push(`${key}=${value}`);
  });
  return str.join('&');
};

export default executeRequest;
