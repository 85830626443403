import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { RouteComponentProps } from 'react-router';

// Material
import { Theme } from '@material-ui/core/styles';

// Module
import Header from '.';

// Actions
import { set_open_drawer, set_show_dialog } from '../../Reducers/App/AppActions';
import { set_data_user } from '../../Reducers/User/UserActions';

//#region TS Interfaces and Types
interface IOwnProps {
  classes?: any; // Styles inject in withStyles
  theme?: Theme; // Prop inject in withStyles->withTheme
  width?: string; // Prop inject in withWidth
  history?: HistoryType; // Prop inject in router Navigator
  hideDrawer?: boolean;
}
type TMapStateToProps = ReturnType<typeof mapStateToProps>;
type TMapDispatchToProps = ReturnType<typeof mapDispatchToProps>;

export type HeaderProps = IOwnProps & TMapStateToProps & TMapDispatchToProps & RouteComponentProps;
//#endregion TS Interfaces and Types

const mapStateToProps = (state: IRootState) => {
  return {
    app: state.app,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatchType) => bindActionCreators({
  // App Actions
  set_show_dialog,
  set_open_drawer,
  // User Actions
  set_data_user,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
