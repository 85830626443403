// Actions
import { AppActionTypes } from '../App/AppActions';
import { set_show_dialog } from '../App/AppActions';

// Utils
import Constants from '../../Utils/Constants';

/**
 * This function shows or hides the spinner depending on the given parameter
 * @param dispatch: Thunk dispatch for execute inside
 * @param show: Validate if show spinner
 * @param timeDeep: Spinner timeout in ms
 * @param isLoadingInfo: Spinner with alert message
 */
export const dispatchShowSpinner = (dispatch: IThunkDispatch, show: boolean, timeDeep?: number, isLoadingInfo?: boolean) => {
  const spinnerValue: ISpinnerType = show ? { type: isLoadingInfo ? 'loadingInfo' : 'show' } : { type: 'none' };

  if (timeDeep) {
    spinnerValue.timeDeep = timeDeep;
  }
  dispatch({ type: AppActionTypes.SHOW_SPINNER, value: spinnerValue });
};

/**
 * This function shows or hides the alert dialog
 * @param dispatch: Thunk dispatch for execute inside
 * @param show: Validate if show dialog
 * @param message: Set Message for dialog
 * @param type: Validate type for dialog
 */
export const dispatchShowDialog = (dispatch: IThunkDispatch, show: boolean, message: string, type: DialogType) => {
  const dialogObject = {
    show,
    type,
    message,
  };
  dispatch(set_show_dialog(dialogObject));
};

/**
 * This function shows error dialogs
 * @param dispatch: Thunk dispatch for execute inside
 * @param error: GraphQl Error Response
 */
export const dispatchShowResponseErrorDialog = (dispatch: IThunkDispatch, error: IGraphQlErrorResponse) => {
  let message = Constants.alert_messages.request_error;
  let statusCode = null;

  if (error) {
    const networkError = error.networkError;

    if (networkError && networkError.result) {
      const errorsGql = networkError.result.errors;
      const resultMessage = networkError.result.message;
      statusCode = networkError.result.code;

      if (resultMessage) {
        message = resultMessage;
      } else if (errorsGql && errorsGql.length > 0) {
        message = errorsGql[0].message;
      }

    } else if (error.message) {
      message = error.message;
      statusCode = error.code;
    }
  }

  const dialogObject = {
    show: true,
    type: 'error' as DialogType,
    message,
    statusCode,
  };

  dispatch(set_show_dialog(dialogObject));
};

/**
 * This function shows or hides the spinner depending on the given parameter
 * @param dispatch: Thunk dispatch for execute inside
 * @param show: Validate if show spinner
 */
export const dispatchLoadingTable = (dispatch: IThunkDispatch, show: boolean) => {
  dispatch({ type: AppActionTypes.SET_LOADING_TABLE, value: show });
};
