import { batch } from 'react-redux';

// Third Party modules
import { to } from 'await-to-js';
import { cloneDeep, find, findIndex, isUndefined, map, pullAt } from 'lodash';

// Utils
import { dispatchLoadingTable, dispatchShowDialog, dispatchShowSpinner } from '../App/AppUtils';
import { concatResponse, dispatchDataResponse, parsingDates, validateErrorFromClientRequest } from '../ReducersUtils';

// External Actions
import { set_loading_dropdown } from '../App/AppActions';

// Service
import executeRequest from '../../Services/ServiceBase';

export enum CupActionTypes {
  SET_LAST_ACTION = 'cup/SET_LAST_ACTION',
  SET_TAB_POSITION = 'cup/SET_TAB_POSITION',
  SET_CURRENT_CUP = 'cup/SET_CURRENT_CUP',
  SET_PREVIEW_CUPS = 'cup/SET_PREVIEW_CUPS',
  SET_CUP_OPTIONS = 'cup/SET_CUP_OPTIONS',
  SET_SELECTED_SEARCH_CUP = 'cup/SET_SELECTED_SEARCH_CUP',
  SET_SEARCH_CUP_TERM = 'cup/SET_SEARCH_CUP_TERM',
  SET_CUP_RESPONSE = 'cup/SET_CUP_RESPONSE',
  SET_CURRENT_PAGE = 'cup/SET_CURRENT_PAGE',
  SET_ROWS_PER_PAGE = 'cup/SET_ROWS_PER_PAGE',
  SET_SEARCH_TERM = 'cup/SET_SEARCH_TERM',
  SET_HISTORICAL_DATA = 'cup/SET_HISTORICAL_DATA',
  SET_DEFAULT_LOOKUP = 'cup/SET_DEFAULT_LOOKUP',
}

export const set_last_action = (value: CupActionTypes): IAction => ({ type: CupActionTypes.SET_LAST_ACTION, value });
export const set_tab_position = (value: number): IAction => ({ type: CupActionTypes.SET_TAB_POSITION, value });

export const set_current_cup = (value: ICupsData): IAction => ({ type: CupActionTypes.SET_CURRENT_CUP, value });

export const set_preview_cups
  = (value: { data: ICupsData[], count: number }): IAction => ({ type: CupActionTypes.SET_PREVIEW_CUPS, value });
export const set_cup_options = (value: IOptionsData[]): IAction => ({ type: CupActionTypes.SET_CUP_OPTIONS, value });

export const set_search_cup_term = (value: string): IAction => ({ type: CupActionTypes.SET_SEARCH_CUP_TERM, value });

export const set_cup_response = (value: ICupsResponse[]): IAction => ({ type: CupActionTypes.SET_CUP_RESPONSE, value });

export const set_current_page = (page: number): IAction => ({ type: CupActionTypes.SET_CURRENT_PAGE, value: page });
export const set_rows_per_page = (value: number): IAction => ({ type: CupActionTypes.SET_ROWS_PER_PAGE, value });
export const set_search_term = (value: string): IAction => ({ type: CupActionTypes.SET_SEARCH_TERM, value });

export const set_historical_data = (value: IHistoricalCup[]): IAction => ({ type: CupActionTypes.SET_HISTORICAL_DATA, value });

export const set_default_lookup = (value: boolean): IAction => ({ type: CupActionTypes.SET_DEFAULT_LOOKUP, value });

export const set_selected_search_cup = (value: IOptionsData): IThunkResult => {
  return async (dispatch, getState) => {
    batch(() => {
      const selectedCup: ICupsResponse = find(getState().cup.cupsDropdownResponse, { cupTechnicalId: value ? value.value : null });

      if (selectedCup) {
        const currentCup: Partial<ICupsData> = getState().cup.currentCup || {};
        currentCup.cupTechnicalCode = selectedCup.cupTechnicalId;
        dispatch(set_current_cup(currentCup as ICupsData));
      }

      dispatch({ type: CupActionTypes.SET_SELECTED_SEARCH_CUP, value });
      dispatch(set_search_cup_term(value ? value.label : null));
    });
  };
};

export const get_attention_cups = (paginationObj: IPaginationObject): IThunkResult => {
  return async (dispatch, getState) => {

    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    let data: IDrugFormulationData[] = [];
    let formulationCount: number = -1;

    let hasDoctorRecords = false;

    if (paginationObj.doRequest) {
      dispatchLoadingTable(dispatch, true);
      const cupReducer = getState().cup;
      const currentData = cupReducer.previewCups;
      const page = paginationObj.reset ? 0 : currentData.length;

      const currentPatient = getState().patient.currentPatient;
      const currentDoctor = getState().medicalHistory.currentDoctor;

      const params = {
        attention_number: currentPatient.attentionNumber,
        user_name: loggedUser.firstName,
        doctor_id: currentDoctor.value,
        cup_technical_group_id: cupReducer.tabPosition + 1,
      };

      const [error, previewCupsResponse] = await to<ICupsResponse>(executeRequest(`attention_cups`, params, 'get'));
      const errorParsed = validateErrorFromClientRequest(error, previewCupsResponse);

      if (errorParsed) {
        dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
        return;
      }

      hasDoctorRecords = previewCupsResponse.hasSetUpRegisters;

      const attentionCups = previewCupsResponse.attentionCups;

      formulationCount = attentionCups.length;
      const parsedData: IDrugFormulationData[] = parsingDates(attentionCups, ['date']);
      data = concatResponse(currentData, parsedData, page);
    }

    if (paginationObj.page === 0) {
      dispatch(set_default_lookup(hasDoctorRecords)); // For knowing if there are pre-configured doctor records
    }

    dispatchDataResponse(dispatch, {
      setCurrentPageDispatch: set_current_page,
      setRowsPerPageDispatch: set_rows_per_page,
      setDataDispatch: set_preview_cups,
    }, paginationObj, data, formulationCount);
  };
};

export const search_cups = (): IThunkResult => {
  return async (dispatch, getState) => {

    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    const cup = getState().cup;
    const maxCharacters = 4;
    const isDoctorFilterSelected = getState().cup.doctorLookUpDefault;
    if (!isDoctorFilterSelected && (!cup.searchCupTerm || cup.searchCupTerm.length < maxCharacters)) {
      dispatchShowDialog(dispatch, true, `Escriba ${maxCharacters} caracteres como mínimo para buscar`, 'warning');
      return;
    }

    dispatch(set_loading_dropdown(true));

    const params: any = {
      filter: cup.searchCupTerm || '',
      user_name: loggedUser.firstName,
      cup_technical_group_id: cup.tabPosition + 1,
    };

    if (cup.doctorLookUpDefault) {
      const currentDoctor = getState().medicalHistory.currentDoctor;
      params.doctor_id = currentDoctor.value;
    }

    const [error, cupsOptionsResponse] = await to<ICupsResponse[]>(executeRequest(`cups_by_filters`, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, cupsOptionsResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    const cupsOptions: IOptionsData[] = [{ label: 'Seleccione un valor', value: 'default' }];

    map(cupsOptionsResponse, (eachCup) => {
      cupsOptions.push({
        value: eachCup.cupTechnicalId,
        label: `${eachCup.cupTechnicalId} - ${eachCup.cupTechnicalName}`,
      });
    });

    batch(() => {
      dispatch(set_loading_dropdown(false));
      dispatch(set_cup_options(cupsOptions));
      dispatch(set_cup_response(cupsOptionsResponse));
    });
  };
};

const allInfoIsValid = (dispatch: IThunkDispatch, cup: ICupReducerType): boolean => {
  const currentCup = cup.currentCup;

  const msgPrefix = 'Por favor digite';
  if (!currentCup) {
    dispatchShowDialog(dispatch, true, `${msgPrefix} toda la información`, 'warning');
    return false;
  }

  if (!currentCup.quantity) {
    dispatchShowDialog(dispatch, true, `${msgPrefix} la cantidad`, 'warning');
    return false;
  }

  if (!cup.cupSelected) {
    dispatchShowDialog(dispatch, true, `Por favor seleccione el CUP`, 'warning');
    return false;
  }

  return true;
};

export const save_cup = (cupEdit?: ICupsData): IThunkResult => {
  return async (dispatch, getState) => {

    if (getState().patient.currentPatient.attentionStatus === 'C') {
      return dispatchShowDialog(dispatch, true, 'Válido solo para citas abiertas', 'warning');
    }

    const cup = getState().cup;
    const previewCups = cup.previewCups;

    let index;
    if (cupEdit) {
      index = findIndex(previewCups, { consecutive: cupEdit.consecutive });
    }

    const isNewRecord = isUndefined(index);
    const currentCup = isNewRecord ? cup.currentCup : previewCups[index];
    if (isNewRecord) {
      if (!allInfoIsValid(dispatch, cup)) {
        return;
      }
    }

    const params: IObj = {
      cup_technical_code: isNewRecord ? cup.cupSelected.value : currentCup.cupTechnicalCode,
      observation: currentCup.observation || '',
      quantity: currentCup.quantity,
      medical_verification: false,
      cup_technical_group_id: cup.tabPosition + 1,
    };

    if (!isNewRecord) {
      params.consecutive = currentCup.consecutive;
    }

    const loggedUser = getState().user.data;
    const currentDoctor = getState().medicalHistory.currentDoctor;
    const currentPatient = getState().patient.currentPatient;

    // Default Values
    params.attention_number = currentPatient.attentionNumber;
    params.doctor_id = currentDoctor.value;
    params.user_name = loggedUser.firstName;

    dispatchShowSpinner(dispatch, true);

    const [error, createCupResponse] = await to(executeRequest(`create_attention_cup`, params, 'post'));
    const errorParsed = validateErrorFromClientRequest(error, createCupResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    dispatchShowDialog(dispatch, true, 'Registro guardado existosamente', 'success');

    batch(() => {
      if (isNewRecord) {
        dispatch(set_current_cup(null));
        dispatch(set_selected_search_cup(null));
        dispatch(get_attention_cups({
          page: 0,
          rowsPerPage: cup.rowsPerPage,
          doRequest: true,
          reset: true,
        }));
      }
    });
  };
};

export const delete_cup = (cupDelete: ICupsData): IThunkResult => {
  return async (dispatch, getState) => {

    if (getState().patient.currentPatient.attentionStatus === 'C') {
      return dispatchShowDialog(dispatch, true, 'Válido solo para citas abiertas', 'warning');
    }

    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    dispatchShowSpinner(dispatch, true);

    const params = {
      consecutive: cupDelete.consecutive,
      user_name: loggedUser.firstName,
    };

    const [error, deleteCup] = await to(executeRequest(`delete_attention_cup`, params, 'post'));
    const errorParsed = validateErrorFromClientRequest(error, deleteCup);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    dispatchShowDialog(dispatch, true, 'Registro eliminado existosamente', 'success');

    const cup = cloneDeep(getState().cup);
    const previewCups = cup.previewCups;

    const index = findIndex(previewCups, { consecutive: cupDelete.consecutive });
    if (index !== -1) {
      pullAt(previewCups, index);
      dispatch(set_preview_cups({ data: previewCups, count: previewCups.length }));
    }
  };
};

export const get_historical_cup = (): IThunkResult => {
  return async (dispatch, getState) => {
    dispatchShowSpinner(dispatch, true);

    const tabPosition = getState().cup.tabPosition;

    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    const currentPatient = getState().patient.currentPatient;

    const params = {
      cup_group_id: tabPosition + 1,
      user_name: loggedUser.firstName,
      patient_id: currentPatient.patientUserId,
    };

    const [error, response] = await to<IHistoricalCup[]>(executeRequest(`attention_cups_transverse`, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, response);
    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    batch(() => {
      dispatch(set_historical_data(response));
      dispatchShowSpinner(dispatch, false);
    });
  };
};
