import createStyles from '@material-ui/core/styles/createStyles';
import Color from '../../../Theme/Colors';

const styles = () => createStyles({
  colorDivider: {
    background: Color.drawerDivider,
  },
  colorDividerHide: {
    background: Color.transparent,
  },
  drawerItemsListExpanded: {
    background: Color.drawerListItemExpandedBackgroundColor,
  },
  drawerItemsListCollapse: {
    'background': Color.drawerBackgroundColor,
    '&:hover': {
      background: Color.drawerHoverBackgroundColor,
    },
  },
});

export default styles;
