// Libraries
import { Reducer } from 'redux';

// Constants
import Constants from '../../Utils/Constants';
import { DrugFormulationActionTypes } from './DrugFormulationActions';

const initialState: IDrugFormulationReducerType = {
  lastAction: null,
  currentDrugFomulation: null,
  medicationType: 'comercial',
  previewFormulations: [],
  searchDrugTerm: null,
  medicineOptions: [],
  medicineSelected: null,
  medicinesResponse: null,
  currentPage: 0,
  rowsPerPage: Constants.limits.page_limit,
  totalFormulations: 0,
  searchTerm: null,
  doctorLookUpDefault: false,
  historicalMedicines: [],
};

const reducer: Reducer<IDrugFormulationReducerType, IAction> = (state = initialState, action: IAction): IDrugFormulationReducerType => {
  switch (action.type) {
    case DrugFormulationActionTypes.SET_LAST_ACTION:
      return {
        ...state,
        lastAction: action.value,
      };
    case DrugFormulationActionTypes.SET_DRUG_TYPE:
      return {
        ...state,
        medicationType: action.value,
      };
    case DrugFormulationActionTypes.SET_CURRENT_DRUG:
      return {
        ...state,
        currentDrugFomulation: action.value,
      };
    case DrugFormulationActionTypes.SET_PREVIEW_FORMULATIONS:
      return {
        ...state,
        previewFormulations: action.value.data,
        totalFormulations: action.value.count,
      };
    case DrugFormulationActionTypes.SET_SEARCH_DRUG_TERM:
      return {
        ...state,
        searchDrugTerm: action.value,
      };
    case DrugFormulationActionTypes.SET_MEDICINE_OPTIONS:
      return {
        ...state,
        medicineOptions: action.value,
      };
    case DrugFormulationActionTypes.SET_SELECTED_SEARCH_DRUG:
      return {
        ...state,
        medicineSelected: action.value,
      };
    case DrugFormulationActionTypes.SET_MEDICINE_REPONSE:
      return {
        ...state,
        medicinesResponse: action.value,
      };
    case DrugFormulationActionTypes.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.value,
      };
    case DrugFormulationActionTypes.SET_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.value,
      };
    case DrugFormulationActionTypes.SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.value,
      };
    case DrugFormulationActionTypes.SET_DEFAULT_LOOKUP:
      return {
        ...state,
        doctorLookUpDefault: action.value,
      };
    case DrugFormulationActionTypes.SET_HISTORICAL_MEDICINES:
      return {
        ...state,
        historicalMedicines: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
