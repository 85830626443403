import { batch } from 'react-redux';

// Third Party modules
import { to } from 'await-to-js';
import { map } from 'lodash';

// Utils
import { dispatchShowDialog, dispatchShowSpinner } from '../App/AppUtils';
import { validateErrorFromClientRequest } from '../ReducersUtils';

// Service
import executeRequest from '../../Services/ServiceBase';

export enum TemplatesActionTypes {
  SET_TEMPLATES = 'templates/SET_TEMPLATES',
  SET_TEMPLATES_OPTIONS = 'templates/SET_TEMPLATES_OPTIONS',
  SET_TEMPLATE_SELECTED = 'templates/SET_TEMPLATE_SELECTED',
}

export const set_templates = (value: ITemplates[]): IAction => ({ type: TemplatesActionTypes.SET_TEMPLATES, value });
export const set_templates_options = (value: IOptionsData[]): IAction => ({ type: TemplatesActionTypes.SET_TEMPLATES_OPTIONS, value });
export const set_template_selected = (value: IOptionsData): IAction => ({ type: TemplatesActionTypes.SET_TEMPLATE_SELECTED, value });

export const get_templates = (section_id: number): IThunkResult => {
  return async (dispatch, getState) => {

    dispatchShowSpinner(dispatch, true);

    const loggedUser = getState().user.data;
    const currentDoctor = getState().medicalHistory.currentDoctor;

    const params = {
      doctor_id: currentDoctor.value,
      user_name: loggedUser.firstName,
      section_id,
    };

    const [error, templates] = await to<ITemplates[]>(executeRequest(`templates`, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, templates);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    const templateOptions: IOptionsData[] = [{ label: 'Seleccione una plantilla', value: 'default' }];

    map(templates, (template: ITemplates) => {
      templateOptions.push({
        label: template.templateName,
        value: template.templateId,
      });
    });

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(set_templates(templates));
      dispatch(set_templates_options(templateOptions));
    });
  };
};

export const get_template_content = (template_id: number, callback: (value: string) => IAction): IThunkResult => {
  return async (dispatch) => {

    dispatchShowSpinner(dispatch, true);

    const params = {
      template_id,
    };

    const [error, template] = await to<ITemplates>(executeRequest(`templates_content`, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, template);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(callback(template.templateContent));
      dispatch(set_template_selected({
        value: template.templateId,
        label: template.templateName,
      }));
    });
  };
};
