import { batch } from 'react-redux';

// Third Party modules
import { to } from 'await-to-js';
import { find, findIndex, map, pullAt } from 'lodash';

// Utils
import { copyObject } from '../../Utils';
import Constants from '../../Utils/Constants';
import { dispatchShowDialog, dispatchShowSpinner } from '../App/AppUtils';
import { validateErrorFromClientRequest } from '../ReducersUtils';

// External Actions
import {
  set_report_data,
  set_selected_report_type,
} from '../MedicalHistory/MedicalHistoryActions';

// Service
import executeRequest from '../../Services/ServiceBase';
import {
  buildExamsTypeInputFormat,
  buildRefractionExamsFormat,
  buildSaveExamsFormat,
  buildSaveToMotilityAndColorExamsFormat,
  buildVisualExamsFormat,
} from './Utils';

export enum PhysicalExamActionTypes {
  SET_LAST_ACTION = 'PhysicalExam/SET_LAST_ACTION',
  SET_PREVIUOS_FINDING = 'PhysicalExam/SET_PREVIUOS_FINDING',
  SET_CURRENT_FINDING = 'PhysicalExam/SET_CURRENT_FINDING',
  SET_SPECIALIZED_MESSAGES = 'PhysicalExam/SET_SPECIALIZED_MESSAGES',
  SET_SELECTED_SPECIALIZED_MESSAGES = 'PhysicalExam/SET_SELECTED_SPECIALIZED_MESSAGES',
  SET_VARIABLE_VISUAL_EXAM = 'PhysicalExam/SET_VARIABLE_VISUAL_EXAM',
  SET_VARIABLE_REFRACTION_EXAM = 'PhysicalExam/SET_VARIABLE_REFRACTION_EXAM',
  SET_PRE_CHARGED_VARIABLES = 'PhysicalExam/SET_PRE_CHARGED_VARIABLES',
  SET_PRE_CHARGED_HISTORICAL_VARIABLES = 'PhysicalExam/SET_PRE_CHARGED_HISTORICAL_VARIABLES',
  SET_SPECIALIZED_HISTORICAL = 'PhysicalExam/SET_SPECIALIZED_HISTORICAL',
  MOTILITY = 'PhysicalExam/MOTILITY',
  COLOR_TEST = 'PhysicalExam/COLOR_TEST',
  SET_REPORT_DATA = 'PhysicalExam/SET_REPORT_DATA',
}

export const set_last_action = (value?: PhysicalExamActionTypes): IAction => ({ type: PhysicalExamActionTypes.SET_LAST_ACTION, value });
export const set_previuos_finding = (value: IFinding[]): IAction => ({ type: PhysicalExamActionTypes.SET_PREVIUOS_FINDING, value });
export const set_current_finding = (value: IFinding): IAction => ({ type: PhysicalExamActionTypes.SET_CURRENT_FINDING, value });
export const set_specialized_messages = (value: IOptionsData[]): IAction => ({ type: PhysicalExamActionTypes.SET_SPECIALIZED_MESSAGES, value });
export const set_selected_specialized_messages =
  (value: IOptionsData): IAction => ({ type: PhysicalExamActionTypes.SET_SELECTED_SPECIALIZED_MESSAGES, value });

export const set_variable_visual_exam = (value: IVariableVisualExams): IAction => ({ type: PhysicalExamActionTypes.SET_VARIABLE_VISUAL_EXAM, value });
export const set_variable_refraction_exam =
  (value: IVariableRefractorExams): IAction => ({ type: PhysicalExamActionTypes.SET_VARIABLE_REFRACTION_EXAM, value });
export const set_motility = (value: any): IAction => ({ type: PhysicalExamActionTypes.MOTILITY, value });
export const set_color_test = (value: any): IAction => ({ type: PhysicalExamActionTypes.COLOR_TEST, value });
export const set_pre_charged_variables =
  (value: IPreChargedVariables[]): IAction => ({ type: PhysicalExamActionTypes.SET_PRE_CHARGED_VARIABLES, value });
export const set_pre_charged_historical_variables =
  (value: IPreChargedVariables[]): IAction => ({ type: PhysicalExamActionTypes.SET_PRE_CHARGED_HISTORICAL_VARIABLES, value });

export const set_specialized_historical =
  (value: ISpecializedHistorical[]): IAction => ({ type: PhysicalExamActionTypes.SET_SPECIALIZED_HISTORICAL, value });

/**
 * Get all diagnostics to all regions
 */
export const get_physical_exam_diagnostics = (): IThunkResult => {
  return async (dispatch, getState) => {

    // dispatchShowSpinner(dispatch, true);

    const currentPatient = getState().patient.currentPatient;

    const params = {
      attentionNumber: currentPatient.attentionNumber,
    };

    const [error, diagnosticsResponse] = await to<IFinding[]>(executeRequest(`physical_exam_diagnostic`, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, diagnosticsResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    batch(() => {
      // dispatchShowSpinner(dispatch, false);
      dispatch(set_previuos_finding(diagnosticsResponse));
    });
  };
};

/**
 * Create new diagnostic with a symptom on a region
 */
export const save_current_finding = (): IThunkResult => {
  return async (dispatch, getState) => {
    if (getState().patient.currentPatient.attentionStatus === 'C') {
      return dispatchShowDialog(dispatch, true, 'Válido solo para citas abiertas', 'warning');
    }

    const currentFinding = copyObject(getState().physicalExam.currentFinding);
    if (!currentFinding) {
      dispatchShowDialog(dispatch, true, Constants.alert_messages.select_finding, 'warning');
      return;
    }

    if (currentFinding && !currentFinding.symptomFound) {
      dispatchShowDialog(dispatch, true, 'Debe agregar alguna descripción al hallazgo', 'warning');
      return;
    }

    const copyPreviuosFindings = getState().physicalExam.previousFindings;

    const currentFindingExist = find(copyPreviuosFindings, { physicalExamRegionId: currentFinding.physicalExamRegionId });
    if (currentFindingExist) {
      const errorMessage = 'Este hallazgo ya ha sido agregado previamente, puede editar el existente sobre los recuadros de abajo';
      dispatchShowDialog(dispatch, true, errorMessage, 'warning');
      return;
    }

    dispatchShowSpinner(dispatch, true);

    const currentDoctor = getState().medicalHistory.currentDoctor;
    const currentPatient = getState().patient.currentPatient;

    const params = {
      attentionNumber: currentPatient.attentionNumber,
      diagnostic: currentFinding.symptomFound,
      doctor: currentDoctor.value,
      doctorName: currentDoctor.label,
      regionId: currentFinding.physicalExamRegionId,
    };

    const [error, diagnosticsResponse] = await to<string>(executeRequest(`create_physical_exam`, params, 'post'));
    const errorParsed = validateErrorFromClientRequest(error, diagnosticsResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    currentFinding.status = 'A'; // To render it correctly
    copyPreviuosFindings.unshift(currentFinding);

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(set_previuos_finding(copyPreviuosFindings));
      dispatch(set_current_finding(null));
      dispatchShowDialog(dispatch, true, 'Creado Exitosamente', 'success');
    });
  };
};

/**
 * Update diagnostic in a specific region.
 * @param finding edited finding
 */
export const update_physical_exam = (finding: IFinding): IThunkResult => {
  return async (dispatch, getState) => {

    if (getState().patient.currentPatient.attentionStatus === 'C') {
      return dispatchShowDialog(dispatch, true, 'Válido solo para citas abiertas', 'warning');
    }

    dispatchShowSpinner(dispatch, true);

    const currentDoctor = getState().medicalHistory.currentDoctor;
    const currentPatient = getState().patient.currentPatient;

    const params = {
      attentionNumber: currentPatient.attentionNumber,
      diagnostic: finding.symptomFound,
      doctor: currentDoctor.value,
      doctorName: currentDoctor.label,
      regionId: finding.physicalExamRegionId,
    };

    const [error, diagnosticsResponse] = await to<string>(executeRequest(`update_physical_exam`, params, 'post'));
    const errorParsed = validateErrorFromClientRequest(error, diagnosticsResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    dispatchShowDialog(dispatch, true, 'Editado Exitosamente', 'success');

    batch(() => {
      dispatchShowSpinner(dispatch, false);
    });
  };
};

/**
 * Delete diagnostic in a specific region.
 * @param doctorName Name of doctor rarely can be anything which is wrong
 * @param regionId Region Id to be deleted
 */
export const delete_physical_exam = (regionId: number): IThunkResult => {
  return async (dispatch, getState) => {

    if (getState().patient.currentPatient.attentionStatus === 'C') {
      return dispatchShowDialog(dispatch, true, 'Válido solo para citas abiertas', 'warning');
    }

    dispatchShowSpinner(dispatch, true);

    const currentPatient = getState().patient.currentPatient;
    const currentDoctor = getState().medicalHistory.currentDoctor;

    const params = {
      attentionNumber: currentPatient.attentionNumber,
      doctorName: currentDoctor.label,
      regionId,
    };

    const [error, diagnosticsResponse] = await to<string>(executeRequest(`delete_physical_exam`, params, 'post'));
    const errorParsed = validateErrorFromClientRequest(error, diagnosticsResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    const copyPreviuosFindings = getState().physicalExam.previousFindings;
    const index = findIndex(copyPreviuosFindings, { physicalExamRegionId: regionId });

    if (index !== -1) {
      pullAt(copyPreviuosFindings, index);
    }

    dispatchShowDialog(dispatch, true, 'Borrado Exitosamente', 'success');

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(set_previuos_finding(copyPreviuosFindings));
    });
  };
};

export const get_specialized_exams_by_doctor = (skip?: boolean): IThunkResult => {
  return async (dispatch, getState) => {

    if (getState().patient.currentPatient.attentionStatus === 'C' && !skip) {
      dispatch(set_specialized_messages([]));
      return dispatchShowDialog(dispatch, true, 'Válido solo para citas abiertas', 'warning');
    }

    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    dispatchShowSpinner(dispatch, true);

    // const currentPatient = getState().patient.currentPatient;
    const currentDoctor = getState().medicalHistory.currentDoctor;

    const params = {
      doctor_id: currentDoctor.value,
      user_name: loggedUser.firstName,
    };

    const [error, specializedResponse] = await to<IEspecializedExams[]>(executeRequest(`specialized_exams_by_doctor`, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, specializedResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    const specializedOptions: IOptionsData[] = [{ label: 'Seleccione un examen especializado', value: 'default' }];

    map(specializedResponse, (spResp) => {
      specializedOptions.push({
        value: spResp.specializedExamId,
        label: spResp.specializedExam,
      });
    });

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(set_specialized_messages(specializedOptions));
      dispatch(get_specialized_exams_by_attention());
    });
  };
};

export const get_variables_by_exams = (examSelected: IOptionsData, historicalAttentionNumber?: number): IThunkResult => {
  return async (dispatch, getState) => {
    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    dispatchShowSpinner(dispatch, true);

    const params = {
      specialized_exam_id: examSelected.value,
      user_name: loggedUser.firstName,
    };

    const [error, variablesResponse] = await to<IVariableExamsResponse[]>(executeRequest(`variables_by_exams`, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, variablesResponse);
    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    const [variablesListsError, variablesListsResponse] = await to<IVariableListResponse[]>(
      executeRequest(`variables_by_exams_lists`, params, 'get'),
    );
    const variablesListsErrorParsed = validateErrorFromClientRequest(variablesListsError, variablesListsResponse);
    if (variablesListsErrorParsed) {
      dispatchShowDialog(dispatch, true, variablesListsErrorParsed.message, 'error');
      return;
    }

    batch(() => {
      const physicalExam = getState().physicalExam;
      const preChargedVariables = historicalAttentionNumber ? physicalExam.preChargedHistoricalVariables : physicalExam.preChargedVariables;

      // The next line is necessary for 1 and 2... because the variablesResponse is being mutated
      const formattedExamsTypeInput = buildExamsTypeInputFormat(variablesResponse, variablesListsResponse, preChargedVariables);

      if (examSelected.value === 1) {
        const formattedExams = buildVisualExamsFormat(variablesResponse, preChargedVariables);
        dispatch(set_variable_visual_exam(formattedExams));
      }
      else if (examSelected.value === 2) {
        const formattedExams = buildRefractionExamsFormat(variablesResponse, preChargedVariables);
        dispatch(set_variable_refraction_exam(formattedExams));
      }
      else if (examSelected.value === 3) {
        dispatch(set_motility(formattedExamsTypeInput));
      }
      else if (examSelected.value === 4) {
        dispatch(set_color_test(formattedExamsTypeInput));
      }

      dispatchShowSpinner(dispatch, false);
    });
  };
};

export const get_specialized_exams_by_attention = (historicalAttentionNumber?: number): IThunkResult => {
  return async (dispatch, getState) => {
    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    dispatchShowSpinner(dispatch, true);

    const currentPatient = getState().patient.currentPatient;
    const params = {
      attention_number: historicalAttentionNumber || currentPatient.attentionNumber,
      user_name: loggedUser.firstName,
    };

    const [error, preChargedvariablesResponse] = await to<IPreChargedVariables[]>(executeRequest(`specialized_exams_by_attention`, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, preChargedvariablesResponse);
    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      if (historicalAttentionNumber) {
        dispatch(set_pre_charged_historical_variables(preChargedvariablesResponse));
        dispatch(get_variables_by_exams(getState().physicalExam.selectedEspecializedExams, historicalAttentionNumber));
      } else {
        dispatch(set_pre_charged_variables(preChargedvariablesResponse));
      }
    });
  };
};

export const create_especialized_exams = (): IThunkResult => {
  return async (dispatch, getState) => {

    if (getState().patient.currentPatient.attentionStatus === 'C') {
      return dispatchShowDialog(dispatch, true, 'Válido solo para citas abiertas', 'warning');
    }

    dispatchShowSpinner(dispatch, true);

    const physicalExam = getState().physicalExam;

    const selectedEspecializedExams = physicalExam.selectedEspecializedExams;

    let exams: IVariableVisualExams | IVariableRefractorExams = physicalExam.variableVisualExams;
    if (selectedEspecializedExams.value === 2) {
      exams = physicalExam.variableRefractionExams;
    }

    const formatedExams: ISaveSpecializedExams[] = buildSaveExamsFormat(exams);

    const currentDoctor = getState().medicalHistory.currentDoctor;
    const currentPatient = getState().patient.currentPatient;

    const params = {
      attention_number: currentPatient.attentionNumber,
      doctor_id: currentDoctor.value,
      specialized_exam_id: selectedEspecializedExams.value,
      specialized_id: '69BBB110-6B87-4450-BE42-368BC3FDFB22',
      exam_variables: formatedExams,
    };

    if (selectedEspecializedExams.value === 3 || selectedEspecializedExams.value === 4) {
      const [examVariablesFormated, newExamVariables] = buildSaveToMotilityAndColorExamsFormat(physicalExam, selectedEspecializedExams.value);
      params.exam_variables = examVariablesFormated;

      dispatch(set_motility(newExamVariables));
    }

    const [error, diagnosticsResponse] = await to<string>(executeRequest(`create_specialized_exams_by_attention`, params, 'post'));
    const errorParsed = validateErrorFromClientRequest(error, diagnosticsResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    dispatchShowDialog(dispatch, true, 'Valores guardados existosamente', 'success');

    batch(() => {
      dispatchShowSpinner(dispatch, false);
    });
  };
};

export const get_specialized_historical = (): IThunkResult => {
  return async (dispatch, getState) => {
    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    dispatchShowSpinner(dispatch, true);

    const currentPatient = getState().patient.currentPatient;
    const currentDoctor = getState().medicalHistory.currentDoctor;

    const params = {
      doctor_id: currentDoctor.value,
      patient_id: currentPatient.patientUserId,
      user_name: loggedUser.firstName,
    };

    const [error, specializedHistoricaResponse] =
      await to<ISpecializedHistorical[]>(executeRequest(`specialized_exams_by_attention_historic`, params, 'get'));

    const errorParsed = validateErrorFromClientRequest(error, specializedHistoricaResponse);
    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(set_specialized_historical(specializedHistoricaResponse));
    });
  };
};

export const get_specialized_exams_pdf_data = (): IThunkResult => {
  return async (dispatch, getState) => {
    dispatch(set_selected_report_type('specializedExams'));

    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    dispatchShowSpinner(dispatch, true);

    const params = {
      attention_number: getState().patient.currentPatient.attentionNumber,
      patient_id: getState().patient.currentPatient.patientUserId,
      user_name: loggedUser.firstName,
    };

    const [error, specializedExamsResponse] = await to<ISpecializedHistorical[]>(executeRequest(`specialized_exams_pdf_data`, params, 'get'));

    const errorParsed = validateErrorFromClientRequest(error, specializedExamsResponse);
    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(set_report_data(specializedExamsResponse as any));
      dispatch(set_last_action(PhysicalExamActionTypes.SET_REPORT_DATA));
    });
  };
};
