import { map } from 'lodash';

type MeasureOptionsFunc = (
  measuresData: IMeasureOptionsResponse[],
  doctorLookUpDefault: boolean,
  dispatch: IThunkDispatch,
  set_measure_options: (value: IOptionsData[]) => IAction,
) => any;

export const setMeasureOptions: MeasureOptionsFunc = (measuresData, doctorLookUpDefault, dispatch, set_measure_options) => {
  const measuresOptions: IOptionsData[] = [{ label: 'Seleccione una medida', value: 'default' }];

  map(measuresData, (measureOption) => {
    if (!doctorLookUpDefault || (doctorLookUpDefault && measureOption.preConfiguredByDoctor)) {
      measuresOptions.push({
        value: measureOption.anthropometricMeasureId,
        label: measureOption.anthropometricMeasureName,
      });
    }
  });

  dispatch(set_measure_options(measuresOptions));
};
