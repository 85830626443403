// Libraries
import { Reducer } from 'redux';

// Constants
import { PatientActionTypes } from './PatientActions';

const initialState: IPatientReducerType = {
  currentPatient: null,
  lastAction: null,
  patientSections: [],
  agreements: [],
  birthCities: [],
  bloodTypes: [],
  countries: [],
  documentTypes: [],
  ethnicGroups: [],
  genders: [],
  healthEntities: [],
  maritalStatus: [],
  occupations: [],
  plans: [],
  relationshipTypes: [],
  residenceCities: [],
  scholarship: [],
};

const reducer: Reducer<IPatientReducerType, IAction> = (state = initialState, action: IAction): IPatientReducerType => {
  switch (action.type) {
    case PatientActionTypes.SET_CURRENT_PATIENT:
      return {
        ...state,
        currentPatient: action.value,
      };
    case PatientActionTypes.SET_LAST_ACTION:
      return {
        ...state,
        lastAction: action.value,
      };
    case PatientActionTypes.SET_PATIENT_SECTIONS:
      return {
        ...state,
        patientSections: action.value,
      };
    case PatientActionTypes.SET_PATIENT_LISTS:
      return {
        ...state,
        bloodTypes: action.value.bloodTypes,
        countries: action.value.countries,
        documentTypes: action.value.documentTypes,
        ethnicGroups: action.value.ethnicGroups,
        genders: action.value.genders,
        maritalStatus: action.value.maritalStatus,
        occupations: action.value.occupations,
        plans: action.value.plans,
        relationshipTypes: action.value.relationshipTypes,
        scholarship: action.value.scholarship,
      };
    case PatientActionTypes.SET_RESIDENCE_COUNTRY_LISTS:
      return {
        ...state,
        agreements: action.value.agreements,
        healthEntities: action.value.healthEntities,
        residenceCities: action.value.cities,
      };
    case PatientActionTypes.SET_BIRTH_COUNTRY_LISTS:
      return {
        ...state,
        birthCities: action.value.cities,
      };
    default:
      return state;
  }
};

export default reducer;
