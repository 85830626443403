import React from 'react';

// MaterialIcons
import AccountBox from '@material-ui/icons/AccountBox';
import AddCircle from '@material-ui/icons/AddCircle';
import Alarm from '@material-ui/icons/Alarm';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Close from '@material-ui/icons/Close';
import Edit from '@material-ui/icons/Edit';
import EnhancedEncryption from '@material-ui/icons/EnhancedEncryption';
import ErrorIcon from '@material-ui/icons/Error';
import GetApp from '@material-ui/icons/GetApp';
import Info from '@material-ui/icons/Info';
import ListAlt from '@material-ui/icons/ListAlt';
import LockOpen from '@material-ui/icons/LockOpen';
import Mic from '@material-ui/icons/Mic';
import Refresh from '@material-ui/icons/Refresh';
import Save from '@material-ui/icons/Save';
import Sync from '@material-ui/icons/Sync';
import Visibility from '@material-ui/icons/Visibility';
import Warning from '@material-ui/icons/Warning';

import { CSSProperties } from '@material-ui/styles';

export type GeneralIconsType = 'account' | 'watch' | 'observation' | 'update' | 'attend' | 'nextArrow' |
  'backArrow' | 'add' | 'brokenImage' | 'checkSnack' | 'errorSnack' | 'infoSnack' | 'warningSnack' |
  'closeSnack' | 'dragAndDrop' | 'lock' | 'micro' | 'edit' | 'addCircle' | 'save' | 'refresh' |
  'editPen' | 'download';

const imageIconsUrls = {
  // vegetables: 'assets/j-1.png',
};

// tslint:disable-next-line: max-line-length
const getIconBy = (name: GeneralIconsType, fontSize?: 'default' | 'inherit' | 'large' | 'small', color?: string, stylesIcon?: CSSProperties, className?: string) => {
  // tslint:disable-next-line: variable-name
  let MyComponent: any = null;
  switch (name) {
    case 'account':
      MyComponent = AccountBox;
      break;
    case 'download':
      MyComponent = GetApp;
      break;
    case 'observation':
      MyComponent = Visibility;
      break;
    case 'watch':
      MyComponent = Alarm;
      break;
    case 'lock':
      MyComponent = LockOpen;
      break;
    case 'update':
      MyComponent = Sync;
      break;
    case 'attend':
      MyComponent = EnhancedEncryption;
      break;
    case 'micro':
      MyComponent = Mic;
      break;
    case 'edit':
      MyComponent = ListAlt;
      break;
    case 'infoSnack':
      MyComponent = Info;
      break;
    case 'closeSnack':
      MyComponent = Close;
      break;
    case 'errorSnack':
      MyComponent = ErrorIcon;
      break;
    case 'addCircle':
      MyComponent = AddCircle;
      break;
    case 'warningSnack':
      MyComponent = Warning;
      break;
    case 'checkSnack':
      MyComponent = CheckCircle;
      break;
    case 'save':
      MyComponent = Save;
      break;
    case 'refresh':
      MyComponent = Refresh;
      break;
    case 'editPen':
      MyComponent = Edit;
      break;
    default:
      break;
  }

  if (!color) {
    color = undefined;
  }

  return <MyComponent fontSize={fontSize} style={stylesIcon} color={color} className={className} />;
};

export default {
  material_bg: 'assets/material_bg.png', // Using assets because needs use the transpiled image. See `CopyWebpackPlugin` in webpack.config file
  getIconBy,
  imageIconsUrls,
  bodyShape: 'assets/bodyShape.png',
  logo: 'assets/logo.png',
  goToAgenda: 'assets/goToAgenda.png',
  doctorProfile: 'assets/icons/doctorProfile.png',
  clinicNoteBook: 'assets/icons/clinicNoteBook.png',
  lockClose: 'assets/icons/lockClose.png',
  lockOpen: 'assets/icons/lockOpen.png',
  watchIcon: 'assets/icons/watchIcon.png',
  pdfFile: 'assets/icons/pdf_file.png',
  lookUpPlus: 'assets/icons/lookUpPlus.png',
  lookUpDoctor: 'assets/icons/lookUpDoctor.png',
  calendar: 'assets/icons/calendar.png',
  folder: 'assets/icons/folder.png',
  paymentIcon: 'assets/icons/paymentIcon.png',
  watchExams: 'assets/wathExams.png',
};
