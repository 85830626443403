// Libraries
import { Reducer } from 'redux';

// Constants
import { PhysicalExamActionTypes } from './PhysicalExamActions';

const initialState: IPhysicalExamReducerType = {
  lastAction: null,
  currentFinding: null,
  previousFindings: [],
  especializedExams: [],
  selectedEspecializedExams: null,
  variableVisualExams: null,
  variableRefractionExams: null,
  motility: [],
  colorTest: [],
  preChargedVariables: [],
  preChargedHistoricalVariables: [],
  specializedHistorical: [],
};

const reducer: Reducer<IPhysicalExamReducerType, IAction> = (state = initialState, action: IAction): IPhysicalExamReducerType => {
  switch (action.type) {
    case PhysicalExamActionTypes.SET_LAST_ACTION:
      return {
        ...state,
        lastAction: action.value,
      };
    case PhysicalExamActionTypes.SET_CURRENT_FINDING:
      return {
        ...state,
        currentFinding: action.value,
      };
    case PhysicalExamActionTypes.SET_PREVIUOS_FINDING:
      return {
        ...state,
        previousFindings: action.value,
      };
    case PhysicalExamActionTypes.SET_SPECIALIZED_MESSAGES:
      return {
        ...state,
        especializedExams: action.value,
      };
    case PhysicalExamActionTypes.SET_SELECTED_SPECIALIZED_MESSAGES:
      return {
        ...state,
        selectedEspecializedExams: action.value,
      };
    case PhysicalExamActionTypes.SET_VARIABLE_VISUAL_EXAM:
      return {
        ...state,
        variableVisualExams: action.value,
      };
    case PhysicalExamActionTypes.SET_VARIABLE_REFRACTION_EXAM:
      return {
        ...state,
        variableRefractionExams: action.value,
      };
    case PhysicalExamActionTypes.MOTILITY:
      return {
        ...state,
        motility: action.value,
      };
    case PhysicalExamActionTypes.COLOR_TEST:
      return {
        ...state,
        colorTest: action.value,
      };
    case PhysicalExamActionTypes.SET_PRE_CHARGED_VARIABLES:
      return {
        ...state,
        preChargedVariables: action.value,
      };
    case PhysicalExamActionTypes.SET_PRE_CHARGED_HISTORICAL_VARIABLES:
      return {
        ...state,
        preChargedHistoricalVariables: action.value,
      };
    case PhysicalExamActionTypes.SET_SPECIALIZED_HISTORICAL:
      return {
        ...state,
        specializedHistorical: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
