// Libraries
import React from 'react';
import { withRouter } from 'react-router';

// Material Styles
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';

// Scene
import { LayoutProps } from './LayoutScene';

// Components
import Header from '../../Components/Header/HeaderScene';
import Patient from '../App/Patient/PatientScene';

// Styles
import ThemeDefault from '../theme-default';
import LayoutStyles from './LayoutStyles';

class Layout extends React.Component<LayoutProps> {
  //#region LifeCyle
  constructor(props: LayoutProps) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    // this.props.validate_session();
  }
  // #endregion LifeCyle

  // #region Functions
  // #endregion Functions

  // #region Render Functions
  render() {
    const pathname = this.props.location.pathname;
    const infoSplitted = pathname.split('/');
    const params = {
      user_id: infoSplitted[2],
      tab: infoSplitted[3],
    };

    const classes = this.props.classes;
    const patient = this.props.patient.currentPatient;

    const isValidPatient = Boolean(patient && patient.attentionNumber);
    if (!isValidPatient) {
      // tslint:disable-next-line: no-console
      console.error('49 The attention is invalid >>>>>>>>> ');
    }

    return (
      <ThemeProvider theme={ThemeDefault}>
        <Header />
        <div className={this.props.app.isOpenDrawer ? classes.layoutContainer : classes.layoutContainerWithMargin}>
          <Patient urlParams={params} history={this.props.history} />
          {isValidPatient && (
            <Paper className={classes.paper}>
              {this.props.children}
            </Paper>
          )}
        </div>
      </ThemeProvider>
    );
  }
}
// #endregion Render Functions

const component = withStyles(LayoutStyles, { withTheme: true })(Layout);
export default withRouter(component);
