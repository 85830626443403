// Used via Metrics.baseMargin
const fonts = {
  fontSizesmall: 10,
  fontSizeBaseReport: 11,
  fontSizeMedium: 15,
  fontSizeIntermediate: 18,
  fontSizeLarge: 20,
  fontSizeXLarge: 30,
  fontSizeDouble: '2.1em',
  fontSizeAlmostDouble: '1.8em',
  fontSizeSlightlyBiggerImage: '1.4em',
  fontSizeImage: '1.3em',
  fontSizeSubtitle: '1.2em',
  fontSizeSmallSubtitle: '1.15em',
  fontSizeTinySubtitle: '1.1em',
  fontSizeNormal: '1em',
  fontSizeSmall: '0.9em',
  fontWeightBold: 700 as 700,
  fontWeightStandard: 500,
  fontWeightSmall: 400 as 400,
  boldSizeMedium: 300,
  fontSizeTree: 16,
};

export default fonts;
