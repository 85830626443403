// Libraries
import { Reducer } from 'redux';

// Constants
import { MedicalHistoryActionTypes } from './MedicalHistoryActions';

const initialState: IMedicalHistoryReducerType = {
  appointments: [],
  selectedAppointment: null,
  lastAction: null,
  currentPage: 0,
  rowsPerPage: 5,
  totalPatients: 0,
  filterValue: '%',
  currentDoctor: null,
  doctors: [],
  selectedReportType: null,
  reportData: null,
  // reportData: {
  //   attentionNumber: 139,
  //   laboratoryExams: [],
  //   patientInfo: {
  //     address: 'cr 34 # 45 -90                ',
  //     age: '29 año(s)  10 mes(es)  16 día(s)',
  //     agreementId: '6C701785-34AC-47ED-ACE2-D957894B183C',
  //     agreementName: 'Cita Particular',
  //     birthDate: '1991-01-01T04:43:00.000Z',
  //     companionName: 'Mario Solarte',
  //     companionPhone: '573004656417',
  //     companionRelationShip: 'Padre',
  //     companionRelationShipId: 1,
  //     documentKind: 'CE',
  //     documentId: '11134564343',
  //     documentName: 'Cédula Extranjeria',
  //     email: [
  //       'carlosan.dresgo.nzalezcalle@gm',
  //       'carlosan.dresgo.nzalezcalle@gm',
  //     ],
  //     entityId: '94F83DAA-9F1A-47C5-BC00-5EABCAF3148D',
  //     entityName: 'Coomeva1',
  //     gender: 'Masculino',
  //     genderId: 1,
  //     jobStatus: true,
  //     jobStatusName: 'Si',
  //     maritalStatus: 'Soltero',
  //     maritalStatusId: 1,
  //     occupation: 'Abogado\r\n',
  //     occupationId: 1,
  //     patientFirstName: 'Luciana ',
  //     patientId: 'E981BD9D-B62E-4195-A0FE-1B61FD2AAB40',
  //     patientLastName: 'Solarte ',
  //     patientName: 'Luciana  Solarte ',
  //     phone: '573004656417',
  //     residenceCity: 'Cali',
  //     residenceCityId: 'B06D2B6A-0338-4BCC-B0EA-AEDD43274424',
  //     responsableName: 'Carolina Maya',
  //     responsablePhone: '573004656417',
  //     responsableRelationShip: 'Padre',
  //     responsableRelationShipId: '1',
  //     scholarship: 'No Bachiller',
  //     scholarshipId: 1,
  //   },
  //   procedureOrder: [],
  //   queryOrder: [],
  //   doctorInfo: {
  //     doctorId: '371ED4BD-7449-45ED-ADDA-CDBAFE71B71D',
  //     doctorName: 'ALCIREMISACOSTA',
  //     doctorProfessionalCard: '121313212',
  //     doctorSpecialty: 'Dermatólogo',
  //   },
  //   managementPlanInfo: {
  //     managementPlanDate: '',
  //   },
  //   medicalDisability: {
  //     date: '',
  //     disabilityStartDate: '',
  //   },
  //   surgeryNote: {
  //     date: '',
  //   },
  //   nursingNote: {
  //     date: '',
  //   },
  //   consultationReason: {
  //     consultationReasonDate: '',
  //     consultationReason: `Lorem Ipsum is simply dummy`,
  //   },
  //   backgrounds: [],
  //   medicines: [
  //     {
  //       administrationMethod: 'Ocular ',
  //       attentionMedicineDate: '2020/10/17 15:41:06',
  //       attentionNumber: 139,
  //       consecutive: 73,
  //       dose: '1 gota',
  //       frecuency: 'Cada 6 horas ',
  //       measure: 'Fco',
  //       medicalMonitoring: false,
  //       medicineId: 1308,
  //       medicineName: 'FLUOROMETALONA SOLUCION OFTALMICA  0.1 %/5 ML',
  //       observations: 'Ambos ojos',
  //       quantity: 1,
  //       status: 'A',
  //       treatmentDuration: '30 días ',
  //       userId: 'E39D1000-D20D-4F18-910E-61271CC24E65',
  //     },
  //   ],
  // },
  appointmentSearchType: 'today',
  searchPatientTerm: null,
  dropDownPatients: [],
  selectedDropDownPatient: null,
  historicAppointments: [],
  paymentMethods: [],
};

const reducer: Reducer<IMedicalHistoryReducerType, IAction> = (state = initialState, action: IAction): IMedicalHistoryReducerType => {
  switch (action.type) {
    case MedicalHistoryActionTypes.SET_APPOINTMENTS_DATA:
      return {
        ...state,
        appointments: action.value.data,
        totalPatients: action.value.count,
      };
    case MedicalHistoryActionTypes.SET_SELECTED_APPOINTMENT:
      return {
        ...state,
        selectedAppointment: action.value,
      };
    case MedicalHistoryActionTypes.SET_FILTER_VALUE:
      return {
        ...state,
        filterValue: action.value,
      };
    case MedicalHistoryActionTypes.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.value,
      };
    case MedicalHistoryActionTypes.SET_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.value,
      };
    case MedicalHistoryActionTypes.SET_CURRENT_DOCTOR:
      return {
        ...state,
        currentDoctor: action.value,
      };
    case MedicalHistoryActionTypes.SET_DOCTORS_DATA:
      return {
        ...state,
        doctors: action.value,
      };
    case MedicalHistoryActionTypes.SET_REPORT_DATA:
      return {
        ...state,
        reportData: action.value,
      };
    case MedicalHistoryActionTypes.SET_APPOINTMENT_SEARCH_TYPE:
      return {
        ...state,
        appointmentSearchType: action.value.search_type,
        currentPage: 0,
        totalPatients: action.value.count,
      };
    case MedicalHistoryActionTypes.SET_SEARCH_PATIENT_TERM:
      return {
        ...state,
        searchPatientTerm: action.value,
      };
    case MedicalHistoryActionTypes.SET_DROPDOWN_PATIENTS:
      return {
        ...state,
        dropDownPatients: action.value,
      };
    case MedicalHistoryActionTypes.SET_SELECTED_DROPDOWN_PATIENT:
      return {
        ...state,
        selectedDropDownPatient: action.value,
      };
    case MedicalHistoryActionTypes.SET_HISTORIC_APPOINTMENTS:
      return {
        ...state,
        historicAppointments: action.value.data,
        totalPatients: action.value.count,
      };
    case MedicalHistoryActionTypes.SET_PAYMENTS_METHODS:
      return {
        ...state,
        paymentMethods: action.value,
      };
    case MedicalHistoryActionTypes.SET_SELECTED_REPORT_TYPE:
      return {
        ...state,
        selectedReportType: action.value,
      };
    case MedicalHistoryActionTypes.SET_LAST_ACTION:
      return {
        ...state,
        lastAction: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
