// Libraries
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import history from './History';

// Modules.
import LayoutContainer from '../Modules/App/LayoutContainer/LayoutContainerScene';
import {
  historyListComponent,
  updateUserDataComponent,
} from '../Modules/App/LayoutContainer/LoadableModules';
import Layout from '../Modules/Layout/LayoutScene';

// import DynamicLookup from '../Components/DynamicLookup';

const routes = () => (
  <Switch>
    {/* <Route path='/login' component={loginComponent} /> */}
    <Route exact path='/' component={historyListComponent} />
    <Route exact path='/bearer/:token' component={historyListComponent} />
    <Route exact path='/update_user/:user_id/' component={updateUserDataComponent} />
    {/* <Route exact path='/test' component={physicalExamComponent} /> */}
    <Layout>
      <Route exact path='/patient/:user_id/' component={LayoutContainer} />
      <Route exact path='/patient/:user_id/:tab/' component={LayoutContainer} />
    </Layout>
  </Switch>
);

export {
  routes,
  history,
};
