import { to } from 'await-to-js';
import { batch } from 'react-redux';

// Utils
import { dispatchShowDialog, dispatchShowSpinner } from '../App/AppUtils';
import { validateErrorFromClientRequest } from '../ReducersUtils';

// Service
import executeRequest from '../../Services/ServiceBase';

export enum ConsultationReasonActionTypes {
  SET_LAST_ACTION = 'consultationReason/SET_LAST_ACTION',
  SET_CONSULTAION_REASON = 'consultationReason/SET_CONSULTAION_REASON',
}

export const set_last_action = (value: ConsultationReasonActionTypes): IAction => ({ type: ConsultationReasonActionTypes.SET_LAST_ACTION, value });
export const set_consultation_reason = (value: IConsultationReason): IAction => ({
  type: ConsultationReasonActionTypes.SET_CONSULTAION_REASON,
  value,
});

export const get_consultation_reason = (): IThunkResult => {
  return async (dispatch, getState) => {
    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    dispatchShowSpinner(dispatch, true);

    const currentPatient = getState().patient.currentPatient;

    const params = {
      attention_number: currentPatient.attentionNumber,
      user_name: loggedUser.firstName,
    };

    const [error, consultationReason] = await to<IConsultationReason>(executeRequest(`consultation_reason`, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, consultationReason);
    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(set_consultation_reason(consultationReason));
    });
  };
};

export const save_consultation_reason = (): IThunkResult => {
  return async (dispatch, getState) => {
    if (getState().patient.currentPatient.attentionStatus === 'C') {
      return dispatchShowDialog(dispatch, true, 'Válido solo para citas abiertas', 'warning');
    }

    const consultationReasonData = getState().consultationReason.data;
    if (!consultationReasonData.consultationReason) {
      const message = `Por favor escriba el motivo de la consulta`;
      dispatchShowDialog(dispatch, true, message, 'warning');
      return;
    }

    dispatchShowSpinner(dispatch, true);

    const loggedUser = getState().user.data;
    const currentDoctor = getState().medicalHistory.currentDoctor;
    const currentPatient = getState().patient.currentPatient;

    const params = {
      attention_number: currentPatient.attentionNumber,
      consultation_reason: consultationReasonData.consultationReason,
      doctor_id: currentDoctor.value,
      user_name: loggedUser.firstName,
    };

    const [error, cosultationReasonResponse] = await to<string>(executeRequest(`create_consultation_reason`, params, 'post'));
    const errorParsed = validateErrorFromClientRequest(error, cosultationReasonResponse);
    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    dispatchShowDialog(dispatch, true, 'Motivo de consulta guardado existosamente', 'success');

    batch(() => {
      dispatchShowSpinner(dispatch, false);
    });
  };
};
