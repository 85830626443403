import { applyMiddleware, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import createEncryptor from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';


import thunk from 'redux-thunk';

import rootReducer from './combineReducers';

const configureStore = (initialState?: IRootState) => {
  const enhancer = composeWithDevTools(
    applyMiddleware(thunk),
  );

  const encryptor = createEncryptor({
    secretKey: process.env.ENCRYPTOR_SECRET_KEY,
    onError: (error) => {
      // tslint:disable-next-line: no-console
      console.error('createEncryptor onError ==> ', error);
    },
  });

  const persistConfig: any = {
    key: 'root',
    storage,
    whitelist: ['admin', 'user'],
    transforms: [encryptor],
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = createStore<IRootState, IAction, {}, {}>(persistedReducer, initialState, enhancer);
  // @ts-ignore
  if (module.hot) {
    // @ts-ignore
    module.hot.accept('./combineReducers', () => {
      const nextReducer = rootReducer;
      store.replaceReducer(nextReducer);
    });
  }

  const persistor = persistStore(store);

  return { persistor, store };
};

export default configureStore;
