import React from 'react';
import Loadable from 'react-loadable';

// Modules
export const anthropometricMeasure = Loadable({
  loader: () => import('../../../Components/AnthropometricMeasure/AnthropometricMeasureScene'),
  loading() {
    return <div></div>;
  },
});

export const backgroundComponent = Loadable({
  loader: () => import('../../../Components/Background/BackgroundScene'),
  loading() {
    return <div></div>;
  },
});

export const consultationReason = Loadable({
  loader: () => import('../../../Components/ConsultationReason/ConsultationReasonScene'),
  loading() {
    return <div></div>;
  },
});

export const historyListComponent = Loadable({
  loader: () => import('../MedicalHistory/MedicalHistoryScene'),
  loading() {
    return <div></div>;
  },
});

export const loginComponent = Loadable({
  loader: () => import('../Login/LoginScene'),
  loading() {
    return <div></div>;
  },
});

export const medicalDisability = Loadable({
  loader: () => import('../../../Components/MedicalDisability/MedicalDisabilityScene'),
  loading() {
    return <div></div>;
  },
});

export const patientComponent = Loadable({
  loader: () => import('../Patient/PatientScene'),
  loading() {
    return <div></div>;
  },
});

export const physicalExamComponent = Loadable({
  loader: () => import('../../../Components/PhysicalExam/PhysicalExamScene'),
  loading() {
    return <div></div>;
  },
});

export const systemReviewComponent = Loadable({
  loader: () => import('../../../Components/SystemReview/SystemReviewScene'),
  loading() {
    return <div></div>;
  },
});

export const diagnosticComponent = Loadable({
  loader: () => import('../../../Components/Diagnostic/DiagnosticScene'),
  loading() {
    return <div></div>;
  },
});

export const vitalSignsComponent = Loadable({
  loader: () => import('../../../Components/VitalSigns/VitalSignsScene'),
  loading() {
    return <div></div>;
  },
});

export const managementPlanComponent = Loadable({
  loader: () => import('../../../Components/ManagementPlan/ManagementPlanScene'),
  loading() {
    return <div></div>;
  },
});

export const evolutionNoteComponent = Loadable({
  loader: () => import('../../../Components/EvolutionNote/EvolutionNoteScene'),
  loading() {
    return <div></div>;
  },
});

export const drugFormulationComponent = Loadable({
  loader: () => import('../../../Components/DrugFormulation/DrugFormulationScene'),
  loading() {
    return <div></div>;
  },
});

export const cupComponent = Loadable({
  loader: () => import('../../../Components/Cup/CupScene'),
  loading() {
    return <div></div>;
  },
});

export const examsRevAndDiagTestComponent = Loadable({
  loader: () => import('../../../Components/ExamsRevAndDiagTest/ExamsRevAndDiagTestScene'),
  loading() {
    return <div></div>;
  },
});

export const surgeryNotesComponent = Loadable({
  loader: () => import('../../../Components/SurgeryNotes/SurgeryNotesScene'),
  loading() {
    return <div></div>;
  },
});

export const nursingNotesComponent = Loadable({
  loader: () => import('../../../Components/NursingNotes/NursingNotesScene'),
  loading() {
    return <div></div>;
  },
});

export const patientPersonalDataComponent = Loadable({
  loader: () => import('../../../Components/PatientPersonalData/PatientPersonaDataScene'),
  loading() {
    return <div></div>;
  },
});

export const aclarationNotesComponent = Loadable({
  loader: () => import('../../../Components/AclarationNotes/AclarationNotesScene'),
  loading() {
    return <div></div>;
  },
});

export const specializedExamsComponent = Loadable({
  loader: () => import('../../../Components/SpecializedExams/SpecializedExamsScene'),
  loading() {
    return <div></div>;
  },
});

export const motilityComponent = Loadable({
  loader: () => import('../../../Components/SpecializedExams/Components/Motility/MotilityScene'),
  loading() {
    return <div></div>;
  },
});

export const colorTestComponent = Loadable({
  loader: () => import('../../../Components/SpecializedExams/Components/ColorTest/ColorTestScene'),
  loading() {
    return <div></div>;
  },
});

export const acuityComponent = Loadable({
  loader: () => import('../../../Components/SpecializedExams/Components/Acuity/AcuityScene'),
  loading() {
    return <div></div>;
  },
});

export const refractionComponent = Loadable({
  loader: () => import('../../../Components/SpecializedExams/Components/Refraction/RefractionScene'),
  loading() {
    return <div></div>;
  },
});

export const updateUserDataComponent = Loadable({
  loader: () => import('../UpdateUserData/UpdateUserDataScene'),
  loading() {
    return <div></div>;
  },
});
