// Libraries
import { Reducer } from 'redux';

// Constants
import { NotesActionTypes } from './NotesActions';

const initialState: INotesReducerType = {
  lastAction: null,
  data: {},
};

const reducer: Reducer<INotesReducerType, IAction> = (state = initialState, action: IAction): INotesReducerType => {
  switch (action.type) {
    case NotesActionTypes.SET_LAST_ACTION:
      return {
        ...state,
        lastAction: action.value,
      };
    case NotesActionTypes.SET_NOTE:
      return {
        ...state,
        data: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
