// Used via Metrics.baseMargin
const metrics = {
  largeXXLMarginOrPaddingBase: 50,
  largeXLMarginOrPaddingBase: 40,
  doubleMarginOrPaddingBase: 30,
  largeMarginOrPaddingBase: 20,
  marginOrPaddingBase: 15,
  smallMarginOrPaddingBase: 10,
  smallXSMarginOrPaddingBase: 5,
  borderRadius: 4,
  icons: {
    tiny: 15,
    small: 20,
    medium: 30,
    large: 45,
    xl: 50,
  },
  images: {
    small: 20,
    medium: 40,
    large: 60,
    xl: 150,
    logo: 200,
  },
  buttonHeight: 60,
  buttonBarWidth: 80,
  minHeighTreeTable: 435,
};

export default metrics;
