// Modules
import { map } from 'lodash';
import React from 'react';

// MaterialCore
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

// MaterialIcons
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

// Images
import Images, { GeneralIconsType } from '../../../Images';

// Styles
import styles from './DrawerChildItemStyle';
import materialStyles from './MaterialStyle';

// Utils
import Color from '../../../Theme/Colors';
import { objectNotEquals } from '../../../Utils';

class DrawerChildItem extends React.Component<IDrawerChildItemProps> {

  //#region LifeCycle
  constructor(props: IDrawerChildItemProps) {
    super(props);
  }

  shouldComponentUpdate(nextProps: IDrawerChildItemProps) {
    let shouldRender: boolean = false;

    if (objectNotEquals(this.props.drawerItems, nextProps.drawerItems)) {
      shouldRender = true;
    }
    if (this.props.isOpenDrawer !== nextProps.isOpenDrawer) {
      shouldRender = true;
    }

    return shouldRender;
  }
  //#endregion LifeCycle

  //#region Render
  renderDisplayArrow(drawerItemChild: IDrawerItem) {
    if (drawerItemChild.drawerItems) {
      if (drawerItemChild.isExpanded) {
        return (<ExpandLess />);
      }

      return (<ExpandMore />);
    }

    return null;
  }

  renderLabel(drawerItemChild: IDrawerItem) {
    const { classes, isOpenDrawer } = this.props;

    if (!isOpenDrawer) {
      return null;
    }

    return (
      <ListItemText
        className={classes.drawerLabel}
        // disableTypography={true}
        primary={drawerItemChild.label}
        style={{ paddingLeft: 12 }}
        primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
      />
    );
  }

  renderIcon(icon: GeneralIconsType) {
    return Images.getIconBy(icon, 'small');
  }

  renderItem(drawerItemChild: IDrawerItem, itemLevel: number) {
    const { classes, isOpenDrawer } = this.props;
    const newPaddingHorinzontal = !isOpenDrawer && 0;
    const marginLeft = (itemLevel > 0 && isOpenDrawer) ? (itemLevel * 10) : 0;

    return (
      <ListItem
        button
        onClick={() => { this.props.handleClick(drawerItemChild, itemLevel); }}
        className={classes.listItemText}
        style={{
          color: drawerItemChild.isExpanded && Color.drawerListItemExpandedTextColor,
          paddingLeft: newPaddingHorinzontal,
          paddingRight: newPaddingHorinzontal,
        }}
      >
        <div style={{ ...styles.listItemContainer, marginLeft, justifyContent: !isOpenDrawer ? 'center' : 'flex-start' }}>
          {this.renderIcon(drawerItemChild.icon)}
          {this.renderLabel(drawerItemChild)}
          {this.renderDisplayArrow(drawerItemChild)}
        </div>
      </ListItem >
    );
  }

  renderListItem(drawerItemChild: IDrawerItem, itemLevel: number) {
    if (this.props.isOpenDrawer) {
      return this.renderItem(drawerItemChild, itemLevel);
    }

    return (
      <Tooltip
        title={drawerItemChild.label}
        placement='right'
        PopperProps={{ style: { pointerEvents: 'none', left: 70 } }}
        TransitionComponent={Zoom}
      >
        {this.renderItem(drawerItemChild, itemLevel)}
      </Tooltip>
    );
  }

  renderChildDrawer(drawerItems: IDrawerItem[], isExpanded?: boolean, level: number = -1): any {
    if (!drawerItems || drawerItems.length === 0) {
      return null;
    }

    const classes = this.props.classes;
    const itemLevel = level === -1 ? 2 : level + 1;

    return map(drawerItems, (drawerItemChild: IDrawerItem, index: number) => {
      return (
        <div key={`${drawerItemChild.label}${index}`}>
          <Collapse in={isExpanded} timeout={'auto'} unmountOnExit>
            <List id={`config${index}`} component={'div'} disablePadding>
              <div className={classes.drawerItemHover}>
                {this.renderListItem(drawerItemChild, itemLevel)}
              </div>
              {this.renderChildDrawer(drawerItemChild.drawerItems, drawerItemChild.isExpanded, itemLevel)}
            </List>
          </Collapse>
        </div>
      );
    });
  }

  render() {
    const drawerItems = this.props.drawerItems;

    return this.renderChildDrawer(drawerItems, true, 0);
  }
  //#endregion Render
}

export default withStyles(materialStyles, { withTheme: true })(DrawerChildItem);
