import { batch } from 'react-redux';

export enum AppActionTypes {
  SHOW_DIALOG = 'app/SHOW_DIALOG',
  SHOW_SPINNER = 'app/SHOW_SPINNER',
  SET_OPEN_DRAWER = 'app/SET_OPEN_DRAWER',
  SET_LOADING_DROPDOWN = 'app/SET_LOADING_DROPDOWN',
  SET_LOADING_TABLE = 'app/SET_LOADING_TABLE',
  SET_LOADING_DRAWER = 'app/SET_LOADING_DRAWER',
  SET_LOADING_MODAL = 'app/SET_LOADING_MODAL',
  SHOW_BACK = 'app/SHOW_BACK',
  LAST_ACTION = 'app/LAST_ACTION',
}

export const set_data_dialog = (data?: IObjectDialog): IAction => ({ type: AppActionTypes.SHOW_DIALOG, value: data });

export const set_show_dialog = (data?: IDialogWindowTypes): IThunkResult => {
  return async (dispatch, getState) => {
    const value: IDialogWindowTypes = data || { show: false, type: null, title: null, message: null };
    const currentDialogs = getState().app.dialogWindow;

    const statusCode = value.statusCode;
    const invalidSessionCases = statusCode && (statusCode === 401 || statusCode === 402 || statusCode === 403);

    if (invalidSessionCases) {
      value.action = 'invalid_session';
      batch(() => {
        dispatch(show_spinner({ type: 'none' }));
        dispatch(set_loading_table(false));
      });
      return;
    }

    const snackBar = value.type && value.type !== 'form' && value.type !== 'dialog';

    const copyDialogs = Object.assign({}, currentDialogs);
    const setData = !value.show ? null : value;

    if (snackBar) {
      copyDialogs.snackbar = setData;
    } else {
      copyDialogs.dialogForm = setData;
      if (copyDialogs.snackbar) {
        copyDialogs.snackbar = null;
      }
    }

    return dispatch(set_data_dialog(copyDialogs));
  };
};

export const show_spinner = (value: ISpinnerType): IAction => ({ type: AppActionTypes.SHOW_SPINNER, value });
export const set_open_drawer = (value: boolean): IAction => ({ type: AppActionTypes.SET_OPEN_DRAWER, value });
export const set_loading_dropdown = (value: boolean): IAction => ({ type: AppActionTypes.SET_LOADING_DROPDOWN, value });
export const set_loading_table = (value: boolean): IAction => ({ type: AppActionTypes.SET_LOADING_TABLE, value });
export const set_loading_drawer = (value: boolean): IAction => ({ type: AppActionTypes.SET_LOADING_DRAWER, value });
export const set_loading_modal = (value: boolean): IAction => ({ type: AppActionTypes.SET_LOADING_MODAL, value });
export const show_back = (value: boolean): IAction => ({ type: AppActionTypes.SHOW_BACK, value });
export const set_last_action = (value: string): IAction => ({ type: AppActionTypes.LAST_ACTION, value });
