import { map } from 'lodash';

type DiagnosticOptionsFunc = (
  ciesData: IDiagnosticCieResponse[],
  doctorLookUpDefault: boolean,
  dispatch: IThunkDispatch,
  set_diagnostic_cies: (value: IOptionsData[]) => IAction,
) => any;

export const setDiagnosticOptions: DiagnosticOptionsFunc = (ciesData, doctorLookUpDefault, dispatch, set_diagnostic_cies) => {
  const diagnosticOptions: IOptionsData[] = [{ label: 'Seleccione una medida', value: 'default' }];

  map(ciesData, (cie) => {
    if (!doctorLookUpDefault || (doctorLookUpDefault && cie.preConfiguredByDoctor)) {
      diagnosticOptions.push({
        value: cie.cieTechnicalCode,
        label: `${cie.cieTechnicalCode} - ${cie.cieTechnicalName}`,
      });
    }
  });

  dispatch(set_diagnostic_cies(diagnosticOptions));
};
