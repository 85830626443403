import { to } from 'await-to-js';
import { batch } from 'react-redux';

// Utils
import { copyObject } from '../../Utils';
import { dispatchShowDialog, dispatchShowSpinner } from '../App/AppUtils';
import { validateErrorFromClientRequest } from '../ReducersUtils';

// Service
import executeRequest from '../../Services/ServiceBase';

export enum BackgroundActionTypes {
  ACTIVE_MICRO = 'patient/ACTIVE_MICRO',
  SET_BACKGROUND_GROUPS = 'patient/SET_BACKGROUND_GROUPS',
  SET_CURRENT_FINDING = 'patient/SET_CURRENT_FINDING',
  SET_LAST_ACTION = 'patient/SET_LAST_ACTION',
  SET_PATIENT_SECTIONS = 'patient/SET_PATIENT_SECTIONS',
  SET_PREVIOUS_USER_BACKGROUND = 'patient/SET_PREVIOUS_USER_BACKGROUND',
  SET_PREVIUOS_FINDING = 'patient/SET_PREVIUOS_FINDING',
  SET_PREVIUOS_TYPE = 'patient/SET_PREVIUOS_TYPE',
  SET_HISTORICAL_BACKGROUND = 'patient/SET_HISTORICAL_BACKGROUND',
}

export const set_background_groups = (value: IBackgroundGroups[]): IAction => ({ type: BackgroundActionTypes.SET_BACKGROUND_GROUPS, value });
export const set_last_action = (value: BackgroundActionTypes): IAction => ({ type: BackgroundActionTypes.SET_LAST_ACTION, value });
export const set_previous_user_background = (value: ITabsQuantity): IAction => ({ type: BackgroundActionTypes.SET_PREVIOUS_USER_BACKGROUND, value });
export const set_historical_background = (value: IHistoricalBackGround[]): IAction => (
  { type: BackgroundActionTypes.SET_HISTORICAL_BACKGROUND, value });

export const get_background_by_group = (tabSelected: number, skip?: boolean): IThunkResult => {
  return async (dispatch, getState) => {
    const backgroundGroupId = getState().background.backgroundGroups[tabSelected].groupBackgroundId;
    const currentPatient = getState().patient.currentPatient;

    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    let previousUserBackground = copyObject(getState().background.userBackgrounds);
    const previousUserBackgroundInTab = previousUserBackground[tabSelected];

    if (previousUserBackgroundInTab && previousUserBackgroundInTab.length && !skip) {
      return;
    }

    dispatchShowSpinner(dispatch, true);

    const params = {
      group_background_id: backgroundGroupId,
      patient_id: currentPatient.patientUserId,
      user_name: loggedUser.firstName,
    };

    const [error, backgroundByGroupsResponse] = await to<IBackgroundData[]>(executeRequest(`attention_background_by_group`, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, backgroundByGroupsResponse);
    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    previousUserBackground = {
      ...previousUserBackground,
      [tabSelected]: backgroundByGroupsResponse,
    };

    batch(() => {
      dispatch(set_previous_user_background(previousUserBackground));
      dispatchShowSpinner(dispatch, false);
    });
  };
};

export const get_background_groups_by_doctor = (): IThunkResult => {
  return async (dispatch, getState) => {
    dispatchShowSpinner(dispatch, true);

    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    const params = {
      user_name: loggedUser.firstName,
    };

    const [error, backgroundGroupsResponse] = await to<IBackgroundGroups[]>(executeRequest(`background_groups`, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, backgroundGroupsResponse);
    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(set_background_groups(backgroundGroupsResponse));
      dispatch(get_background_by_group(0));
    });
  };
};

export const save_background = (background: IBackgroundData, tabSelected: number): IThunkResult => {
  return async (dispatch, getState) => {

    if (getState().patient.currentPatient.attentionStatus === 'C') {
      return dispatchShowDialog(dispatch, true, 'Válido solo para citas abiertas', 'warning');
    }

    if (!allInfoIsValid(dispatch, background)) {
      return;
    }

    const currentPatient = getState().patient.currentPatient;
    if (background.attentionNumber !== null && background.attentionNumber !== currentPatient.attentionNumber) {
      return dispatchShowDialog(dispatch, true, `Antecedentes anteriores a esta atención no pueden ser editados`, 'warning');
    }

    const params: IObj = {
      background_id: background.backgroundId,
      consecutive: background.consecutive,
      days: parseInt(background.days as any, 10),
      is_background_Family: background.isBackgroundFamily,
      is_background_personal: background.isBackgroundPersonal,
      months: parseInt(background.months as any, 10),
      observation: background.observation,
      years: parseInt(background.years as any, 10),
    };

    const loggedUser = getState().user.data;
    const currentDoctor = getState().medicalHistory.currentDoctor;

    // Default Values
    params.attention_number = currentPatient.attentionNumber;
    params.doctor_id = currentDoctor.value;
    params.user_name = loggedUser.firstName;

    dispatchShowSpinner(dispatch, true);

    const [error, createBackgroundResponse] = await to(executeRequest(`create_attention_background`, params, 'post'));
    const errorParsed = validateErrorFromClientRequest(error, createBackgroundResponse);
    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    dispatchShowDialog(dispatch, true, 'Registro guardado existosamente', 'success');
    dispatch(get_background_by_group(tabSelected, !background.consecutive));
  };
};

const allInfoIsValid = (dispatch: IThunkDispatch, background: IBackgroundData): boolean => {
  const msgPrefix = 'Por favor digite';

  if (!background) {
    dispatchShowDialog(dispatch, true, `${msgPrefix} toda la información`, 'warning');
    return false;
  }
  if (background.years > 120) {
    dispatchShowDialog(dispatch, true, `No puede ingresar un año mayor a 120`, 'warning');
    return false;
  }
  if (!background.isBackgroundPersonal && !background.isBackgroundFamily) {
    dispatchShowDialog(dispatch, true, `Por favor seleccione el tipo de antecedente, personal o familiar`, 'warning');
    return false;
  }

  return true;
};

export const get_historical_background = (): IThunkResult => {
  return async (dispatch, getState) => {
    dispatchShowSpinner(dispatch, true);

    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    const currentPatient = getState().patient.currentPatient;

    const params = {
      user_name: loggedUser.firstName,
      patient_id: currentPatient.patientUserId,
    };

    const [error, response] = await to<IHistoricalBackGround[]>(executeRequest(`attention_backgrounds_transverse`, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, response);
    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    batch(() => {
      dispatch(set_historical_background(response));
      dispatchShowSpinner(dispatch, false);
    });
  };
};
