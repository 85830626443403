// Libraries
import moment from 'moment';
import React from 'react';
import * as ReactDOM from 'react-dom';
import ReactNotification from 'react-notifications-component';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

// Persist
import { PersistGate } from 'redux-persist/integration/react';

// Routes
import history from './Routes/History';

// App styles
import 'font-awesome/css/font-awesome.css';
import 'react-notifications-component/dist/theme.css';
import './styles.scss';

// Main Component
import Container from './Container/ContainerScene';

// Redux
import configureStore from './Redux/setupStore';
const config = configureStore();

moment.locale('es');

ReactDOM.render(
  <Provider store={config.store as any}>
    <PersistGate loading={null} persistor={config.persistor as any}>
      <Router history={history}>
        <ReactNotification />
        <Container />
      </Router>
    </PersistGate>
  </Provider>, document.getElementById('root'),
);
