// Libraries
import React from 'react';

// Modules
import {
  aclarationNotesComponent,
  anthropometricMeasure,
  backgroundComponent,
  consultationReason,
  cupComponent,
  diagnosticComponent,
  drugFormulationComponent,
  evolutionNoteComponent,
  examsRevAndDiagTestComponent,
  // historyListComponent,
  managementPlanComponent,
  medicalDisability,
  // patientComponent,
  nursingNotesComponent,
  patientPersonalDataComponent,
  physicalExamComponent,
  specializedExamsComponent,
  surgeryNotesComponent,
  systemReviewComponent,
  vitalSignsComponent,
} from './LoadableModules';

// Utils
// import { createCookie } from '../../../Utils/cookies';

// Interfaces
import { LayoutContainerProps } from './LayoutContainerScene';

// Constants

class LayoutContainer extends React.Component<LayoutContainerProps> {
  private previousRoute: string = null;

  //#region LifeCycle
  constructor(props: LayoutContainerProps) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    this.screenTracking();
  }
  //#endregion LifeCycle

  //#region Functions
  screenTracking() {
    this.props.history.listen((location) => {
      const currentPath = location.pathname;

      if (this.previousRoute !== currentPath) {
        this.previousRoute = location.pathname;
      }
    });
  }
  //#endregion Functions

  //#region Render Functions
  render() {
    // if (!this.props.user.sessionIsValid) {
    //   return (
    //     <div />
    //   );
    // }

    // tslint:disable-next-line variable-name
    let Component: any = null;
    switch (this.props.match.params.tab) {
      case 'atencedentesGenerales':
        Component = backgroundComponent;
        break;
      case 'datosPersonales':
        Component = patientPersonalDataComponent;
        break;
      case 'diagnostico':
        Component = diagnosticComponent;
        break;
      case 'examenFisico':
        Component = physicalExamComponent;
        break;
      case 'specializedExams':
        Component = specializedExamsComponent;
        break;
      case 'formulacionExamenesConsultas':
        Component = cupComponent;
        break;
      case 'formulacionMedicamentos':
        Component = drugFormulationComponent;
        break;
      case 'formulacionIncapacidad':
        Component = medicalDisability;
        break;
      case 'medidaAntropometrica':
        Component = anthropometricMeasure;
        break;
      case 'motivoConsulta':
        Component = consultationReason;
        break;
      case 'notaAclaratoria':
        Component = aclarationNotesComponent;
        break;
      case 'notaEnfermeria':
        Component = nursingNotesComponent;
        break;
      case 'notaEvolucion':
        Component = evolutionNoteComponent;
        break;
      case 'notaQuirurgica':
        Component = surgeryNotesComponent;
        break;
      case 'planManejo':
        Component = managementPlanComponent;
        break;
      case 'revExamDiagTest':
        Component = examsRevAndDiagTestComponent;
        break;
      case 'revisionSistema':
        Component = systemReviewComponent;
        break;
      case 'signosVitales':
        Component = vitalSignsComponent;
        break;
      default:
        return <div>No hay Información</div>;
    }

    return (
      <Component history={this.props.history} urlParams={this.props.match.params} />
    );
  }
  //#endregion Render Functions
}

export default LayoutContainer;
