// Libraries
import moment from 'moment';
import React from 'react';
import Timer from 'react-compound-timer';

// Components

// Material Core
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Zoom from '@material-ui/core/Zoom';

// Scene
import { PatientProps } from './PatientScene';

// Styles
import Images from '../../../Images';
import styles from './PatientStyles';

// Constants
import { PatientActionTypes } from '../../../Reducers/Patient/PatientActions';

interface IState {
  [key: string]: any;
}

class Patient extends React.Component<PatientProps, IState> {

  //#region LifeCycle
  constructor(props: PatientProps) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    this.makeRequest();
  }

  componentDidUpdate() {
    if (this.props.patient.lastAction === PatientActionTypes.SET_CURRENT_PATIENT) {
      this.props.set_last_action(null);

      const currentPatient = this.props.patient.currentPatient;
      if (currentPatient.appointmentStateBeauty === 'En Espera') {
        this.props.create_new_atention();
      }
    }

    if (this.props.patient.lastAction === PatientActionTypes.ATTENTION_CLOSED) {
      this.props.set_last_action(null);
      this.props.history.replace('/');
      this.props.set_show_dialog({ show: true, type: 'success', message: 'Atención Cerrada Existosamente' });
    }
  }
  //#endregion LifeCycle

  //#region Functions
  makeRequest() {
    let appointmentDate: string = null;
    let doctorId: string = null;

    const recordId = this.props.urlParams && this.props.urlParams.user_id;
    if (!recordId) {
      return;
    }

    const currentPatient = this.props.patient.currentPatient;

    if (currentPatient) {
      appointmentDate = currentPatient.appointmentDate;
    }

    const userData = this.props.user.data;
    if (userData) {
      doctorId = userData.id;
    }

    this.props.get_patient(recordId, appointmentDate, doctorId);
    this.props.get_sections();
  }

  isFirstTime() {
    const currentPatient = this.props.patient.currentPatient;
    return currentPatient ? Boolean(currentPatient.firstTime) : true;
  }

  //#endregion Functions

  //#region Actions
  //#endregion Actions

  //#region Render Functions
  firstColumn() {
    const classes = this.props.classes;

    return (
      <React.Fragment>
        <Grid container item xs={12}>
          <label className={classes.pageTitle}>{`Atención Paciente`}</label>
        </Grid>
        {this.buildConsultationType()}
      </React.Fragment>
    );
  }

  buildColumnSection(title: string, value: string, customStyle?: React.CSSProperties) {
    const classes = this.props.classes;

    return (
      <div className={classes.blockInfo} style={customStyle}>
        <div className={classes.blockLabel}>{title}</div>
        <div className={classes.value}>{value}</div>
      </div>
    );
  }

  secondColumn() {
    const currentPatient = this.props.patient.currentPatient;
    const currentDoctor = this.props.medicalHistory.currentDoctor;
    if (!currentPatient) {
      return null;
    }

    return (
      <React.Fragment>
        <Grid item xs={12}>
          {this.buildColumnSection('Paciente:', currentPatient.patientName)}
          {this.buildColumnSection('Doctor:', currentDoctor.label)}
        </Grid>
      </React.Fragment>
    );
  }

  thirdColumn() {
    const currentPatient = this.props.patient.currentPatient;

    if (!currentPatient) {
      return null;
    }

    return (
      <React.Fragment>
        <Grid item xs={12}>
          {this.buildColumnSection('Edad:', currentPatient.age)}
          {this.buildColumnSection('Documento:', currentPatient.documentId)}
        </Grid>
      </React.Fragment>
    );
  }

  fourthColumn() {
    const currentPatient = this.props.patient.currentPatient;

    if (!currentPatient) {
      return null;
    }

    const initDate = moment().format('DD/MMMM/YYYY HH:mm');

    return (
      <React.Fragment>
        <Grid item xs={12}>
          {this.buildColumnSection('Inicia', initDate)}
          {this.buildColumnSection('Fin', 'No definido')}
          {this.buildTimerComp()}
        </Grid>
      </React.Fragment>
    );
  }

  fifthColumn() {
    const classes = this.props.classes;
    const currentPatient = this.props.patient.currentPatient;
    if (!currentPatient) {
      return null;
    }

    const attentionIsOpen = currentPatient.attentionStatus === 'A';
    const imageState = attentionIsOpen ? Images.lockOpen : Images.lockClose;
    const tooltipState = attentionIsOpen ? 'Abierta' : 'Cerrada';

    return (
      <React.Fragment>
        <Grid container item xs={12} direction={'row'}>
          {this.buildColumnSection('Número', `${currentPatient.attentionNumber}`)}
          <Grid container item xs={12}>
            <div className={classes.blockLabel}>{'Estado'}</div>
            <Tooltip
              title={`Atención ${tooltipState}`}
              placement='right'
              PopperProps={{ style: { pointerEvents: 'none', left: 70 } }}
              TransitionComponent={Zoom}
            >
              <img src={imageState} className={classes.lockIcon} />
            </Tooltip>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  buildTimerComp() {
    const classes = this.props.classes;
    return (
      <Grid container item xs={12} className={classes.watchContainer}>
        <React.Fragment>{Images.getIconBy('watch', 'default', '', { size: 60, marginRight: 5 })}</React.Fragment>
        <Timer>
          <Timer.Hours formatValue={(value) => `${String(value).length === 1 ? `0${value}` : value}`} />:
            <Timer.Minutes formatValue={(value) => `${String(value).length === 1 ? `0${value}` : value}`} />:
            <Timer.Seconds formatValue={(value) => `${String(value).length === 1 ? `0${value}` : value}`} />
        </Timer>
      </Grid>
    );
  }

  buildConsultationType() {
    const classes = this.props.classes;
    const isFirstTime = this.isFirstTime();

    return (
      <FormControl className={classes.radioButtonsContainer}>
        <FormLabel>{`Consulta`}</FormLabel>
        <RadioGroup className={classes.radioButtonsContent} value={isFirstTime}>
          <FormControlLabel disabled value={true} control={<Radio color={'primary'} />} label={'Primera Vez'} />
          <FormControlLabel disabled value={false} control={<Radio color={'primary'} />} label={'De Control'} />
        </RadioGroup>
      </FormControl>
    );
  }

  render() {
    const classes = this.props.classes;

    return (
      <Paper className={classes.paper} style={isWidthDown('sm', this.props.width) ? { maxHeight: 'none' } : {}}>
        <Grid container className={classes.patientInfoContainer} direction={'row'} spacing={1}>
          <Grid container item justify={'flex-start'} lg={3} md={6} sm={6} spacing={0}>
            {this.firstColumn()}
          </Grid>
          <Grid container item lg={2} md={6} sm={6} spacing={0}>
            {this.secondColumn()}
          </Grid>
          <Grid container item justify={'space-around'} lg={7} md={12} spacing={0}>
            <Grid container item xs={12} sm={5} spacing={0}>
              {this.thirdColumn()}
            </Grid>
            <Grid container item xs={12} sm={4} spacing={0}>
              {this.fourthColumn()}
            </Grid>
            <Grid container item xs={12} sm={3} spacing={0}>
              {this.fifthColumn()}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
  //#endregion Render Functions
}

export default withWidth()(withStyles(styles, { withTheme: true })(Patient));
