// Material
import { Theme } from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';

import { ApplicationStyles, Colors, Metrics } from '../../../Theme';

const styles = (theme: Theme) => createStyles({
  ...ApplicationStyles,
  avatar: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
  blockInfo: {
    display: 'flex',
  },
  blockLabel: {
    fontWeight: 600,
  },
  collapseContainer: {
    marginBottom: Metrics.smallMarginOrPaddingBase,
    marginTop: Metrics.largeMarginOrPaddingBase,
  },
  collapseLabel: {
    cursor: 'pointer',
    fontSize: Metrics.largeMarginOrPaddingBase,
    marginBottom: Metrics.smallMarginOrPaddingBase,
    marginTop: Metrics.smallMarginOrPaddingBase,
  },
  divider: {
    height: 2,
  },
  value: {
    paddingLeft: Metrics.smallMarginOrPaddingBase,
    textAlign: 'left',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'calc(100vh - 104px)', // height 64, 20 marginTop y 20 marginBottom in layoutContainer styles
    boxSizing: 'border-box',
    marginBottom: Metrics.marginOrPaddingBase,
  },
  patientInfoContainer: {
    marginBottom: Metrics.largeMarginOrPaddingBase,
    marginTop: Metrics.largeMarginOrPaddingBase,
    height: '20%',
  },
  previousBackgroundButton: {
    marginTop: Metrics.smallMarginOrPaddingBase,
  },
  previousBackground: {
    marginBottom: Metrics.largeMarginOrPaddingBase,
    marginTop: Metrics.largeMarginOrPaddingBase,
    textAlign: 'start',
  },
  radioButtonsContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  radioButtonsContent: {
    flexDirection: 'initial',
    marginLeft: Metrics.smallMarginOrPaddingBase,
  },
  title: {
    fontSize: Metrics.largeMarginOrPaddingBase,
  },
  attentionNumber: {
    marginTop: Metrics.marginOrPaddingBase,
  },
  attentionLabel: {
    color: Colors.error,
  },
  attentionValue: {
    fontWeight: 'bold',
  },
  scrollableSection: {
    overflowY: 'auto',
    height: '80%',
  },
  watchContainer: {
    marginTop: Metrics.smallMarginOrPaddingBase,
  },
  lockIcon: {
    width: 20,
    height: 20,
    marginLeft: Metrics.marginOrPaddingBase,
  },
});

export default styles;
