// Libraries
import { to } from 'await-to-js';
import { batch } from 'react-redux';

// Utils
import Constants from '../../Utils/Constants';
import { createCookie } from '../../Utils/cookies';
import { dispatchShowDialog, dispatchShowSpinner } from '../App/AppUtils';
import { validateErrorFromClientRequest } from '../ReducersUtils';

// ServiceBase
import executeRequest from '../../Services/ServiceBase';

export enum UserActionTypes {
  LOGIN = 'user/LOGIN',
  LOGIN_READY = 'user/LOGIN_READY',
  SET_LAST_ACTION = 'user/SET_LAST_ACTION',
  INVALID_SESSION = 'user/INVALID_SESSION',
  SET_SESSION_VALID = 'user/SET_SESSION_VALID',
}

export const validate_session = (): IThunkResult => {
  return async (dispatch) => {
    dispatchShowSpinner(dispatch, true);

    const [error] = await to<IUserSignInResponse>(executeRequest(`${process.env.BACKEND_NET_URL}/User/recovery_session`, {}, 'post', true));

    if (error) {
      dispatchShowDialog(dispatch, true, error.message, 'error');
      return;
    }

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(set_session_valid(true));
    });
  };
};

export const authenticate = (userToken: string): IThunkResult => {
  return async (dispatch) => {
    dispatchShowSpinner(dispatch, true);

    // const [error, validateResponse] =
    //   await to<IUserSignInResponse>(executeRequest(`${process.env.BACKEND_NET_URL}/User/recovery_session`, params, 'post', true));

    // const errorParsed = validateErrorFromClientRequest(error, validateResponse);
    // if (errorParsed || !validateResponse.data) {
    //   dispatchShowDialog(dispatch, true, errorParsed ? errorParsed.message : 'No hay información en la respuesta', 'error');
    //   return;
    // }

    // const userToken = validateResponse.data.access_token;
    createCookie('token', userToken, 8);
    // const userToken = getCookie('token');
    const [errorUserInfo, userInfo] = await to<IUserInfo>(executeRequest('user', { token: userToken }, 'get'));
    const errorParsed2 = validateErrorFromClientRequest(errorUserInfo, userInfo);

    if (errorParsed2) {
      dispatchShowDialog(dispatch, true, errorParsed2.message, 'error');
      return;
    }

    userInfo.token = userToken;
    
    if (userInfo.profile !== 'Asistente Medico' && userInfo.profile !== 'Medico') {
      dispatchShowDialog(dispatch, true, Constants.alert_messages.not_valid_user, 'warning');
      return;
    }

    batch(() => {
      dispatch(set_data_user(userInfo));
      dispatchShowSpinner(dispatch, false);
    });
  };
};

export const set_last_last_action_user = (value?: UserActionTypes): IAction => {
  return {
    type: UserActionTypes.SET_LAST_ACTION,
    value,
  };
};

export const set_data_user = (value?: IUserInfo): IAction => {
  return {
    type: UserActionTypes.LOGIN,
    value,
  };
};

export const set_session_valid = (isvalid?: boolean): IAction => ({ type: UserActionTypes.SET_SESSION_VALID, value: isvalid });
