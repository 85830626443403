import {
  cloneDeep,
  each,
  forOwn,
  isArray,
  isEmpty,
  map,
  startsWith,
  upperFirst,
} from 'lodash';

export const capitalize = (value: any) => {
  if (typeof value !== 'string') {
    return value;
  }

  const newValue = value.split(' ');
  if (newValue.length === 1) {
    const lower = value.toLowerCase();
    return upperFirst(lower.split('_').join(' '));
  }
  return value;
};

export const calculateWidthForColumns = (colums: IHeadRow[]): IHeadRow[] => {
  const columns: IHeadRow[] = cloneDeep(colums);

  let totalRowsWithPercentage = 0;
  let totalWidth = 100;

  const columnsWithoutPercent: IHeadRow[] = [];

  each(columns, (column) => {
    if (!column.percentageWidth) {
      columnsWithoutPercent.push(column);
      return;
    }

    totalRowsWithPercentage += 1;
    totalWidth -= column.percentageWidth;
  });

  each(columnsWithoutPercent, (column) => {
    const rowsWithoutPercent = columns.length - totalRowsWithPercentage;
    const width = totalWidth / rowsWithoutPercent;

    column.percentageWidth = Number(width.toFixed(2));
  });

  return columns;
};

/**
 * copyObject
 * @param obj
 */
export const copyObject = <T>(obj: T): T => {
  const filesCopy: any = {};
  forOwn(obj, (value: any, key: string) => {
    if (isImageFile(value)) {
      filesCopy[key] = value;
    }
    else if (isArray(value) && value.length > 0) {
      if (isImageFile(value[0])) {
        filesCopy[key] = value;
      }
    }
  });

  let objectCopied: T;

  try {
    objectCopied = JSON.parse(JSON.stringify(obj));
    if (!isEmpty(filesCopy)) {
      objectCopied = { ...objectCopied, ...filesCopy };
    }
    return objectCopied;
  }
  catch (error) {
    return null;
  }
};

export const isImageFile = (value: any): boolean => {
  if (value && typeof (value) === 'object') {
    if (value.type && startsWith(value.type, 'image')) {
      return true;
    }
  }
  return false;
};

export const getRowPageOption = (dataLength: number): number[] => {
  const array = [5, 10, 15];
  const basicRows: number[] = [];

  map(array, (eachNumber) => {
    if (dataLength >= eachNumber || eachNumber <= 5) {
      basicRows.push(eachNumber);
    }
  });

  return basicRows;
};

/**
 * This function return the correct url to load file
 * @param fileId string | object. FileId or File object to be added as objectUrl
 */
export const loadFile = (fileId: any): string => {
  if (!fileId) {
    return null;
  }

  if (typeof fileId === 'object' && fileId.size) { // If is File type
    return URL.createObjectURL(fileId); // Make file-path a valid url to be loaded
  }

  if (typeof fileId === 'string') {
    return `/api/v1/file/${fileId}`;
  }

  return null;
};

/**
 * compare two objects
 * @param obj1
 * @param obj2
 */
export const objectNotEquals = (obj1: object, obj2: object) => {
  return JSON.stringify(obj1) !== JSON.stringify(obj2);
};

export const validateEmail = (email: string) => {
  // tslint:disable-next-line: max-line-length
  const validateExp = new RegExp(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
  return validateExp.test(email);
};

export const getTimeFromSeparatedValues = (years: number, months: number, days: number) => {
  const yearsText = years ? `${years} ${years > 1 ? 'años' : 'año'},` : '';
  const monthsText = months ? ` ${months} ${months > 1 ? 'meses' : 'mes'},` : '';
  const daysText = days ? ` ${days} ${days > 1 ? 'días' : 'día'}` : '';

  return `${yearsText}${monthsText}${daysText}`;
};
