// Libraries
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Module
import Container from '.';

// Actions
import {
  set_data_dialog,
  set_show_dialog,
  show_spinner,
} from '../Reducers/App/AppActions';

//#region TS Interfaces and Types
interface IOwnProps {
  history?: HistoryType; // Prop inject in router Navigator
}
type TMapStateToProps = ReturnType<typeof mapStateToProps>;
type TMapDispatchToProps = ReturnType<typeof mapDispatchToProps>;

export type ContainerProps = IOwnProps & TMapStateToProps & TMapDispatchToProps;
//#endregion TS Interfaces and Types

const mapStateToProps = (state: IRootState) => {
  return {
    app: state.app,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatchType) => bindActionCreators({
  // App Actions
  set_show_dialog,
  show_spinner,
  set_data_dialog,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Container);
