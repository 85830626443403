// Libraries
import React from 'react';
import { withRouter } from 'react-router';

import classNames from 'classnames';
import { map } from 'lodash';

// Material UI.
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';

// Components
import DrawerComp from '../DrawerComp/DrawerCompScene';

// Material Styles
import withStyles from '@material-ui/core/styles/withStyles';

// Icons
import MenuIcon from '@material-ui/icons/Menu';

// Scene
import { HeaderProps } from './HeaderScene';

// styles
import HeaderStyles from './HeaderStyles';

// Images
import Images from '../../Images';

// config
import config from '../../enviroment';

interface IState {
  anchorEl?: any;
  itemsMenuVertical: string[];
}

class Header extends React.Component<HeaderProps, IState> {

  constructor(props: HeaderProps) {
    super(props);
    this.state = {
      itemsMenuVertical: ['Cerrar Sesion'],
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
    this.onClickGoToAgenda = this.onClickGoToAgenda.bind(this);
  }

  UNSAFE_componentDidMount() {
    if (this.props.width === 'xs' || this.props.width === 'sm') {
      this.props.set_open_drawer(false);
    }
  }

  // Handlers
  handleMenu(event: any) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose(event: any) {
    if (event.currentTarget.childNodes.length !== 0) {
      if (event.currentTarget.childNodes[1].data) {
        if (event.currentTarget.childNodes[1].data === 'Cerrar Sesion') {
          this.props.history.replace('/login');
          this.props.set_data_user(null);
        }
      }
    }
    this.setState({ anchorEl: null });
  }

  onClickGoToAgenda() {
    const pathname = this.props.location.pathname;
    const infoSplitted = pathname.split('/patient/');

    if (infoSplitted.length > 1 || infoSplitted[0].includes('update_user')) {
      return this.props.history.replace('/');
    }

    const agendaLink = `${config.hostAgenda}#/CitasMedico/MisCitas`;
    window.location.href = agendaLink;
  }

  renderUserOptions(items: string[]) {
    return map(items, (item, i) => <MenuItem onClick={this.handleClose} key={i} > {item}</MenuItem >);
  }

  handleDrawerToggle = () => {
    this.props.set_open_drawer(!this.props.app.isOpenDrawer);
  };

  //#region Render Functions
  renderLogo() {
    const classes = this.props.classes;
    return (
      <img src={Images.logo} className={classes.logo} />
    );
  }

  renderTitle() {
    const profile = this.props.user.data ? this.props.user.data.profile : 'No hay usuario con sesión';
    return (
      <Typography variant='h6' color='inherit' className={'truncateTextToOneLine'} style={{ flexGrow: 1 }}>
        {`Historia Clinica: ${profile}`}
      </Typography>
    );
  }

  renderUserAvatar(anchorEl: any) {
    const open = Boolean(anchorEl);
    return (
      <div>
        <Avatar
          onClick={this.handleMenu}
          src={'https://cdn3.iconfinder.com/data/icons/vector-icons-for-mobile-apps-2/512/Settings_black-256.png'}
          className={this.props.classes.bigAvatar}
          id={'avatar'}
        />
        <Menu
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={this.handleClose}
        >
          {this.renderUserOptions(this.state.itemsMenuVertical)}
        </Menu>
      </div>
    );
  }

  renderButtonDrawer(width: string) {
    if (width === 'xs' || width === 'sm') {
      return (
        <IconButton color='inherit' aria-label='Open drawer' onClick={this.handleDrawerToggle}>
          <MenuIcon />
        </IconButton>
      );
    }
  }

  render() {
    const { classes, width, hideDrawer } = this.props;

    const fixLeftSpace: any = {};

    if (hideDrawer) {
      fixLeftSpace.marginLeft = 0;
      fixLeftSpace.width = '100%';
    }

    return (
      <div className={classNames(classes.appBar, this.props.app.isOpenDrawer && classes.appBarShift)} style={fixLeftSpace}>
        <AppBar position='static'>
          <Toolbar style={{ paddingLeft: (width === 'xs' || width === 'sm') && 0 }}>
            {this.renderButtonDrawer(width)}
            <img src={Images.logo} className={classes.logo} />
            {this.renderTitle()}
            <IconButton color='inherit' aria-label='goToAgenda' onClick={this.onClickGoToAgenda}>
              <img src={Images.goToAgenda} className={classes.doorImg} />
            </IconButton>
          </Toolbar>
        </AppBar>
        {!hideDrawer && <DrawerComp />}
      </div >
    );
  }
  //#endregion Render Functions
}

export default withRouter(withWidth()(withStyles(HeaderStyles, { withTheme: true })(Header)));
