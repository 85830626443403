// Modules
import React from 'react';

// Components
import DrawerChildItem from '../DrawerChildItem';
import DrawerListItem from '../DrawerListItem';

// MaterialCore
import { WithStyles } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import MaterialList from '@material-ui/core/List';
import withStyles from '@material-ui/core/styles/withStyles';

// Styles
import materialStyles from './MaterialStyle';

// Utils
import { objectNotEquals } from '../../../Utils';

interface IDrawerListProps extends Partial<WithStyles<typeof materialStyles>> {
  key: string;
  isOpenDrawer: boolean;
  drawerItem: IDrawerItem;
  index: number;
  handleClick: (drawerItem: IDrawerItem, level?: number) => void;
}

class DrawerList extends React.Component<IDrawerListProps> {

  //#region LifeCycle
  constructor(props: IDrawerListProps) {
    super(props);
  }

  shouldComponentUpdate(nextProps: IDrawerListProps) {
    let shouldRender: boolean = false;

    if (this.props.isOpenDrawer !== nextProps.isOpenDrawer) {
      shouldRender = true;
    } else if (objectNotEquals(this.props.drawerItem, nextProps.drawerItem)) {
      shouldRender = true;
    }

    return shouldRender;
  }
  //#endregion LifeCycle

  //#region Render
  renderDivider() {
    const { classes, isOpenDrawer } = this.props;

    return (
      <Divider className={isOpenDrawer ? classes.colorDivider : classes.colorDividerHide} />
    );
  }

  render() {
    const {
      classes,
      isOpenDrawer,
      drawerItem,
      handleClick,
      key,
    } = this.props;

    return (
      <div key={key}>
        <MaterialList
          id={'config'}
          component={'div'}
          disablePadding
          className={drawerItem.isExpanded ? classes.drawerItemsListExpanded : classes.drawerItemsListCollapse}
        >
          <DrawerListItem
            // Params
            drawerItem={drawerItem}
            isOpenDrawer={isOpenDrawer}
            // Callbacks
            handleClick={handleClick}
          />
          <Collapse in={drawerItem.isExpanded} timeout={'auto'} unmountOnExit>
            <DrawerChildItem
              // Params
              drawerItems={drawerItem.drawerItems}
              isOpenDrawer={this.props.isOpenDrawer}
              // Callbacks
              handleClick={handleClick}
            />
          </Collapse>
        </MaterialList>
        {this.renderDivider()}
      </div>
    );
  }
  //#endregion Render
}

export default withStyles(materialStyles, { withTheme: true })(DrawerList) as typeof DrawerList;
