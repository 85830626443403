// Libraries
import { map } from 'lodash';

//#region Cookies
export const createCookie = (name: string, value: string, expDays: number) => {
  const d = new Date();
  d.setTime(d.getTime() + (expDays * 24 * 60 * 60 * 1000));
  const expires = 'expires=' + d.toUTCString();
  document.cookie = name + '=' + value + ';' + expires + ';path=/';
};

export const getCookie = (cname: string) => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  const token = map(ca, (c) => {
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  });
  return token[0];
};

export const deleteCookie = (key: string) => {
  document.cookie = key + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};
//#endregion Cookies

//#region Cache Data
export const getStorageItem = (keyName: string) => {
  const data = localStorage.getItem(keyName);

  if (data) {
    return JSON.parse(data);
  }

  return null;
};

export const createStorageItem = (key: string, value: any, expDays?: number) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeStorageItem = (keyName: string) => {
  localStorage.removeItem(keyName);
};

export const clearAllLocalStorage = () => {
  localStorage.clear();
};
//#endregion Cache Data
