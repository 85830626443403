// Modules
import React from 'react';

// MaterialCore
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

// MaterialIcons
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

// Images
import Images, { GeneralIconsType } from '../../../Images';

// Styles
import { Colors } from '../../../Theme';
import materialStyles from './MaterialStyle';

// Utils
import { objectNotEquals } from '../../../Utils';

class DrawerListItem extends React.Component<IDrawerListItemProps> {

  //#region LifeCycle
  constructor(props: IDrawerListItemProps) {
    super(props);
  }

  shouldComponentUpdate(nextProps: IDrawerListItemProps) {
    let shouldRender: boolean = false;

    if (this.props.isOpenDrawer !== nextProps.isOpenDrawer) {
      shouldRender = true;
    } else if (objectNotEquals(this.props.drawerItem, nextProps.drawerItem)) {
      shouldRender = true;
    }

    return shouldRender;
  }
  //#endregion LifeCycle

  //#region Render
  renderDisplayArrow(drawerItem: IDrawerItem) {
    const isOpenDrawer = this.props.isOpenDrawer;

    if (!isOpenDrawer || !drawerItem.drawerItems) {
      return null;
    }

    const shouldExpand = drawerItem.isExpanded;

    if (shouldExpand) {
      return (
        <ExpandLess />
      );
    }

    return (
      <ExpandMore />
    );
  }

  renderIcon(icon: GeneralIconsType) {
    return Images.getIconBy(icon, 'small');
  }

  renderLabel() {
    const { classes, drawerItem } = this.props;

    return (
      <ListItemText
        aria-multiline
        primary={`${this.props.isOpenDrawer ? drawerItem.label : ''}`}
        // disableTypography={true}
        className={classes.text}
        primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
      />
    );
  }

  renderListItem() {
    if (this.props.isOpenDrawer) {
      return this.renderItem();
    }

    return (
      <Tooltip
        title={this.props.drawerItem.label}
        placement='right'
        PopperProps={{ style: { pointerEvents: 'none', left: 70 } }}
        TransitionComponent={Zoom}
      >
        {this.renderItem()}
      </Tooltip>
    );
  }

  renderItem() {
    const { classes, drawerItem } = this.props;

    return (
      <ListItem
        button
        onClick={() => { this.props.handleClick(drawerItem, 1); }}
        className={classes.listItemTextParent}
        style={{
          color: drawerItem.isExpanded && Colors.drawerListItemExpandedTextColor,
        }}
      >
        {this.renderIcon(drawerItem.icon)}
        {this.renderLabel()}
        {this.renderDisplayArrow(drawerItem)}
      </ListItem>
    );
  }

  render() {
    return this.renderListItem();
  }
  //#endregion Render
}

export default withStyles(materialStyles, { withTheme: true })(DrawerListItem);
