import createStyles from '@material-ui/core/styles/createStyles';
import Color from '../../../Theme/Colors';

const styles = (theme: any) => createStyles({
  coloDrawerContent: {
    color: Color.drawerListItemTextColor,
  },
  drawerLogo: {
    flex: 1,
    justifyContent: 'center',
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 12px',
    ...theme.mixins.toolbar,
    height: 64,
  },
});

export default styles;
