import { batch } from 'react-redux';

// Third Party modules
import { to } from 'await-to-js';

// Utils
import { getStorageItem } from '../../Utils/cookies';
import { dispatchShowDialog, dispatchShowSpinner } from '../App/AppUtils';
import { validateErrorFromClientRequest } from '../ReducersUtils';
// import { addNewVariable } from './PatientUtils';

// External Actions
import { set_loading_drawer } from '../App/AppActions';
import { set_current_doctor } from '../MedicalHistory/MedicalHistoryActions';

// Service
import executeRequest from '../../Services/ServiceBase';

export enum PatientActionTypes {
  ACTIVE_MICRO = 'patient/ACTIVE_MICRO',
  ATTENTION_CLOSED = 'patient/ATTENTION_CLOSED',
  SET_CURRENT_PATIENT = 'patient/SET_CURRENT_PATIENT',
  SET_LAST_ACTION = 'patient/SET_LAST_ACTION',
  SET_PATIENT_LISTS = 'patient/SET_PATIENT_LISTS',
  SET_RESIDENCE_COUNTRY_LISTS = 'patient/SET_RESIDENCE_COUNTRY_LISTS',
  SET_BIRTH_COUNTRY_LISTS = 'patient/SET_BIRTH_COUNTRY_LISTS',
  SET_PATIENT_SECTIONS = 'patient/SET_PATIENT_SECTIONS',
}

export const set_last_action = (value?: PatientActionTypes): IAction => ({ type: PatientActionTypes.SET_LAST_ACTION, value });
export const set_current_patient = (value: IPatientData): IAction => ({ type: PatientActionTypes.SET_CURRENT_PATIENT, value });
export const set_patient_sections = (value: IPatientSection[]): IAction => ({ type: PatientActionTypes.SET_PATIENT_SECTIONS, value });

// export const add_new_variable_to_tab = (tab: number): IThunkResult => {
//   return (dispatch, getState) => {
//     const previousUserBackground = getState().patient.previousUserBackground;
//     dispatch(set_previous_user_background(addNewVariable(previousUserBackground, tab)));
//   };
// };

export const active_micro = (): IAction => {
  // tslint:disable-next-line: no-console
  console.log('executing micro...');

  return {
    type: PatientActionTypes.ACTIVE_MICRO,
    value: null,
  };
};

export const create_new_atention = (): IThunkResult => {
  return async (dispatch, getState) => {

    dispatchShowSpinner(dispatch, true);

    const currentPatient = getState().patient.currentPatient;
    const currentDoctor = getState().medicalHistory.currentDoctor;

    const params = {
      appointment_id: currentPatient.appointmentId,
      patient_id: currentPatient.patientUserId,
      doctor_id: currentDoctor.value,
    };

    const [error, newAttentionResponse] = await to<IPatientData>(executeRequest(`create_new_attention`, params, 'post'));
    const errorParsed = validateErrorFromClientRequest(error, newAttentionResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    currentPatient.attentionNumber = newAttentionResponse.attentionNumber;

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(set_current_patient(currentPatient));
    });
  };
};

export const close_atention = (): IThunkResult => {
  return async (dispatch, getState) => {

    dispatchShowSpinner(dispatch, true);

    const currentPatient = getState().patient.currentPatient;
    const currentDoctor = getState().medicalHistory.currentDoctor;

    const params = {
      attention_number: currentPatient.attentionNumber,
      user_name: currentDoctor.label,
    };

    const [error, newAttentionResponse] = await to<IPatientData>(executeRequest(`close_attention`, params, 'post'));
    const errorParsed = validateErrorFromClientRequest(error, newAttentionResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(set_last_action(PatientActionTypes.ATTENTION_CLOSED));
    });
  };
};

/**
 * Get current selected patient
 * @param patientId to be able to get the patientInfo
 */
export const get_patient = (patientUrlRecordId: string, appointmentDate: string, doctorId: string, newGetPatient?: boolean): IThunkResult => {
  return async (dispatch, getState) => {
    dispatchShowSpinner(dispatch, true, 10000, true);

    const currentPatient = getState().patient.currentPatient;
    const currentDoctor = getState().medicalHistory.currentDoctor;
    const userData = getState().user.data;
    let patientId = patientUrlRecordId;
    let url = 'patient';

    if (!patientId) {
      patientId = newGetPatient ? currentPatient.patientId : currentPatient.patientUserId;
    }
    if (doctorId && !currentDoctor) {
      dispatch(set_current_doctor({ label: `${userData.firstName} ${userData.lastName}`, value: userData.id }, true));
    }
    if (newGetPatient) {
      url = 'patient_v2';
    }

    const params = {
      patient_id: patientId,
      appointment_date: appointmentDate,
      doctor_id: doctorId,
      user_name: `${userData.firstName} ${userData.lastName}`,
    };

    const [error, patientResponse] = await to<IPatientData>(executeRequest(url, params, 'get', false, 10000));
    const errorParsed = validateErrorFromClientRequest(error, patientResponse);
    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    batch(() => {
      dispatchShowSpinner(dispatch, false);

      const newCurrentPatient = {
        ...currentPatient,
        ...patientResponse,
      };

      let persistentInfo = getStorageItem('historicAttentionNumber');
      if (persistentInfo) {
        persistentInfo = parseInt(persistentInfo, 10);
      }

      if (persistentInfo) {
        newCurrentPatient.attentionNumber = persistentInfo;
        newCurrentPatient.attentionStatus = 'C';
      }

      newCurrentPatient.patientId = patientId;

      dispatch(set_current_patient(newCurrentPatient));
      dispatch(set_last_action(PatientActionTypes.SET_CURRENT_PATIENT));
      dispatch(get_patient_lists(`${userData.firstName} ${userData.lastName}`, patientResponse.birthCountryId, patientResponse.residenceCountryId));
    });
  };
};

export const get_sections = (): IThunkResult => {
  return async (dispatch, getState) => {

    dispatch(set_loading_drawer(true));

    const [error, sectionsResponse] = await to<IPatientSection[]>(executeRequest(`sections`, {}, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, sectionsResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    batch(() => {
      dispatch(set_loading_drawer(false));
      dispatch(set_patient_sections(sectionsResponse));
      dispatch(set_last_action(PatientActionTypes.SET_PATIENT_SECTIONS));
    });
  };
};

export const get_patient_lists = (userName: string, d2: number, d: number): IThunkResult => {
  return async (dispatch) => {
    dispatchShowSpinner(dispatch, true, 10000, true);

    const params = {
      user_name: userName,
    };

    const [error, patientListsResponse] = await to<IObj>(executeRequest(`patient_lists_data`, params, 'get', false, 10000));
    const errorParsed = validateErrorFromClientRequest(error, patientListsResponse);
    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    const countries = patientListsResponse.countries;
    let countryCode1: string = null;
    let countryCode2: string = null;

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < countries.length; i++) {
      const country: IObj = countries[i];

      if (country.value === d) {
        countryCode1 = country.code;
      }
      if (country.value === d2) {
        countryCode2 = country.code;
      }
    }

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch({ type: PatientActionTypes.SET_PATIENT_LISTS, value: patientListsResponse });
      dispatch(get_residence_country_lists(countryCode1, userName));
      dispatch(get_birth_country_lists(countryCode2, userName));
    });
  };
};

export const get_residence_country_lists = (countryId: string, userName: string): IThunkResult => {
  return async (dispatch) => {
    dispatchShowSpinner(dispatch, true, 10000, true);

    const params = {
      country_id: countryId,
      user_name: userName,
    };

    const [error, response] = await to<IPatientData>(executeRequest(`residence_lists_data`, params, 'get', false, 10000));
    const errorParsed = validateErrorFromClientRequest(error, response);
    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch({ type: PatientActionTypes.SET_RESIDENCE_COUNTRY_LISTS, value: response });
    });
  };
};

export const get_birth_country_lists = (countryId: string, userName: string): IThunkResult => {
  return async (dispatch) => {
    dispatchShowSpinner(dispatch, true, 10000, true);

    const params = {
      country_id: countryId,
      user_name: userName,
    };

    const [error, response] = await to<IPatientData>(executeRequest(`cities`, params, 'get', false, 10000));
    const errorParsed = validateErrorFromClientRequest(error, response);
    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch({ type: PatientActionTypes.SET_BIRTH_COUNTRY_LISTS, value: response });
    });
  };
};

const allInfoIsValid = (dispatch: IThunkDispatch, currentPatient: IPatientData): boolean => {
  const msgPrefixI = 'Por favor digite';
  const msgPrefixS = 'Por favor seleccione';

  if (!currentPatient.documentKind) {
    dispatchShowDialog(dispatch, true, `${msgPrefixS} el tipo documento del paciente`, 'warning');
    return false;
  }
  else if (!currentPatient.documentId) {
    dispatchShowDialog(dispatch, true, `${msgPrefixI} el numero documento del paciente`, 'warning');
    return false;
  }
  else if (!currentPatient.phone) {
    dispatchShowDialog(dispatch, true, `${msgPrefixI} el teléfono/celular del paciente`, 'warning');
    return false;
  }

  return true;
};

export const update_patient = (): IThunkResult => {
  return async (dispatch, getState) => {
    dispatchShowSpinner(dispatch, true, 10000, true);

    const currentPatient = getState().patient.currentPatient;
    if (!allInfoIsValid(dispatch, currentPatient)) {
      return;
    }

    const userData = getState().user.data;
    let bloodTypeSelected = null;

    for (const bloodType of getState().patient.bloodTypes) {
      if (bloodType.value === currentPatient.bloodType) {
        bloodTypeSelected = bloodType.label;
      }
    }

    const params = {
      patient_id: currentPatient.patientId,
      document_type: currentPatient.documentKind,
      document_id: currentPatient.documentId,
      first_name: currentPatient.patientFirstName,
      second_name: currentPatient.patientSecondName,
      last_name: currentPatient.patientLastName,
      second_last_name: currentPatient.patientSecondLastName,
      phone: currentPatient.phone,
      patient_user_name: currentPatient.userName,
      email: currentPatient.email,
      birth_date: currentPatient.birthDate,
      birth_country_id: currentPatient.birthCountryId,
      birth_city_id: currentPatient.birthCityId,
      residence_country_id: currentPatient.residenceCountryId,
      residence_city_id: currentPatient.residenceCityId,
      address: currentPatient.address,
      gender: currentPatient.genderId,
      scholarship_id: currentPatient.scholarshipId,
      occupation_id: currentPatient.occupationId,
      marital_status_id: currentPatient.maritalStatusId,
      companion_name: currentPatient.companionName,
      companion_phone: currentPatient.companionPhone,
      companion_document_id: currentPatient.companionDocumentId,
      companion_relation_ship_id: currentPatient.companionRelationShipId,
      companion_address: currentPatient.companionAddress,
      blood_type: bloodTypeSelected,
      breed_id: currentPatient.breed,
      agreement_id: currentPatient.agreementId,
      entity_id: currentPatient.entityId,
      plan_id: currentPatient.planId,
      user_name: `${userData.firstName} ${userData.lastName}`,
    };

    const [error, response] = await to(executeRequest(`update_patient_data`, params, 'post'));
    const errorParsed = validateErrorFromClientRequest(error, response);
    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    batch(() => {
      dispatchShowSpinner(dispatch, false);
    });
  };
};
