import { Theme } from '@material-ui/core/styles';

import Colors from './Colors';
import Fonts from './Fonts';
import Metrics from './Metrics';

const containerStyle = (theme: Theme) => {
  return {
    marginTop: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  };
};

const smallContainerStyle = (theme: Theme) => {
  return {
    marginTop: theme.spacing(1),
    overflow: 'hidden',
    width: '70%',
  };
};

const blockForm = (theme: Theme) => {
  return {
    margin: '25px 0px',
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
  };
};

export const componentsWithMaterialTheme = {
  containerStyle,
  smallContainerStyle,
  blockForm,
};

const applicationStyles = {
  drawerWidth: {
    width: 256,
  },
  fullWidth: {
    width: '100%',
  },
  fullHeight: {
    height: '100%',
  },
  fullContent: {
    height: '100%',
    width: '100%',
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center' as 'center',
  },
  flexRowAlignCenter: {
    display: 'flex',
    alignItems: 'center' as 'center',
  },
  flexCenterElementsInContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexColumnCenterItems: {
    display: 'flex',
    justifyContent: 'center' as 'center',
    flexDirection: 'column' as 'column',
    alignItems: 'center' as 'center',
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end' as 'flex-end',
  },
  // Table
  tableCell: {
    borderBottom: 'none',
  },
  centerCells: {
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
  },
  containerTableSearch: {
    width: '99%',
    display: 'flex',
    paddingTop: '1%',
    paddingRight: '1%',
    maxWidth: 200,
  },
  cellTreeTable: {
    fontSize: 'calc((6vw - 0.83rem) / 7)',
    display: 'flex',
  },
  sizeIconTableImageContainer: {
    width: '40px',
    height: '40px',
  },
  paddingTableImageContainer: {
    paddingTop: '2%',
    paddingBottom: '2%',
  },
  // Orders
  fontWeightLabel: {
    fontWeight: 500,
  },
  dividerStyle: {
    height: '2px',
    background: Colors.backgroundDivider,
    width: '100%',
  },
  fontSizeSubTitle: {
    fontSize: '0.9em',
  },
  infoLabel: {
    fontSize: '1em',
    marginBottom: '2%',
    width: 'fit-content',
  },
  root: {
    flexGrow: 1,
    overflow: 'auto',
    justifyContent: 'center',
    display: 'flex',
  },
  containerButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1%',
    marginBottom: '1%',
  },
  backButtonSt: {
    boxShadow: 'none',
  },
  marginFormToToolbar: {
    marginTop: 15,
  },
  divider: {
    height: 2,
    marginTop: Metrics.largeMarginOrPaddingBase,
    marginBottom: Metrics.largeMarginOrPaddingBase,
    width: '100%',
  },
  pageTitle: {
    fontSize: Fonts.fontSizeAlmostDouble,
    color: Colors.blueButton,
    textAlign: 'start' as any,
  },
  tablePreview: {
    marginTop: Metrics.marginOrPaddingBase,
  },
  tablePreviewCell: {
    borderColor: Colors.black,
    borderStyle: 'solid',
    borderWidth: 1,
    padding: Metrics.marginOrPaddingBase,
  },
  iconsContainer: {
    bottom: 0,
    display: 'flex',
    position: 'absolute' as any,
    right: Metrics.smallXSMarginOrPaddingBase,
  },
  tableHeader: {
    borderColor: Colors.black,
    borderStyle: 'solid',
    borderWidth: 1,
    padding: Metrics.smallMarginOrPaddingBase,
  },
  headerAdjustments: {
    // backgroundColor: Colors.tableHeadersReview,
    fontWeight: Fonts.fontWeightStandard,
    fontSize: 17,
  },
  emptyData: {
    padding: Metrics.largeXLMarginOrPaddingBase,
  },
  textArea: {
    border: '1px solid',
    paddingLeft: Metrics.smallMarginOrPaddingBase,
    paddingRight: Metrics.smallMarginOrPaddingBase,
    paddingTop: Metrics.smallMarginOrPaddingBase,
  },
  rightButtonsPanel: {
    paddingLeft: Metrics.marginOrPaddingBase,
  },
  searchBtn: {
    width: '100%',
  },
  baseBorder: {
    borderColor: Colors.black,
    borderStyle: 'solid',
    borderWidth: 1,
  },
  subtitle: {
    display: 'block',
    fontSize: '1.15em',
    fontWeight: 500,
  },
  modalTitle: {
    fontWeight: 'bold' as any,
    marginRight: Metrics.smallXSMarginOrPaddingBase,
  },
  marginInBottom: {
    marginBottom: Metrics.marginOrPaddingBase,
  },
  marginInTop: {
    marginTop: Metrics.marginOrPaddingBase,
  },
  physicalExamDropDown: {
    marginBottom: 0,
    marginTop: 0,
  },
  physicalExamBorder: {
    borderBottom: 0,
  },
  physicalExamCellColor: {
    borderColor: Colors.lightGray,
  },
  physicalExamPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  leftTitle: {
    backgroundColor: Colors.leftColor,
    color: Colors.white,
  },
  rightTitle: {
    backgroundColor: Colors.rightColor,
    color: Colors.white,
  },
};

export default applicationStyles;
