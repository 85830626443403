export default {
  show_spinner_types: {
  },
  alert_messages: {
    itemShouldBeSelected: 'Debe seleccionar el item que quiere añadir',
    request_error: 'No podemos completar su solicitud',
    request_error_friendly: 'Algo ha pasado... No podemos completar su solicitud, intentalo mas tarde.',
    record_not_found: 'No se pudo encontrar el registro en nuestra base de datos',
    not_valid_user: 'Esta aplicación es solo para Medicos y Asistentes Médicos',
    update_patient_data: 'Debe actualizar los datos del paciente para ser atendido',
    register_updated: 'Registro Actualizado',
    pdf_report_success: 'Enviando por correo... El archivo se descargará en breve',
    pdf_report_success_no_email: 'El archivo se descargará en breve',
    select_finding: 'Por favor seleccione un hallazgo desde la silueta del cuerpo',
    symptom_already_exist: 'Este sintoma ya ha sido agregado previamente',
    no_symptoms: 'No hay un sintoma seleccionado para ser guardado',
    premium_alert: 'Fase Premium',
  },
  messages: {
    itemCurrentlyAdded: 'El item que estás buscando ya ha sido añadido',
    notFound: 'Tu búsqueda no arrojó resultados',
  },
  limits: {
    page_limit: 15,
  },
  settingsHelp: {
  },
};
