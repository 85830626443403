// Libraries
import { Reducer } from 'redux';

// Constants
import { DiagnosticActionsTypes } from './DiagnosticActions';

const initialState: IDiagnosticReducerType = {
  lastAction: null,
  diagnosticCies: [],
  currentDiagnosticCie: null,
  medicalCheck: null,
  previewDiagnostics: [],
  historical: [],
  doctorLookUpDefault: false,
  diagnosticCiesResponse: null,
};

const reducer: Reducer<IDiagnosticReducerType, IAction> = (state = initialState, action: IAction): IDiagnosticReducerType => {
  switch (action.type) {
    case DiagnosticActionsTypes.SET_LAST_ACTION:
      return {
        ...state,
        lastAction: action.value,
      };
    case DiagnosticActionsTypes.SET_DIAGNOSTIC_CIES:
      return {
        ...state,
        diagnosticCies: action.value,
      };
    case DiagnosticActionsTypes.SET_CURRENT_DIAGNOSTIC_CIE:
      return {
        ...state,
        currentDiagnosticCie: action.value,
      };
    case DiagnosticActionsTypes.SET_MEDICAL_CHECK:
      return {
        ...state,
        medicalCheck: action.value,
      };
    case DiagnosticActionsTypes.SET_PREVIEW_DIAGNOSTIC:
      return {
        ...state,
        previewDiagnostics: action.value,
      };
    case DiagnosticActionsTypes.SET_HISTORICAL_DATA:
      return {
        ...state,
        historical: action.value,
      };
    case DiagnosticActionsTypes.SET_DEFAULT_LOOKUP:
      return {
        ...state,
        doctorLookUpDefault: action.value,
      };
    case DiagnosticActionsTypes.SET_DIAGNOSTIC_CIES_RESPONSE:
      return {
        ...state,
        diagnosticCiesResponse: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
