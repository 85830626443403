import createStyles from '@material-ui/core/styles/createStyles';

import { Colors, Fonts, Metrics } from '../../../Theme';

export const drawerWidth = 257;

const styles = () => createStyles({
  listItemTextParent: {
    color: Colors.drawerListItemTextColor,
    fontSize: Fonts.fontSizeNormal,
  },
  text: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingLeft: Metrics.doubleMarginOrPaddingBase,
  },
  toolTipChild: {
    position: 'absolute',
    left: '-13px',
    height: '44.8px',
    width: '70px',
  },
});

export default styles;
