// Libraries
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { WithStyles } from '@material-ui/core';

// Component
import Patient from '.';

// Actions
import { set_show_dialog } from '../../../Reducers/App/AppActions';
import {
  set_current_page,
  set_filter_value,
} from '../../../Reducers/MedicalHistory/MedicalHistoryActions';
import {
  active_micro,
  create_new_atention,
  get_patient,
  get_sections,
  set_last_action,
} from '../../../Reducers/Patient/PatientActions';

// Styles
import Styles from './PatientStyles';

//#region TS Interfaces and Types
interface IOwnProps {
  history?: HistoryType; // Prop inject in router Navigator
  urlParams?: IUrlParams;
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}
type TMapStateToProps = ReturnType<typeof mapStateToProps>;
type TMapDispatchToProps = ReturnType<typeof mapDispatchToProps>;

export type PatientProps = IOwnProps & TMapStateToProps & TMapDispatchToProps & WithStyles<typeof Styles>;
//#endregion TS Interfaces and Types

const mapStateToProps = (state: IRootState) => {
  return {
    app: state.app,
    patient: state.patient,
    medicalHistory: state.medicalHistory,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatchType) => bindActionCreators({
  // AppActions
  set_show_dialog,
  // MedicalHistoryActions
  set_current_page,
  set_filter_value,
  // PatientActions
  active_micro,
  create_new_atention,
  get_patient,
  get_sections,
  set_last_action,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Patient);
