// Libraries
import { to } from 'await-to-js';
import { replace } from 'lodash';
import { batch } from 'react-redux';

// Utils
import { dispatchShowDialog, dispatchShowSpinner } from '../App/AppUtils';
import { validateErrorFromClientRequest } from '../ReducersUtils';

// Service
import executeRequest from '../../Services/ServiceBase';

export enum NotesActionTypes {
  SET_LAST_ACTION = 'notes/SET_LAST_ACTION',
  SET_NOTE = 'notes/SET_NOTE',
}

export const set_last_action = (value: NotesActionTypes): IAction => ({
  type: NotesActionTypes.SET_LAST_ACTION,
  value,
});

export const set_note = (value: INotes): IAction => ({
  type: NotesActionTypes.SET_NOTE,
  value,
});

/**
 * Get surgery or nursing note from DB
 * @param type There are 3 notes options this param helps to identify which is the current
 */
export const get_note = (type: NotesType): IThunkResult => {
  return async (dispatch, getState) => {
    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    dispatchShowSpinner(dispatch, true);

    const currentPatient = getState().patient.currentPatient;

    const params = {
      attention_number: currentPatient.attentionNumber,
      user_name: loggedUser.firstName,
    };

    let endpoint = null;

    if (type === 'Surgery') {
      endpoint = 'attention_surgery_note';
    } else if (type === 'Nurse') {
      endpoint = 'attention_nursing_note';
    } else if (type === 'Aclaration') {
      endpoint = 'attention_explanatory_note';
    }

    const [error, noteResponse] = await to<INoteResponse>(executeRequest(endpoint, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, noteResponse);
    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    batch(() => {
      const description = noteResponse.nursingDescription || noteResponse.surgeryDescription || noteResponse.explanatoryDescription;
      dispatch(set_note({ description, ...noteResponse }));
      dispatchShowSpinner(dispatch, false);
    });
  };
};

/**
 * Save or update surgery or nursing note  in DB
 * @param type There are 3 notes options this param helps to identify which is the current
 */
export const save_note = (type: NotesType): IThunkResult => {
  return async (dispatch, getState) => {
    const noteData = getState().notes.data;
    if (!noteData.description) {
      let message = 'Por favor escriba la nota de ';
      if (type === 'Surgery') {
        message += 'la cirugía';
      } else if (type === 'Nurse') {
        message += 'la enfermería';
      } else if (type === 'Aclaration') {
        message += 'la Aclaración';
      }

      dispatchShowDialog(dispatch, true, message, 'warning');
      return;
    }

    dispatchShowSpinner(dispatch, true);

    const loggedUser = getState().user.data;
    const currentDoctor = getState().medicalHistory.currentDoctor;
    const currentPatient = getState().patient.currentPatient;

    const params = {
      attention_number: currentPatient.attentionNumber,
      description: noteData.description,
      doctor_id: currentDoctor.value,
      user_name: loggedUser.firstName,
    };

    let endpoint = null;

    if (type === 'Surgery') {
      endpoint = 'create_attention_surgery_note';
    } else if (type === 'Nurse') {
      endpoint = 'create_attention_nursing_note';
    } else if (type === 'Aclaration') {
      endpoint = 'create_attention_explanatory_note';
    }

    if (endpoint === 'create_attention_explanatory_note' && getState().patient.currentPatient.attentionStatus !== 'C') {
      return dispatchShowDialog(dispatch, true, 'Válido solo para citas cerradas', 'warning');
    } else if (endpoint !== 'create_attention_explanatory_note' && getState().patient.currentPatient.attentionStatus === 'C') {
      return dispatchShowDialog(dispatch, true, 'Válido solo para citas abiertas', 'warning');
    }

    const [error, noteResponse] = await to<string>(executeRequest(endpoint, params, 'post'));
    const errorParsed = validateErrorFromClientRequest(error, noteResponse);
    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    let successMessage = `Nota de la %nota guardada existosamente`;

    if (type === 'Surgery') {
      successMessage = replace(successMessage, '%nota', 'cirugía');
    } else if (type === 'Nurse') {
      successMessage = replace(successMessage, '%nota', 'enfemería');
    } else if (type === 'Aclaration') {
      successMessage = replace(successMessage, '%nota', 'aclaración');
    }

    dispatchShowDialog(dispatch, true, successMessage, 'success');

    batch(() => {
      dispatchShowSpinner(dispatch, false);
    });
  };
};
