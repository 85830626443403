import { batch } from 'react-redux';

// Third Party modules
import { to } from 'await-to-js';
import { cloneDeep, each, isUndefined, pullAt } from 'lodash';
import moment from 'moment';

// Utils
// import Constants from '../../Utils/Constants';
import { dispatchShowDialog, dispatchShowSpinner } from '../App/AppUtils';
import { validateErrorFromClientRequest } from '../ReducersUtils';
// import { addNewVariable } from './PatientUtils';

// External Actions

// Service
import executeRequest from '../../Services/ServiceBase';

export enum ManagementPlanActionTypes {
  SET_LAST_ACTION = 'managementPlan/SET_LAST_ACTION',
  SET_MANAGEMENT_PLAN = 'managementPlan/SET_MANAGEMENT_PLAN',
  SET_PREVIEW_PLANS = 'managementPlan/SET_PREVIEW_PLANS',
}

export const set_last_action = (value: ManagementPlanActionTypes): IAction => ({ type: ManagementPlanActionTypes.SET_LAST_ACTION, value });
export const set_management_plan = (value: string): IAction => ({ type: ManagementPlanActionTypes.SET_MANAGEMENT_PLAN, value });
export const set_preview_plans = (value: IManagementPlan[]): IAction => ({ type: ManagementPlanActionTypes.SET_PREVIEW_PLANS, value });

export const get_plans = (): IThunkResult => {
  return async (dispatch, getState) => {

    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    dispatchShowSpinner(dispatch, true);

    const currentPatient = getState().patient.currentPatient;

    const params = {
      attention_number: currentPatient.attentionNumber,
      user_name: loggedUser.firstName,
    };

    const [error, managementPlanResponse] = await to<IManagementPlan[]>(executeRequest(`attention_management_plan`, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, managementPlanResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    each(managementPlanResponse, (eachManagementPlan) => {
      eachManagementPlan.managementPlanDate = moment(eachManagementPlan.managementPlanDate).format('MMMM DD YYYY - hh:mm a');
    });

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(set_preview_plans(managementPlanResponse));
    });
  };
};

export const delete_management_plan = (index: number): IThunkResult => {
  return async (dispatch, getState) => {

    if (getState().patient.currentPatient.attentionStatus === 'C') {
      return dispatchShowDialog(dispatch, true, 'Válido solo para citas abiertas', 'warning');
    }

    const managementPlan = getState().managementPlan;

    const previewPlansCopy = cloneDeep(managementPlan.previewPlans);
    const previewPlan = managementPlan.previewPlans[index];

    dispatchShowSpinner(dispatch, true);

    const loggedUser = getState().user.data;

    const params = {
      user_name: loggedUser.firstName,
      consecutive: previewPlan.consecutive,
    };

    const [error, managementPlanResponse] = await to<IMedicalCheck>(executeRequest(`delete_attention_management_plan`, params, 'post'));
    const errorParsed = validateErrorFromClientRequest(error, managementPlanResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    batch(() => {
      dispatchShowDialog(dispatch, true, 'Plan de manejo eliminado existosamente', 'success');
      pullAt(previewPlansCopy, index);
      dispatch(set_preview_plans(previewPlansCopy));
    });
  };
};

export const save_management_plan = (index?: number): IThunkResult => {
  return async (dispatch, getState) => {

    if (getState().patient.currentPatient.attentionStatus === 'C') {
      return dispatchShowDialog(dispatch, true, 'Válido solo para citas abiertas', 'warning');
    }

    const managementPlan = getState().managementPlan;

    let params: any = null;
    if (isUndefined(index)) { // Is not Edit
      if (!managementPlan.plan) {
        dispatchShowDialog(dispatch, true, 'Por favor escriba el plan de manejo', 'warning');
        return;
      }

      params = {
        description: managementPlan.plan,
      };
    } else {
      const previewPlan = managementPlan.previewPlans[index];

      params = {
        description: previewPlan.managementPlanDescription,
        consecutive: previewPlan.consecutive,
      };
    }

    dispatchShowSpinner(dispatch, true);

    const loggedUser = getState().user.data;
    const currentDoctor = getState().medicalHistory.currentDoctor;
    const currentPatient = getState().patient.currentPatient;

    // Default Values
    params.attention_number = currentPatient.attentionNumber;
    params.doctor_id = currentDoctor.value;
    params.user_name = loggedUser.firstName;

    const [error, bodySystemsResponse] = await to<IMedicalCheck>(executeRequest(`create_attention_management_plan`, params, 'post'));
    const errorParsed = validateErrorFromClientRequest(error, bodySystemsResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    dispatchShowDialog(dispatch, true, 'Plan de manejo guardado existosamente', 'success');

    batch(() => {
      dispatch(set_management_plan(null));

      if (isUndefined(index)) { // Is not Edit
        dispatch(get_plans());
      }
    });
  };
};
