import { combineReducers } from 'redux';

// Reducers
import anthropometricMeasure from '../Reducers/AnthropometricMeasure/AnthropometricMeasureReducer';
import app from '../Reducers/App/AppReducer';
import background from '../Reducers/Background/BackgroundReducer';
import consultationReason from '../Reducers/ConsultationReason/ConsultationReasonReducer';
import cup from '../Reducers/Cup/CupReducer';
import diagnostic from '../Reducers/Diagnostic/DiagnosticReducer';
import drugFormulation from '../Reducers/DrugFormulation/DrugFormulationReducer';
import evolutionNote from '../Reducers/EvolutionNote/EvolutionNoteReducer';
import examsRevAndDiagTest from '../Reducers/ExamsRevAndDiagTest/ExamsRevAndDiagTestReducer';
import managementPlan from '../Reducers/ManagementPlan/ManagementPlanReducer';
import medicalDisability from '../Reducers/MedicalDisability/MedicalDisabilityReducer';
import medicalHistory from '../Reducers/MedicalHistory/MedicalHistoryReducer';
import notes from '../Reducers/Notes/NotesReducer';
import patient from '../Reducers/Patient/PatientReducer';
import physicalExam from '../Reducers/PhysicalExam/PhysicalExamReducer';
import systemReview from '../Reducers/SystemReview/SystemReviewReducer';
import templates from '../Reducers/Templates/TemplatesReducer';
import user from '../Reducers/User/UserReducer';
import vitalSigns from '../Reducers/VitalSigns/VitalSignsReducer';

export default combineReducers<IRootState>({
  anthropometricMeasure,
  app,
  background,
  consultationReason,
  cup,
  diagnostic,
  drugFormulation,
  evolutionNote,
  examsRevAndDiagTest,
  managementPlan,
  medicalDisability,
  medicalHistory,
  notes,
  patient,
  physicalExam,
  systemReview,
  user,
  vitalSigns,
  templates,
});
