// Libraries
import { Reducer } from 'redux';

// Constants
import Constants from '../../Utils/Constants';
import { AnthropometricMeasureActionTypes } from './AnthropometricMeasureActions';

const initialState: IAnthropometricMeasureReducerType = {
  lastAction: null,
  currentAnthropometricMeasure: null,
  anthropometricMeasures: [],
  searchMeasureTerm: null,
  measureOptions: [],
  measureSelected: null,
  measuresResponse: null,
  currentPage: 0,
  rowsPerPage: Constants.limits.page_limit,
  totalAnthropometricMeasures: 0,
  searchTerm: null,
  doctorLookUpDefault: false,
};

const reducer: Reducer<IAnthropometricMeasureReducerType, IAction> = (state = initialState, action: IAction): IAnthropometricMeasureReducerType => {
  switch (action.type) {
    case AnthropometricMeasureActionTypes.SET_LAST_ACTION:
      return {
        ...state,
        lastAction: action.value,
      };
    case AnthropometricMeasureActionTypes.SET_CURRENT_MEASURE:
      return {
        ...state,
        currentAnthropometricMeasure: action.value,
      };
    case AnthropometricMeasureActionTypes.SET_ANTHROPOMETRIC_MEASURE:
      return {
        ...state,
        anthropometricMeasures: action.value.data,
        totalAnthropometricMeasures: action.value.count,
      };
    case AnthropometricMeasureActionTypes.SET_SEARCH_MEASURE_TERM:
      return {
        ...state,
        searchMeasureTerm: action.value,
      };
    case AnthropometricMeasureActionTypes.SET_MEASURE_OPTIONS:
      return {
        ...state,
        measureOptions: action.value,
      };
    case AnthropometricMeasureActionTypes.SET_SELECTED_SEARCH_MEASURE:
      return {
        ...state,
        measureSelected: action.value,
      };
    case AnthropometricMeasureActionTypes.SET_MEASURE_REPONSE:
      return {
        ...state,
        measuresResponse: action.value,
      };
    case AnthropometricMeasureActionTypes.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.value,
      };
    case AnthropometricMeasureActionTypes.SET_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.value,
      };
    case AnthropometricMeasureActionTypes.SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.value,
      };
    case AnthropometricMeasureActionTypes.SET_DEFAULT_LOOKUP:
      return {
        ...state,
        doctorLookUpDefault: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
