// Modules
import React from 'react';

// MaterialCore
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';

// MaterialIcons
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

// Styles
import materialStyles from './MaterialStyle';

class DrawerHeader extends React.Component<IDrawerHeaderProps, IDrawerHeaderState> {

  //#region LifeCycle
  constructor(props: IDrawerHeaderProps) {
    super(props);

    this.state = {
      imageReady: false,
    };

    this.handlerLoad = this.handlerLoad.bind(this);
  }

  shouldComponentUpdate(nextProps: IDrawerHeaderProps, nextState: IDrawerHeaderState) {
    let shouldRender: boolean = false;

    if (this.props.isOpenDrawer !== nextProps.isOpenDrawer) {
      shouldRender = true;
    }
    else if (this.state.imageReady !== nextState.imageReady) {
      shouldRender = true;
    }

    return shouldRender;
  }
  //#endregion LifeCycle

  //#region Handlers
  handlerLoad() {
    this.setState({ imageReady: true });
  }
  //#endregion Handlers

  //#region Functions
  //#endregion Functions

  //#region Render
  renderLogo() {
    const { classes, isOpenDrawer } = this.props;
    const logo: string = null;

    if (!isOpenDrawer) {
      return null;
    }

    return (
      <div className={classes.drawerLogo}>
        <img
          height={50}
          src={logo}
          style={{ display: !this.state.imageReady && 'none' }}
          width={150}
          onLoad={this.handlerLoad}
        />
      </div>
    );
  }

  render() {
    const { classes, isOpenDrawer } = this.props;

    return (
      <div className={classes.toolbar}>
        {this.renderLogo()}
        <IconButton
          className={classes.coloDrawerContent}
          id={'close_drawer'}
          onClick={this.props.handleDrawerOpen}
        >
          {isOpenDrawer ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </div>
    );
  }
  //#endregion Render
}

export default withStyles(materialStyles, { withTheme: true })(DrawerHeader);
