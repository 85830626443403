// Libraries
import { Reducer } from 'redux';

// Constants
import { SystemReviewActionTypes } from './SystemReviewActions';

const initialState: ISytemReviewReducerType = {
  lastAction: null,
  bodySystems: [],
  currentBodySystem: null,
  medicalReview: null,
  attentionSymptoms: [],
};

const reducer: Reducer<ISytemReviewReducerType, IAction> = (state = initialState, action: IAction): ISytemReviewReducerType => {
  switch (action.type) {
    case SystemReviewActionTypes.SET_LAST_ACTION:
      return {
        ...state,
        lastAction: action.value,
      };
    case SystemReviewActionTypes.SET_BODY_SYSTEMS:
      return {
        ...state,
        bodySystems: action.value,
      };
    case SystemReviewActionTypes.SET_CURRENT_BODY_SYSTEM:
      return {
        ...state,
        currentBodySystem: action.value,
      };
    case SystemReviewActionTypes.SET_MEDICAL_REVIEW:
      return {
        ...state,
        medicalReview: action.value,
      };
    case SystemReviewActionTypes.SET_SYMPTOMS:
      return {
        ...state,
        attentionSymptoms: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
