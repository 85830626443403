// Libraries
import { concat, each, isArray, isEqual, map, slice } from 'lodash';
import moment from 'moment';
import { batch } from 'react-redux';

// Utils
import { dispatchLoadingTable } from './App/AppUtils';

// Constants
import Constants from '../Utils/Constants';

export const searchAndPagination = <T extends IObj[]>(page: number, data: T, searchTerm: string, searchKeys?: string[]): ISelectorResponse<T> => {
  const skip = page * Constants.limits.page_limit;

  if (searchTerm) {
    data = searchDataInLists(data, searchTerm, searchKeys);
  }

  const currentCount = skip + Constants.limits.page_limit;
  const dataSliced = slice(data, skip, currentCount) as T;

  return {
    data: dataSliced,
    currentCount,
    total: data.length,
  };
};

/**
 * Search data in list
 * @param data data list
 * @param term term for search
 */
export const searchDataInLists = <T extends IObj[]>(data: T, term: string, keysForSearch: string[]): T => {
  const newArray: T = [] as T;

  map(data, (row) => {
    map(keysForSearch, (key) => {
      if (row[key]) {
        row[key] = row[key].toString();
        if (row[key].toLowerCase().includes(term.toLowerCase())) {
          let isSaved: boolean = false;

          each(newArray, (rowSaved: IObj) => {
            if (isEqual(rowSaved, row)) {
              isSaved = true;
              return false;
            }
          });

          if (!isSaved) {
            newArray.push(row);
          }
        }
      }
    });
  });

  return newArray;
};

/**
 * Search by searchTerm or paginate records
 * @param limit RowsPerPage
 * @param page current page
 * @param data info to be paginated
 * @param searchTerm value to search
 * @param searchKeys in which keys we are goint to search
 */
export const filterAndPagination = (limit: number, page: number, data: any, searchTerm: string, searchKeys?: string[]) => {
  const skip = page * limit;

  if (searchTerm) {
    data = searchDataInLists(data, searchTerm, searchKeys);
  }

  return slice(data, skip, skip + limit);
};

/**
 * dispatchDataResponse
 * @param dispatch dispatch action
 * @param paginationObj List paginationObj
 * @param actions actions from respective reducer to dispatch
 * @param data Internal data array
 * @param dataCount Total data from DB
 */
// tslint:disable-next-line: max-line-length
export const dispatchDataResponse = (dispatch: IThunkDispatch, actions: IPagingDispatchActions, paginationObj: IPaginationObject, data: any[], dataCount: number) => {
  batch(() => {
    dispatch(actions.setCurrentPageDispatch(paginationObj.page));
    dispatch(actions.setRowsPerPageDispatch(paginationObj.rowsPerPage));
    if (data && dataCount !== -1) {
      dispatch(actions.setDataDispatch({ data, count: dataCount }));
      dispatchLoadingTable(dispatch, false);
    }
  });
};

export const concatResponse = (currentData: any[], responseData: any[], skip: number) => {
  // const responseDataBeauty = setBeautyDateAndText(responseData, keys);
  const data = skip === 0 ? responseData : concat(currentData, responseData); // Pending Verify.. Works with lodash->union?
  return data;
};

export const parsingDates = (currentData: any[], keys: string[], timeFormat?: boolean) => {
  const timeFormatString = 'hh:mm a';
  each(currentData, (data) => {
    each(keys, (key) => {
      if (data[key]) {
        data[`${key}Beauty`] = moment(data[key]).format(!timeFormat ? `YYYY/MM/DD ${timeFormatString}` : timeFormatString);
      }
    });
  });

  return currentData;
};

/**
 * Look for error in error or response keys
 * @param error Request error
 * @param response Request response
 */
export const validateErrorFromClientRequest = (error: any, response: any) => {
  if (error) {
    return error;
  }

  if (!isArray(response) && response.code && response.code !== 0) {
    return response;
  }

  return null;
};
