// Material Styles
import { Theme } from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';

import { Metrics } from '../../Theme';

const drawerWidth = 257;

const styles = (theme: Theme) => createStyles({
  layoutContainer: {
    paddingRight: 'env(safe-area-inset-right)',
    marginTop: Metrics.largeMarginOrPaddingBase,
    marginBottom: Metrics.largeMarginOrPaddingBase,
    marginRight: Metrics.marginOrPaddingBase,
    marginLeft: drawerWidth + Metrics.marginOrPaddingBase,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      marginLeft: Metrics.marginOrPaddingBase,
    },
    // overflow: 'scroll',
    height: 'calc(100% - 104px)',
  },
  layoutContainerWithMargin: {
    marginTop: Metrics.largeMarginOrPaddingBase,
    marginRight: Metrics.marginOrPaddingBase,
    marginLeft: theme.spacing(9) + Metrics.marginOrPaddingBase,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      marginLeft: Metrics.marginOrPaddingBase,
    },
  },
  backContainer: {
    paddingLeft: '26.7%',
    paddingTop: '2%',
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
  },
});

export default styles;
