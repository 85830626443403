// Libraries
import { Reducer } from 'redux';

// Constants
import { TemplatesActionTypes } from './TemplatesActions';

const initialState: ITemplateReducerType = {
  data: [],
  templatesOptions: [],
  templateSelected: null,
};

const reducer: Reducer<ITemplateReducerType, IAction> = (state = initialState, action: IAction): ITemplateReducerType => {
  switch (action.type) {
    case TemplatesActionTypes.SET_TEMPLATES:
      return {
        ...state,
        data: action.value,
      };
    case TemplatesActionTypes.SET_TEMPLATES_OPTIONS:
      return {
        ...state,
        templatesOptions: action.value,
      };
    case TemplatesActionTypes.SET_TEMPLATE_SELECTED:
      return {
        ...state,
        templateSelected: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
