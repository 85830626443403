// Modules.
import classNames from 'classnames';
import { Action, Location } from 'history';
import { each, find, findIndex, map, replace } from 'lodash';
import React from 'react';
import { withRouter } from 'react-router';

// Components
import DraweHeader from '../DrawerComponents/DrawerHeader';
import DrawerList from '../DrawerComponents/DrawerList';

import { DrawerProps } from './DrawerCompScene';

// Material UI.
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import withWidth from '@material-ui/core/withWidth';
import Zoom from '@material-ui/core/Zoom';

// styles
import { copyObject } from '../../Utils';
import materialStyles from './DrawerCompStyles';

// Constants
import { PatientActionTypes } from '../../Reducers/Patient/PatientActions';

class DrawerComp extends React.Component<DrawerProps, IDrawerState> {
  //#region LifeCycle
  constructor(props: DrawerProps) {
    super(props);
    this.state = {
      drawerList: [],
    };

    // Handlers
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.onClickCloseAtention = this.onClickCloseAtention.bind(this);

    // Render
    this.renderDrawerList = this.renderDrawerList.bind(this);
  }

  componentDidMount() {
    this.props.history.listen((location: Location, action: Action) => {
      if (action === 'PUSH' || action === 'POP') {
        this.highlightCurrentRoute(); // For each navigation
      }
    });
  }

  componentDidUpdate() {
    if (this.props.patient.lastAction === PatientActionTypes.SET_PATIENT_SECTIONS) {
      this.props.set_last_action();
      this.buildSection();
    }
  }
  //#endregion LifeCycle

  //#region Functions
  getCorrectRouteBySection(sectionId: number) {
    // Check LayoutContainer cases
    switch (sectionId) {
      case 1:
        return 'datosPersonales';
      case 2:
        return 'motivoConsulta';
      case 3:
        return 'atencedentesGenerales';
      case 4:
        return 'revisionSistema';
      case 5:
        return 'signosVitales';
      case 6:
        return 'medidaAntropometrica';
      case 7:
        return 'examenFisico';
      case 8:
        return 'revExamDiagTest';
      case 9:
        return 'notaEvolucion';
      case 10:
        return 'diagnostico';
      case 11:
        return 'formulacionMedicamentos';
      case 12:
        return 'formulacionExamenesConsultas';
      case 13:
        return 'planManejo';
      case 14:
        return 'formulacionIncapacidad';
      case 15:
        return 'notaEnfermeria';
      case 16:
        return 'notaQuirurgica';
      case 17:
        return 'notaAclaratoria';
      case 18:
        return 'specializedExams';
      default:
        return '';
    }
  }

  buildSection() {
    const patientSections = this.props.patient.patientSections;

    const sections: IDrawerItem[] = [];

    each(patientSections, (section) => {

      const drawerItem: IDrawerItem = {
        sectionId: section.sectionId,
        label: section.section,
        icon: 'account',
        route: this.getCorrectRouteBySection(section.sectionId),
      };

      const parentSectionId = section.parentSectionId;
      const parentIndex = findIndex(sections, { sectionId: parentSectionId });
      if (parentIndex !== -1) {
        let currentDrawerItems = sections[parentIndex].drawerItems;
        if (currentDrawerItems) {
          currentDrawerItems.push(drawerItem);
        } else {
          currentDrawerItems = [drawerItem];
        }

        sections[parentIndex] = {
          ...sections[parentIndex],
          drawerItems: currentDrawerItems,
        };
      } else {
        sections.push(drawerItem);
      }
    });

    this.setState({ drawerList: sections }, this.highlightCurrentRoute);
  }
  /**
   * Mark in blue the current open drawer item
   */
  highlightCurrentRoute() {
    const url = window.location.href;
    const getModuleContainerSection = url.match(/\/[^\d]+$/g);
    let route = null;
    if (getModuleContainerSection && getModuleContainerSection.length) {
      route = replace(getModuleContainerSection[0], '/', '');

      if (route) {
        const drawerList = copyObject(this.state.drawerList);
        const drawerLabel = this.getLabelByRoute(drawerList, route);
        this.expandParentDrawerItems(drawerList, drawerLabel);
      }
    }
  }

  /**
   * Search Label from route.
   * @param drawerList All Drawer List from the root
   * @param drawerRoute route obtained from url
   */
  getLabelByRoute(drawerList: IDrawerItem[], drawerRoute: string) {
    let label: string = null;
    each(drawerList, (eachDrawer) => {
      if (eachDrawer.route === drawerRoute) {
        label = eachDrawer.label;
        return false;
      }

      if (eachDrawer.drawerItems && !label) {
        label = this.getLabelByRoute(eachDrawer.drawerItems, drawerRoute);
      }
    });

    if (label) {
      return label;
    }
  }

  /**
   * Expand the item that was found and starts to expand each parents in reverse
   * @param drawerList All Drawer List from the root
   * @param drawerLabel Drawer Label to match with the drawer list in recursive execution this argument is parentLabel
   */
  expandParentDrawerItems(drawerList: IDrawerItem[], drawerLabel: string) {
    this.searchOpenedDrawerItem(drawerList, drawerLabel, 1, null, (parentLabel: string) => {
      if (parentLabel) {
        this.expandParentDrawerItems(drawerList, parentLabel);
      }
    });
  }

  /**
   * Search the drawerItem by label and expand it
   * @param drawerList All Drawer List from the root
   * @param drawerLabel Drawer Label to match with the drawer list in recursive execution this argument is parentLabel
   * @param level To detect the first level to execute the setState
   * @param parentLabel To execute recursive logic and expand
   * @param cb To return the parent label
   */
  searchOpenedDrawerItem(drawerList: IDrawerItem[], drawerLabel: string, level: number, parentLabel: string, cb: LoopDynamicCb) {
    let foundRoute = false;
    each(drawerList, (eachDrawer) => {
      if (eachDrawer.label === drawerLabel) {
        eachDrawer.isExpanded = true;
        foundRoute = true;
      } else {
        eachDrawer.isExpanded = false;
      }

      if (eachDrawer.drawerItems && !eachDrawer.isExpanded) {
        this.searchOpenedDrawerItem(eachDrawer.drawerItems, drawerLabel, level + 1, eachDrawer.label, cb);
      }
    });

    if (level === 1) {
      this.setState({ drawerList });
    }

    if (foundRoute) {
      return cb(parentLabel);
    }
  }

  /**
   * manage drawer opening when a draweritem is pressed
   * @param drawerItem current draweritem pressed
   */
  handlerIsOpenDrawer(drawerItem: IDrawerItem) {
    const { app, width } = this.props;

    // if is mobile and has not childitems it will close drawer
    if (width === 'xs' || width === 'sm') {
      if (!drawerItem.drawerItems) {
        this.props.set_open_drawer(false);
      }
    }

    // if is not mobile and drawer is closed it will open drawer
    if (!app.isOpenDrawer) {
      this.props.set_open_drawer(true);
    }
  }

  /**
   * control Expanded of DrawerItems when a item is pressed
   * @param level: Level to identify hierarchy, 1 = Apps, 2 = navigationItems, etc.
   * @param drawerItem: current draweritem pressed
   * @param isExpanded: value of Expanded to set
   */
  controlDrawerItemsExpanded(level: number, drawerItem: IDrawerItem, isExpanded: boolean) {
    if (level === 1) { // When press is to parent, the level is = 1. Only need update the first level and not worry by childs
      const drawerList = this.manageExpandedItems(this.state.drawerList, drawerItem, true, isExpanded);
      this.setState({ drawerList });
      return;
    }

    this.updateChildsInAdminDrawer(drawerItem, isExpanded);
  }

  /**
   * Validate the correct value to expanded and clicked properties. Returns original drawerItemsInitial sent
   * @param drawerItemsInitial: Drawer items to manage expanded status.
   * @param currentDrawerItem: The last item pressed.
   * @param onlyFirstLevel: Execute only for parent items don't need be recursive to childs
   * @param isExpanded: Expanded value
   * @param setAllChildsToFalse: If a parent is not expanded set all childs to false.
   */
  // tslint:disable-next-line: max-line-length
  manageExpandedItems: ManageExpandedItems = (drawerItemsInitial, currentDrawerItem, onlyFirstLevel?, isExpanded?, setAllChildsToFalse?) => {
    const drawerItems: IDrawerItem[] = copyObject(drawerItemsInitial);
    return map(drawerItems, (drawerItem) => {
      if ((drawerItem.route && drawerItem.route === currentDrawerItem.route) || (!drawerItem.route && drawerItem.label === currentDrawerItem.label)) {

        // This map iterate inside all items in same level and update expanded status to false.
        map(drawerItems, (eachDrawerItem) => {
          eachDrawerItem.isExpanded = false;
        });

        drawerItem.isExpanded = isExpanded;
      }

      // Validate if childs need be setted to false if parent is not expanded.
      if (setAllChildsToFalse) {
        drawerItem.isExpanded = false;
      }

      let setAllChildsToFalseVar = false;
      if (!drawerItem.isExpanded) {
        setAllChildsToFalseVar = true;
      }

      if (drawerItem.drawerItems && !onlyFirstLevel && (drawerItem.isExpanded || setAllChildsToFalseVar)) {
        // All calls to this returns the drawerItems parents
        // tslint:disable-next-line: max-line-length
        drawerItem.drawerItems = this.manageExpandedItems(drawerItem.drawerItems, currentDrawerItem, onlyFirstLevel, isExpanded, setAllChildsToFalseVar);
      }

      return drawerItem;
    });
  }

  /**
   * Search drawer by expanded status
   * @param drawerItems: Source drawers to show
   */
  searchCurrentExpandedDrawerItem(drawerItems: IDrawerItem[]) {
    return find(drawerItems, (appItem) => appItem.isExpanded);
  }

  /**
   * Update childs inside drawer items with all changes applied inside manageExpandedItems
   * @param drawerItems: Parent items to update the childs
   * @param drawerChildItems: Childs that was updated
   * @param currentLabel: String to match de parent drawerItem
   */
  setInternalDrawerItemsToApp(drawerItems: IDrawerItem[], drawerChildItems: IDrawerItem[], currentLabel: string) {
    const newApps: IDrawerItem[] = copyObject(drawerItems);

    each(newApps, (item) => {
      if (item.label === currentLabel) {
        item.drawerItems = drawerChildItems;
        return false;
      }
    });

    this.setState({ drawerList: newApps });
  }

  /**
   * Iterate inside all childs in admin drawer and updated expanded status
   * @param drawerItem: Current click item
   * @param isExpanded: Expanded status
   */
  updateChildsInAdminDrawer(drawerItem: IDrawerItem, isExpanded: boolean, setAllChildsToFalse?: boolean) {
    let finalItems = this.state.drawerList;
    let drawerLabel = finalItems[0].label; // Default parent admin item

    // Returns parent section. In this moment is the same at this.state.drawerListAdminAccount[0], because only has 1 position.
    const adminDrawerItems: IDrawerItem = this.searchCurrentExpandedDrawerItem(this.state.drawerList);

    /**
     * Because when searchCurrentExpandedDrawerItem is called and admin drawerItem is expanded false, returns undefined
     * this helps to set expanded key to false inside all childs when drawerListAdminAccount parent is expanded false
     */
    if (adminDrawerItems) {
      finalItems = adminDrawerItems.drawerItems;
      drawerLabel = adminDrawerItems.label;
    }

    // Manage only clicked status because in this moment this items don't has internal items.
    const drawerList = this.manageExpandedItems(finalItems, drawerItem, false, isExpanded, setAllChildsToFalse);

    if (!setAllChildsToFalse) {
      // Here inject the managed items to drawerListAdminAccount state
      this.setInternalDrawerItemsToApp(this.state.drawerList, drawerList, drawerLabel);
    } else {
      // just update the managed items to drawerListAdminAccount without inject
      this.setState({ drawerList });
    }
  }
  //#endregion Functions

  //#region Handlers
  handleDrawerOpen() {
    this.props.set_open_drawer(!this.props.app.isOpenDrawer);
  }

  handleClick(drawerItem: IDrawerItem, level?: number) {
    let drawerItemRoute = drawerItem.route;
    let isExpanded = true;

    if (drawerItem.isExpanded && drawerItem.drawerItems) {
      isExpanded = false;
    }

    this.controlDrawerItemsExpanded(level, drawerItem, isExpanded);
    this.handlerIsOpenDrawer(drawerItem);

    if (drawerItem.route) {
      if (drawerItem.route === 'specializedExams' && this.props.physicalExam.especializedExams.length === 1) {
        drawerItemRoute = 'examenFisico';
      }

      const patientUserId = this.props.patient.currentPatient.patientUserId;
      const route = `/patient/${patientUserId}/${drawerItemRoute}`;
      this.props.history.replace(route);
      return;
    }

    if (!drawerItem.drawerItems) {
      this.props.set_show_dialog({
        show: true,
        type: 'dialog',
        message: 'NO ROUTE DEFINED',
      });
    }
  }

  onClickCloseAtention() {
    this.props.close_atention();
  }
  //#endregion Handlers

  //#region Render
  /**
   * Rendering Drawer List
   */
  renderDrawerList() {
    return map(this.state.drawerList, (drawerItem: IDrawerItem, index: number) => {
      return (
        <DrawerList
          key={`${drawerItem.label}-${index}`}
          isOpenDrawer={this.props.app.isOpenDrawer}
          drawerItem={drawerItem}
          handleClick={this.handleClick}
          index={index}
        />
      );
    });
  }

  renderDrawerMobile() {
    const { theme, classes, app } = this.props;

    return (
      <Hidden mdUp> {/* comment when tests are return, add width === 'xs' || width === 'sm'  */}
        <Drawer
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={app.isOpenDrawer}
          onClose={this.handleDrawerOpen}
          classes={{
            paper: classNames(classes.drawerPaperResponsive, app.isOpenDrawer && classes.drawerPaperResponsiveShow),
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {this.buildDrawerList(true)}
          {this.buildCloseAttentionButton()}
        </Drawer>
      </Hidden>
    );
  }

  buildDrawerList(isMobile?: boolean) {
    const classes = this.props.classes;
    const space = isMobile ? 59 : 123;

    const loadingSections = this.props.app.isLoadingDrawer;
    const centerClass = loadingSections ? classes.flexCenterElementsInContainer : null;

    return (
      <div
        id={'app_list'}
        className={classNames(classes.drawerContent, centerClass)}
        style={{ height: `calc(100% - ${space}px)` }}
      >
        {loadingSections ? (
          <CircularProgress />
        ) : (
            <React.Fragment>
              {this.renderDrawerList()}
              <Divider className={classes.colorDivider} />
            </React.Fragment>
          )}
      </div>
    );
  }

  buildCloseAttentionButton(isMobile?: boolean) {
    const classes = this.props.classes;
    const size = isMobile ? 59 : 123;

    const isOpenDrawer = this.props.app.isOpenDrawer;
    let textButton = 'Cerrar';
    if (isOpenDrawer) {
      textButton += ' Atención';
    }

    return (
      <div style={{ height: size }} className={classes.flexCenterElementsInContainer}>
        <Tooltip
          title={'Cerrar Atención'}
          placement='right'
          PopperProps={{ style: { pointerEvents: 'none', left: 70 } }}
          TransitionComponent={Zoom}
        >
          <Button color='primary' variant={'contained'} onClick={this.onClickCloseAtention}>
            {textButton}
          </Button>
        </Tooltip>
      </div>
    );
  }

  renderDrawerWeb() {
    const { classes, app } = this.props;

    return (
      <Hidden smDown implementation='css'> {/* comment when tests are return, add width !== 'xs' && width !== 'sm'  */}
        <Drawer
          id={'drawer'}
          variant='permanent'
          classes={{
            paper: classNames(classes.drawerPaper, !app.isOpenDrawer && classes.drawerPaperClose),
          }}
          open={app.isOpenDrawer}
        >
          <DraweHeader
            isOpenDrawer={app.isOpenDrawer}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <Divider className={classes.colorDivider} />
          {this.buildDrawerList()}
          {this.buildCloseAttentionButton()}
        </Drawer>
      </Hidden>
    );
  }

  render() {
    return (
      <div>
        {this.renderDrawerMobile()}
        {this.renderDrawerWeb()}
      </div >
    );
  }
  //#endregion Render
}

const component: any = withWidth()(withStyles(materialStyles, { withTheme: true })(DrawerComp));
export default withRouter(component);
