// Libraries
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Component
import LayoutContainer from '.';

// Actions
import { authenticate, set_last_last_action_user } from '../../../Reducers/User/UserActions';

//#region TS Interfaces and Types
interface IOwnProps {
  match: {
    params: {
      tab: string,
    },
    url: string,
  };
  history: HistoryType;
}
type TMapStateToProps = ReturnType<typeof mapStateToProps>;
type TMapDispatchToProps = ReturnType<typeof mapDispatchToProps>;

export type LayoutContainerProps = IOwnProps & TMapStateToProps & TMapDispatchToProps;
//#endregion TS Interfaces and Types

const mapStateToProps = (state: IRootState) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatchType) => bindActionCreators({
  authenticate,
  set_last_last_action_user,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LayoutContainer);
