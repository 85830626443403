import { batch } from 'react-redux';

// Third Party modules
import { to } from 'await-to-js';
import { cloneDeep, each, find, isUndefined, pullAt } from 'lodash';

// Utils
import { dispatchShowDialog, dispatchShowSpinner } from '../App/AppUtils';
import { validateErrorFromClientRequest } from '../ReducersUtils';
import { setDiagnosticOptions } from './Utils';

// Service
import executeRequest from '../../Services/ServiceBase';

// External Actions
import { set_loading_dropdown } from '../App/AppActions';

export enum DiagnosticActionsTypes {
  SET_LAST_ACTION = 'Diagnostic/SET_LAST_ACTION',
  SET_DIAGNOSTIC_CIES = 'Diagnostic/SET_DIAGNOSTIC_CIES',
  SET_CURRENT_DIAGNOSTIC_CIE = 'Diagnostic/SET_CURRENT_DIAGNOSTIC_CIE',
  SET_MEDICAL_CHECK = 'Diagnostic/SET_MEDICAL_CHECK',
  SET_PREVIEW_DIAGNOSTIC = 'Diagnostic/SET_PREVIEW_DIAGNOSTIC',
  SET_HISTORICAL_DATA = 'Diagnostic/SET_HISTORICAL_DATA',
  SET_DIAGNOSTIC_CIES_RESPONSE = 'Diagnostic/SET_DIAGNOSTIC_CIES_RESPONSE',
  SET_DEFAULT_LOOKUP = 'Diagnostic/SET_DEFAULT_LOOKUP',
}

export const set_last_action = (value: DiagnosticActionsTypes): IAction => ({ type: DiagnosticActionsTypes.SET_LAST_ACTION, value });
export const set_diagnostic_cies = (value: IOptionsData[]): IAction => ({ type: DiagnosticActionsTypes.SET_DIAGNOSTIC_CIES, value });
export const set_current_diagnostic_cie = (value: IOptionsData): IAction => ({ type: DiagnosticActionsTypes.SET_CURRENT_DIAGNOSTIC_CIE, value });
export const set_medical_check = (value: string): IAction => ({ type: DiagnosticActionsTypes.SET_MEDICAL_CHECK, value });
export const set_preview_diagnostic = (value: IPreviewDiagnostics[]): IAction => ({ type: DiagnosticActionsTypes.SET_PREVIEW_DIAGNOSTIC, value });
export const set_historical_data = (value: IHistoricalDiagnostic[]): IAction => ({ type: DiagnosticActionsTypes.SET_HISTORICAL_DATA, value });
export const set_diagnostic_cies_response
  = (value: IDiagnosticCieResponse[]): IAction => ({ type: DiagnosticActionsTypes.SET_DIAGNOSTIC_CIES_RESPONSE, value });

export const set_default_lookup = (value: boolean, updateLocalFilter?: boolean): IThunkResult => {
  return async (dispatch, getState) => {
    batch(() => {
      if (updateLocalFilter) {
        const diagnosticCiesResponse = getState().diagnostic.diagnosticCiesResponse;
        const doctorLookUpDefault = value;

        setDiagnosticOptions(diagnosticCiesResponse, doctorLookUpDefault, dispatch, set_diagnostic_cies);
      }

      dispatch({ type: DiagnosticActionsTypes.SET_DEFAULT_LOOKUP, value });
    });
  };
};

export const get_diagnostic = (avoidGetCies?: boolean): IThunkResult => {
  return async (dispatch, getState) => {

    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    dispatchShowSpinner(dispatch, true);

    const currentPatient = getState().patient.currentPatient;
    const currentDoctor = getState().medicalHistory.currentDoctor;

    const params = {
      attention_number: currentPatient.attentionNumber,
      user_name: loggedUser.firstName,
      doctor_id: currentDoctor.value,
    };

    const [error, previewDiagnosticsResponse] = await to<IPreviewDiagnosticsResponse>(executeRequest(`attention_diagnostics`, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, previewDiagnosticsResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(set_default_lookup(previewDiagnosticsResponse.hasSetUpRegisters)); // For knowing if there are pre-configured doctor records

      if (!avoidGetCies) {
        dispatch(get_diagnostic_cies());
      }

      dispatch(set_preview_diagnostic(previewDiagnosticsResponse.attentionDiagnostics));
    });
  };
};

export const get_diagnostic_cies = (mergeDoctorValues?: boolean): IThunkResult => {
  return async (dispatch, getState) => {

    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    dispatch(set_loading_dropdown(true));

    const params: any = {
      user_name: loggedUser.firstName,
      filter_value: '', // Filter is executed locally
    };

    if (mergeDoctorValues) {
      const currentDoctor = getState().medicalHistory.currentDoctor;
      params.doctor_id = currentDoctor.value;
    }

    let ciesResponse: IDiagnosticCieResponse[] = null;
    let error: Error = null;

    [error, ciesResponse] = await to(executeRequest(`diagnostic_cies_by_filters`, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, ciesResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    /**
     * The idea is get all the data and after that recursively get all pre-configured values by doctor.
     * With both request i can join it and execute the filters locally and
     * prevent request with each button press in dynamicLookUp componente
     */
    if (!mergeDoctorValues) {
      dispatch(set_diagnostic_cies_response(ciesResponse));
      return dispatch(get_diagnostic_cies(true));
    } else {
      const diagCiesResponse = getState().diagnostic.diagnosticCiesResponse;

      /**
       * Loop the previous measures loaded from the first request and verify if
       * each record exist in the pre-configured values by doctor getted in last request response.
       */
      each(diagCiesResponse, (eachDiagCie) => {
        const foundMeasure = find(ciesResponse, { cieTechnicalCode: eachDiagCie.cieTechnicalCode });
        if (foundMeasure) { // If the measure is found in the pre-configured values, set a new key to identify it.
          eachDiagCie.preConfiguredByDoctor = true;
        }
      });

      ciesResponse = diagCiesResponse; // Update the last response with all records loaded and edited
    }

    const doctorLookUpDefault = getState().diagnostic.doctorLookUpDefault;

    batch(() => {
      dispatch(set_loading_dropdown(false));
      setDiagnosticOptions(ciesResponse, doctorLookUpDefault, dispatch, set_diagnostic_cies);
      dispatch(set_diagnostic_cies_response(ciesResponse));
    });
  };
};

export const delete_diagnostic = (index: number): IThunkResult => {
  return async (dispatch, getState) => {

    if (getState().patient.currentPatient.attentionStatus === 'C') {
      return dispatchShowDialog(dispatch, true, 'Válido solo para citas abiertas', 'warning');
    }

    const diagnostic = getState().diagnostic;

    const previewDiagnosticCopy = cloneDeep(diagnostic.previewDiagnostics);
    const previewDiagnostic = diagnostic.previewDiagnostics[index];

    dispatchShowSpinner(dispatch, true);

    const loggedUser = getState().user.data;

    const params = {
      user_name: loggedUser.firstName,
      consecutive: previewDiagnostic.consecutive,
    };

    const [error, diagnosticResponse] = await to<IMedicalCheck>(executeRequest(`delete_attention_diagnostic`, params, 'post'));
    const errorParsed = validateErrorFromClientRequest(error, diagnosticResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    dispatchShowDialog(dispatch, true, 'Diagnóstico eliminado existosamente', 'success');

    pullAt(previewDiagnosticCopy, index);
    dispatch(set_preview_diagnostic(previewDiagnosticCopy));
  };
};

export const save_diagnostic = (index?: number): IThunkResult => {
  return async (dispatch, getState) => {

    if (getState().patient.currentPatient.attentionStatus === 'C') {
      return dispatchShowDialog(dispatch, true, 'Válido solo para citas abiertas', 'warning');
    }

    const diagnostic = getState().diagnostic;

    let params: any = null;

    if (isUndefined(index)) { // Is not Edit
      if (!diagnostic.currentDiagnosticCie) {
        dispatchShowDialog(dispatch, true, 'Seleccione un diagnistico de la lista', 'warning');
        return;
      }

      params = {
        cie_technical_code: diagnostic.currentDiagnosticCie.value,
        observation: diagnostic.medicalCheck,
      };
    } else {
      const previewDiagnostic = diagnostic.previewDiagnostics[index];

      params = {
        cie_technical_code: previewDiagnostic.cieTechnicalCode,
        observation: previewDiagnostic.observation,
        consecutive: previewDiagnostic.consecutive,
      };
    }

    dispatchShowSpinner(dispatch, true);

    const loggedUser = getState().user.data;
    const currentDoctor = getState().medicalHistory.currentDoctor;
    const currentPatient = getState().patient.currentPatient;

    // Default Values
    params.attention_number = currentPatient.attentionNumber;
    params.doctor_id = currentDoctor.value;
    params.user_name = loggedUser.firstName;

    const [error, diagnosticResponse] = await to<IMedicalCheck>(executeRequest(`create_attention_diagnostic`, params, 'post'));
    const errorParsed = validateErrorFromClientRequest(error, diagnosticResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    dispatchShowDialog(dispatch, true, 'Diagnóstico guardado existosamente', 'success');

    batch(() => {
      dispatch(set_current_diagnostic_cie(null));
      dispatch(set_medical_check(null));

      if (isUndefined(index)) { // Is not Edit
        dispatch(get_diagnostic());
      }
    });
  };
};

export const get_historical_diagnostic = (): IThunkResult => {
  return async (dispatch, getState) => {
    dispatchShowSpinner(dispatch, true);

    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    const currentPatient = getState().patient.currentPatient;

    const params = {
      user_name: loggedUser.firstName,
      patient_id: currentPatient.patientUserId,
    };

    const [error, response] = await to<IHistoricalDiagnostic[]>(executeRequest(`attention_diagnostics_transverse`, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, response);
    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    batch(() => {
      dispatch(set_historical_data(response));
      dispatchShowSpinner(dispatch, false);
    });
  };
};
