import { batch } from 'react-redux';

// Third Party modules
import { to } from 'await-to-js';

// Utils
// import Constants from '../../Utils/Constants';
import { dispatchLoadingTable, dispatchShowDialog, dispatchShowSpinner } from '../App/AppUtils';
import { concatResponse, dispatchDataResponse, parsingDates, validateErrorFromClientRequest } from '../ReducersUtils';

// External Actions
// import { set_loading_modal } from '../App/AppActions';

// Service
import executeRequest from '../../Services/ServiceBase';

export enum ExamsRevAndDiagTestActionTypes {
  SET_LAST_ACTION = 'ExamsRevAndDiagTest/SET_LAST_ACTION',
  SET_PENDING_EXAMS = 'ExamsRevAndDiagTest/SET_PENDING_EXAMS',
  SET_CURRENT_PENDING_EXAM = 'ExamsRevAndDiagTest/SET_CURRENT_PENDING_EXAM',
  SET_CURRENT_PAGE = 'ExamsRevAndDiagTest/SET_CURRENT_PAGE',
  SET_ROWS_PER_PAGE = 'ExamsRevAndDiagTest/SET_ROWS_PER_PAGE',
  SET_SEARCH_TERM = 'ExamsRevAndDiagTest/SET_SEARCH_TERM',
  GET_REVIEW_BY_EXAM = 'ExamsRevAndDiagTest/GET_REVIEW_BY_EXAM',
  SET_HISTORICAL_EXAMS = 'ExamsRevAndDiagTest/SET_HISTORICAL_EXAMS',
  GET_PATIENTS_EXAMS_LIST = 'ExamsRevAndDiagTest/GET_PATIENTS_EXAMS_LIST',
  SET_PATIENTS_EXAMS_LIST = 'ExamsRevAndDiagTest/SET_PATIENTS_EXAMS_LIST',
}

export const set_last_action = (value: ExamsRevAndDiagTestActionTypes): IAction => ({ type: ExamsRevAndDiagTestActionTypes.SET_LAST_ACTION, value });

// Pagination
export const set_current_page = (page: number): IAction => ({ type: ExamsRevAndDiagTestActionTypes.SET_CURRENT_PAGE, value: page });
export const set_rows_per_page = (value: number): IAction => ({ type: ExamsRevAndDiagTestActionTypes.SET_ROWS_PER_PAGE, value });
export const set_search_term = (value: string): IAction => ({ type: ExamsRevAndDiagTestActionTypes.SET_SEARCH_TERM, value });
export const set_historical_exams = (value: IReviewByExam[]): IAction => ({ type: ExamsRevAndDiagTestActionTypes.SET_HISTORICAL_EXAMS, value });
export const set_patients_exams_list = (value: IExamList[]): IAction => ({ type: ExamsRevAndDiagTestActionTypes.SET_PATIENTS_EXAMS_LIST, value });

export const set_current_pending_exam = (currentPExam: IPendingExamsResponse, blockRequest?: boolean): IThunkResult => {
  return async (dispatch, getState) => {
    dispatch({ type: ExamsRevAndDiagTestActionTypes.SET_CURRENT_PENDING_EXAM, value: currentPExam });
    if (!blockRequest) {
      dispatch(get_reviews_by_exam(currentPExam));
    }
  };
};

export const set_pending_exams = (value: { data: IPendingExamsResponse[], count: number }): IAction => ({
  type: ExamsRevAndDiagTestActionTypes.SET_PENDING_EXAMS,
  value,
});

export const get_pending_exams = (paginationObj: IPaginationObject): IThunkResult => {
  return async (dispatch, getState) => {

    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    let data: IPendingExamsResponse[] = [];
    let pendingExamsCount: number = -1;

    if (paginationObj.doRequest) {
      dispatchLoadingTable(dispatch, true);

      const currentData = getState().drugFormulation.previewFormulations;
      const page = paginationObj.reset ? 0 : currentData.length;

      const currentPatient = getState().patient.currentPatient;

      const params = {
        patient_id: currentPatient.patientUserId,
        user_name: loggedUser.firstName,
      };

      const [error, pendingExamsResponse] = await to<IPendingExamsResponse[]>(executeRequest(`attention_review_exams_pending`, params, 'get'));
      const errorParsed = validateErrorFromClientRequest(error, pendingExamsResponse);

      if (errorParsed) {
        dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
        return;
      }

      pendingExamsCount = pendingExamsResponse.length;
      const parsedData: IPendingExamsResponse[] = parsingDates(pendingExamsResponse, ['date']);
      data = concatResponse(currentData, parsedData, page);
    }

    dispatchDataResponse(dispatch, {
      setCurrentPageDispatch: set_current_page,
      setRowsPerPageDispatch: set_rows_per_page,
      setDataDispatch: set_pending_exams,
    }, paginationObj, data, pendingExamsCount);
  };
};

export const get_reviews_by_exam = (currentPExam: IPendingExamsResponse): IThunkResult => {
  return async (dispatch, getState) => {

    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    dispatchShowSpinner(dispatch, true);

    const params = {
      attention_number: currentPExam.attentionNumber,
      user_name: loggedUser.firstName,
    };

    const [error, reviewByExamResponse] = await to<IReviewByExam>(executeRequest(`attention_review_exams`, params, 'get'));

    const errorParsed = validateErrorFromClientRequest(error, reviewByExamResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(set_last_action(ExamsRevAndDiagTestActionTypes.GET_REVIEW_BY_EXAM));
    });
  };
};

export const save_review_by_exam = (): IThunkResult => {
  return async (dispatch, getState) => {

    if (getState().patient.currentPatient.attentionStatus === 'C') {
      return dispatchShowDialog(dispatch, true, 'Válido solo para citas abiertas', 'warning');
    }

    const currentPendingExam = getState().examsRevAndDiagTest.currentPendingExam;

    if (!currentPendingExam.evaluation) {
      return dispatchShowDialog(dispatch, true, 'Por favor escriba la evaluación', 'warning');
    }

    dispatchShowSpinner(dispatch, true);

    const loggedUser = getState().user.data;
    const currentDoctor = getState().medicalHistory.currentDoctor;
    const currentPatient = getState().patient.currentPatient;

    // Default Values
    const params = {
      attention_number: currentPatient.attentionNumber,
      doctor_id: currentDoctor.value,
      user_name: loggedUser.firstName,
      consecutive: currentPendingExam.consecutive,
      cup_technical_code: currentPendingExam.cupTechnicalCode,
      diagnostic_help_test: currentPendingExam.cupTechnicalName, // confuse case of params
      evaluation: currentPendingExam.evaluation,
      request_attention_number: currentPendingExam.attentionNumber,
    };

    const [error, reviewExamResponse] = await to(executeRequest(`create_attention_review_exam`, params, 'post'));
    const errorParsed = validateErrorFromClientRequest(error, reviewExamResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    dispatchShowDialog(dispatch, true, 'Revisión guardada existosamente', 'success');
  };
};

export const get_historical_exam_reviews = (): IThunkResult => {
  return async (dispatch, getState) => {
    dispatchShowSpinner(dispatch, true);

    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    const currentPatient = getState().patient.currentPatient;

    const params = {
      user_name: loggedUser.firstName,
      patient_id: currentPatient.patientUserId,
    };

    const [error, response] = await to<IReviewByExam[]>(executeRequest(`attention_review_exams_historic`, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, response);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(set_historical_exams(response));
    });
  };
};

export const get_list_exams = (): IThunkResult => {
  return async (dispatch, getState) => {
    dispatchShowSpinner(dispatch, true);

    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    const currentPatient = getState().patient.currentPatient;

    const params = {
      user_name: loggedUser.firstName,
      patient_id: currentPatient.patientUserId,
    };

    const [error, response] = await to<IExamList[]>(executeRequest(`patient_exams`, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, response);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(set_patients_exams_list(response));
      dispatch(set_last_action(ExamsRevAndDiagTestActionTypes.GET_PATIENTS_EXAMS_LIST));
    });
  };
};
