
// Modules
import 'animate.css';
import React from 'react';
import { Helmet } from 'react-helmet';
import { HashRouter as Router } from 'react-router-dom';

// Navigation
import { history, routes } from '../Routes';

// Scene
import { ContainerProps } from './ContainerScene';

// Components
import DialogWindow from '../Components/DialogWindow';
import SnackbarComponent from '../Components/SnackbarComponent';
import Spinner from '../Components/Spinner';

class Container extends React.Component<ContainerProps> {

  spinnerTimeOut: any;

  //#region LifeCycle
  constructor(props: ContainerProps) {
    super(props);

    this.showDialogWindow = this.showDialogWindow.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.validateAlertAction = this.validateAlertAction.bind(this);
  }

  componentDidUpdate(prevProps: ContainerProps) {
    if (prevProps.app.spinnerValue.type === 'show' && this.props.app.spinnerValue.type === 'none' && this.spinnerTimeOut) {
      clearTimeout(this.spinnerTimeOut);
    }

    if (prevProps.app.spinnerValue.type === 'none' && this.props.app.spinnerValue.type === 'show') {
      clearTimeout(this.spinnerTimeOut);
      const spinnerValue: ISpinnerType = this.props.app.spinnerValue;
      const time: number = spinnerValue.timeDeep ? spinnerValue.timeDeep : 10000;
      if (time !== -1) {
        const alertValue: string = spinnerValue.type;
        this.spinnerTimeOut = setTimeout(() => {
          if (alertValue === 'show') {
            this.props.show_spinner({ type: 'none' });
          }
        }, time);
      }
    }
  }
  //#endregion LifeCycle

  //#region Actions
  handleCloseDialog(dialogObj: IDialogWindowTypes) {
    const copyDialog = Object.assign({}, dialogObj);

    copyDialog.show = false;
    this.props.set_show_dialog(copyDialog);
    this.validateAlertAction();
  }
  //#endregion Actions

  //#region Functions
  validateAlertAction() {
    const alertAction = this.props.app.dialogWindow.snackbar ? this.props.app.dialogWindow.snackbar.action : '';

    switch (alertAction) {
      case 'invalid_session': // Go to login with alert
        history.replace('/login'); // history is a manual configuration in Router comp inside src/index.tsx file
        break;
      default:
        break;
    }
  }
  //#endregion Functions

  //#region Render Functions
  showDialogWindow() {
    const app = this.props.app;

    if (app.dialogWindow && !app.isLoadingTable) {
      return (
        <div>
          {app.dialogWindow.snackbar && this.renderSnackBar(app.dialogWindow.snackbar)}
          {app.dialogWindow.dialogForm && (
            <DialogWindow
              dialogWindow={app.dialogWindow.dialogForm}
              open={app.dialogWindow.dialogForm.show}
              onClose={this.handleCloseDialog}
            />
          )}
        </div>
      );

    }
    return null;
  }

  renderSnackBar(objSnackBar: IDialogWindowTypes) {
    return (
      <SnackbarComponent
        dialogWindow={objSnackBar}
        open={objSnackBar.show}
        onClose={this.handleCloseDialog}
      />
    );
  }

  buildSpinner() {
    const spinnerValue = this.props.app.spinnerValue;
    const alertValue: SpinnerStatesType = spinnerValue.type;
    if (alertValue === 'show' || alertValue === 'loadingInfo') {
      return (
        <Spinner message={alertValue === 'loadingInfo' ? 'Cargando información...' : ''} />
      );
    }
  }

  render() {
    const profile = this.props.user.data ? this.props.user.data.profile : 'Historia Clinica';
    return (
      <div style={{ height: '100%' }}>
        <Helmet>
          <title>{profile}</title>
        </Helmet>
        <Router>
          {routes()}
        </Router>
        {this.showDialogWindow()}
        {this.buildSpinner()}
      </div>
    );
  }
  //#endregion Render Functions
}

export default Container;
