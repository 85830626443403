// Libraries
import { Reducer } from 'redux';

import { UserActionTypes } from './UserActions';

const initialState: IUserReduceType = {
  data: null,
  lastAction: null,
  sessionIsValid: false,
};

const reducer: Reducer<IUserReduceType, IAction> = (state = initialState, action: IAction) => {
  switch (action.type) {
    case UserActionTypes.LOGIN:
      return {
        ...state,
        data: action.value,
        lastAction: UserActionTypes.LOGIN,
      };
    case UserActionTypes.SET_LAST_ACTION:
      return {
        ...state,
        lastAction: action.value,
      };
    case UserActionTypes.SET_SESSION_VALID:
      return {
        ...state,
        sessionIsValid: action.value,
        lastAction: null,
      };
    default:
      return state;
  }
};

export default reducer;
