import createStyles from '@material-ui/core/styles/createStyles';

import { Colors, Fonts, Metrics } from '../../../Theme';

export const drawerWidth = 257;

const styles = () => createStyles({
  drawerLabel: {
    fontSize: Fonts.fontSizeMedium,
  },
  drawerItemHover: {
    '&:hover': {
      background: Colors.drawerHoverBackgroundColor,
    },
  },
  listItemText: {
    color: Colors.drawerChildItemTextColor,
    paddingBottom: Metrics.smallXSMarginOrPaddingBase,
    paddingTop: Metrics.smallXSMarginOrPaddingBase,
  },
});

export default styles;
