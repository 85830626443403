import { to } from 'await-to-js';
import { map } from 'lodash';
import { batch } from 'react-redux';

// Utils
import { dispatchShowDialog, dispatchShowSpinner } from '../App/AppUtils';
import { validateErrorFromClientRequest } from '../ReducersUtils';

// Service
import executeRequest from '../../Services/ServiceBase';

export enum MedicalDisabilityActionTypes {
  GET_MEDICAL_DISABILITY_FINISHED = 'medicalDisability/GET_MEDICAL_DISABILITY_FINISHED',
  SET_LAST_ACTION = 'medicalDisability/SET_LAST_ACTION',
  SET_DISABILITY_ORIGINGS = 'medicalDisability/SET_DISABILITY_ORIGINGS',
  SET_CURRENT_ORIGIN = 'medicalDisability/SET_CURRENT_ORIGIN',
  SET_MEDICAL_DISABILITY = 'medicalDisability/SET_MEDICAL_DISABILITY',
}

export const set_current_origin = (value: IOptionsData): IAction => ({ type: MedicalDisabilityActionTypes.SET_CURRENT_ORIGIN, value });
export const set_disability_origings = (value: IOptionsData[]): IAction => ({ type: MedicalDisabilityActionTypes.SET_DISABILITY_ORIGINGS, value });
export const set_last_action = (value: MedicalDisabilityActionTypes): IAction => ({ type: MedicalDisabilityActionTypes.SET_LAST_ACTION, value });
export const set_medical_disability = (value: IMedicalDisabilityResponse): IAction => ({
  type: MedicalDisabilityActionTypes.SET_MEDICAL_DISABILITY,
  value,
});

export const get_medical_disability = (): IThunkResult => {
  return async (dispatch, getState) => {
    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    dispatchShowSpinner(dispatch, true);

    const currentPatient = getState().patient.currentPatient;

    const params = {
      attention_number: currentPatient.attentionNumber,
      user_name: loggedUser.firstName,
    };

    const [error, medicalDisabilityResponse] = await to<IMedicalDisabilityResponse>(executeRequest(`attention_medical_disability`, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, medicalDisabilityResponse);
    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(set_medical_disability(medicalDisabilityResponse));
      dispatch(set_last_action(MedicalDisabilityActionTypes.GET_MEDICAL_DISABILITY_FINISHED));
    });
  };
};

export const get_disability_origings = (): IThunkResult => {
  return async (dispatch, getState) => {
    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    dispatchShowSpinner(dispatch, true);

    const params = {
      user_name: loggedUser.firstName,
      filter_value: '', // Filter is executed locally
    };
    const [error, originsResponse] = await to<IDisabilityOriginResponse[]>(executeRequest(`disability_origins`, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, originsResponse);
    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    const diagCiesOptions: IOptionsData[] = [{ label: 'Seleccione un origen', value: 'default' }];
    map(originsResponse, (origin: IDisabilityOriginResponse) => {
      diagCiesOptions.push({
        value: origin.originId,
        label: `${origin.originInitials} - ${origin.originName}`,
      });
    });

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(set_disability_origings(diagCiesOptions));
    });
  };
};

export const create_medical_disability = (): IThunkResult => {
  return async (dispatch, getState) => {
    const diagnostic = getState().diagnostic;
    const medicalDisability = getState().medicalDisability;

    if (getState().patient.currentPatient.attentionStatus === 'C') {
      return dispatchShowDialog(dispatch, true, 'Válido solo para citas abiertas', 'warning');
    }

    if (!medicalDisability.currentOrigin.value) {
      dispatchShowDialog(dispatch, true, 'Seleccione un origen', 'warning');
      return;
    }

    if (!medicalDisability.medicalDisabilityData.disabilityStartDate) {
      dispatchShowDialog(dispatch, true, 'Seleccione una fecha de inicio', 'warning');
      return;
    }

    if (!medicalDisability.medicalDisabilityData.disabilityDays) {
      dispatchShowDialog(dispatch, true, 'Por favor ingrese los días de discapacidad', 'warning');
      return;
    }

    dispatchShowSpinner(dispatch, true);

    const currentDoctor = getState().medicalHistory.currentDoctor;
    const currentPatient = getState().patient.currentPatient;
    const loggedUser = getState().user.data;

    const params = {
      attention_number: currentPatient.attentionNumber,
      cie_technical_code: diagnostic.previewDiagnostics[0].cieTechnicalCode,
      doctor_id: currentDoctor.value,
      medical_disability_id: medicalDisability.medicalDisabilityData.disabilityId,
      origin_id: medicalDisability.currentOrigin.value,
      quantity_days: medicalDisability.medicalDisabilityData.disabilityDays,
      start_date: medicalDisability.medicalDisabilityData.disabilityStartDate,
      user_name: loggedUser.firstName,
      extension: medicalDisability.medicalDisabilityData.extension,
    };

    const [error, response] = await to(executeRequest(`create_attention_medical_disability`, params, 'post'));
    const errorParsed = validateErrorFromClientRequest(error, response);
    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    const [medicalDisabilityError, medicalDisabilityResponse] = await to<IMedicalDisabilityResponse>(executeRequest(
      `attention_medical_disability`, params, 'get',
    ));
    const medicalDisabilitysErrorErrorParsed = validateErrorFromClientRequest(medicalDisabilityError, medicalDisabilityResponse);
    if (medicalDisabilitysErrorErrorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    medicalDisability.medicalDisabilityData.disabilityId = medicalDisabilityResponse.disabilityId;

    dispatchShowDialog(dispatch, true, 'Incapacidad médica guardada existosamente', 'success');
    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(set_medical_disability(medicalDisability.medicalDisabilityData));
    });
  };
};
