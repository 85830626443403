import { batch } from 'react-redux';

// Third Party modules
import { to } from 'await-to-js';
import { each } from 'lodash';
import moment from 'moment';

// Utils
// import Constants from '../../Utils/Constants';
import { dispatchShowDialog, dispatchShowSpinner } from '../App/AppUtils';
import { validateErrorFromClientRequest } from '../ReducersUtils';

// External Actions

// Service
import executeRequest from '../../Services/ServiceBase';

export enum EvolutionNoteActionTypes {
  SET_LAST_ACTION = 'evolutionNote/SET_LAST_ACTION',
  SET_PREVIEW_DIAGNOSTIC_NOTES = 'evolutionNote/SET_PREVIEW_DIAGNOSTIC_NOTES',
  SET_NOTE = 'evolutionNote/SET_NOTE',
  SET_HISTORICAL_DATA = 'evolutionNote/SET_HISTORICAL_DATA',
}

export const set_last_action = (value: EvolutionNoteActionTypes): IAction => ({ type: EvolutionNoteActionTypes.SET_LAST_ACTION, value });
export const set_note = (value: string): IAction => ({ type: EvolutionNoteActionTypes.SET_NOTE, value });
export const set_historical_data = (value: IHistoricalEvolutionNote[]): IAction => ({ type: EvolutionNoteActionTypes.SET_HISTORICAL_DATA, value });

export const set_preview_diagnostic_notes =
  (value: IPreviewDiagnosticNotes[]): IAction => ({ type: EvolutionNoteActionTypes.SET_PREVIEW_DIAGNOSTIC_NOTES, value });

export const get_diagnostics_previous = (): IThunkResult => {
  return async (dispatch, getState) => {

    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    dispatchShowSpinner(dispatch, true);

    const currentPatient = getState().patient.currentPatient;

    const params = {
      patient_id: currentPatient.patientUserId,
      user_name: loggedUser.firstName,
    };

    const [error, previewDiagnosticsResponse] = await to<IPreviewDiagnosticNotes[]>(executeRequest(`diagnostics_previous`, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, previewDiagnosticsResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    each(previewDiagnosticsResponse, (prevDiag) => {
      prevDiag.date = moment(prevDiag.date).format('DD/MM/YYYY');
    });

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(set_preview_diagnostic_notes(previewDiagnosticsResponse));
    });
  };
};

export const get_current_evolution_note = (): IThunkResult => {
  return async (dispatch, getState) => {

    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    dispatchShowSpinner(dispatch, true);

    const currentPatient = getState().patient.currentPatient;

    const params = {
      attention_number: currentPatient.attentionNumber,
      user_name: loggedUser.firstName,
    };

    const [error, currentNoteResponse] = await to<IPreviewDiagnosticNotes>(executeRequest(`attention_evolution_note`, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, currentNoteResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(set_note(currentNoteResponse.evolutionDescription));
    });
  };
};

export const save_evolution = (index?: number): IThunkResult => {
  return async (dispatch, getState) => {

    if (getState().patient.currentPatient.attentionStatus === 'C') {
      return dispatchShowDialog(dispatch, true, 'Válido solo para citas abiertas', 'warning');
    }

    const evolutionNote = getState().evolutionNote;

    if (!evolutionNote.note) {
      dispatchShowDialog(dispatch, true, 'Por favor digite la nota', 'warning');
      return;
    }

    dispatchShowSpinner(dispatch, true);

    const loggedUser = getState().user.data;
    const currentDoctor = getState().medicalHistory.currentDoctor;
    const currentPatient = getState().patient.currentPatient;

    // Default Values
    const params = {
      attention_number: currentPatient.attentionNumber,
      description: evolutionNote.note,
      doctor: currentDoctor.value,
      user_name: loggedUser.firstName,
    };

    const [error, evolutionResponse] = await to(executeRequest(`create_evolution_note`, params, 'post'));
    const errorParsed = validateErrorFromClientRequest(error, evolutionResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    dispatchShowDialog(dispatch, true, 'Nota de evolución guardada existosamente', 'success');
  };
};

export const get_historical_evolutions_note = (): IThunkResult => {
  return async (dispatch, getState) => {
    dispatchShowSpinner(dispatch, true);

    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    const currentPatient = getState().patient.currentPatient;

    const params = {
      user_name: loggedUser.firstName,
      patient_id: currentPatient.patientUserId,
    };

    const [error, response] = await to<IHistoricalEvolutionNote[]>(executeRequest(`attention_evolution_notes_transverse`, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, response);
    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    batch(() => {
      dispatch(set_historical_data(response));
      dispatchShowSpinner(dispatch, false);
    });
  };
};
