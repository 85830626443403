// Libraries
import { Reducer } from 'redux';

// Constants
import { BackgroundActionTypes } from './BackgroundActions';

const initialState: IBackgroundReducerType = {
  lastAction: null,
  backgroundGroups: null,
  userBackgrounds: {},
  historical: [],
};

const reducer: Reducer<IBackgroundReducerType, IAction> = (state = initialState, action: IAction): IBackgroundReducerType => {
  switch (action.type) {
    case BackgroundActionTypes.SET_PREVIOUS_USER_BACKGROUND:
      return {
        ...state,
        userBackgrounds: action.value,
      };
    case BackgroundActionTypes.SET_LAST_ACTION:
      return {
        ...state,
        lastAction: action.value,
      };
    case BackgroundActionTypes.SET_BACKGROUND_GROUPS:
      return {
        ...state,
        backgroundGroups: action.value,
      };
    case BackgroundActionTypes.SET_HISTORICAL_BACKGROUND:
      return {
        ...state,
        historical: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
