// Libraries
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators } from 'redux';

// Component
import Layout from '.';

// Actions
import { set_last_action, show_back } from '../../Reducers/App/AppActions';
import { validate_session } from '../../Reducers/User/UserActions';

//#region TS Interfaces and Types
interface IOwnProps {
  children: any;
  history?: HistoryType; // Prop inject in router Navigator
  classes?: any; // Styles inject in withStyles
  location?: any;
}
type TMapStateToProps = ReturnType<typeof mapStateToProps>;
type TMapDispatchToProps = ReturnType<typeof mapDispatchToProps>;

export type LayoutProps = IOwnProps & TMapStateToProps & TMapDispatchToProps & RouteComponentProps;
//#endregion TS Interfaces and Types

const mapStateToProps = (state: IRootState) => {
  return {
    app: state.app,
    patient: state.patient,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatchType) => bindActionCreators({
  // AppActions
  set_last_action,
  show_back,
  // UserActions
  validate_session,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
