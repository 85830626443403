// Libraries
import { Reducer } from 'redux';

// Constants
import { ConsultationReasonActionTypes } from './ConsultationReasonActions';

const initialState: IConsultationReasonReducerType = {
  lastAction: null,
  data: {},
};

const reducer: Reducer<IConsultationReasonReducerType, IAction> = (state = initialState, action: IAction): IConsultationReasonReducerType => {
  switch (action.type) {
    case ConsultationReasonActionTypes.SET_LAST_ACTION:
      return {
        ...state,
        lastAction: action.value,
      };
    case ConsultationReasonActionTypes.SET_CONSULTAION_REASON:
      return {
        ...state,
        data: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
