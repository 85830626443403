// Libraries
import { Reducer } from 'redux';

// Constants
import { EvolutionNoteActionTypes } from './EvolutionNoteActions';

const initialState: IEvolutionNoteReducerType = {
  lastAction: null,
  previewDiagnosticNotes: [],
  note: null,
  historical: [],
};

const reducer: Reducer<IEvolutionNoteReducerType, IAction> = (state = initialState, action: IAction): IEvolutionNoteReducerType => {
  switch (action.type) {
    case EvolutionNoteActionTypes.SET_LAST_ACTION:
      return {
        ...state,
        lastAction: action.value,
      };
    case EvolutionNoteActionTypes.SET_PREVIEW_DIAGNOSTIC_NOTES:
      return {
        ...state,
        previewDiagnosticNotes: action.value,
      };
    case EvolutionNoteActionTypes.SET_NOTE:
      return {
        ...state,
        note: action.value,
      };
    case EvolutionNoteActionTypes.SET_HISTORICAL_DATA:
      return {
        ...state,
        historical: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
