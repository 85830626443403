import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Material
import { WithStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

// Component
import DrawerComp from '../DrawerComp';

// Actions
import { set_open_drawer, set_show_dialog } from '../../Reducers/App/AppActions';
import { close_atention, set_last_action } from '../../Reducers/Patient/PatientActions';

// Styles
import Styles from './DrawerCompStyles';

//#region TS Interfaces and Types
interface IOwnProps {
  theme?: Theme; // Prop inject in withStyles->withTheme
  width?: string; // Prop inject in withWidth
  history?: HistoryType; // Prop inject in router Navigator
}
type TMapStateToProps = ReturnType<typeof mapStateToProps>;
type TMapDispatchToProps = ReturnType<typeof mapDispatchToProps>;

export type DrawerProps = IOwnProps & TMapStateToProps & TMapDispatchToProps & WithStyles<typeof Styles>;
//#endregion TS Interfaces and Types

const mapStateToProps = (state: IRootState) => {
  return {
    app: state.app,
    patient: state.patient,
    physicalExam: state.physicalExam,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatchType) => bindActionCreators({
  // AppActions
  set_open_drawer,
  set_show_dialog,
  // PatientActions
  close_atention,
  set_last_action,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DrawerComp);
