// Libraries
import { Reducer } from 'redux';

// Constants
import { AppActionTypes } from './AppActions';

const initialState: IAppReducerType = {
  dialogWindow: {
    snackbar: null,
    dialogForm: null,
  },
  spinnerValue: {
    type: 'none',
  },
  isOpenDrawer: true,
  isLoadingDropdown: false,
  isLoadingTable: false,
  isLoadingDrawer: false,
  isLoadingModal: false,
  showBackButton: false,
  lastAction: '',
};

const reducer: Reducer<IAppReducerType, IAction> = (state = initialState, action: IAction): IAppReducerType => {
  switch (action.type) {
    case AppActionTypes.SHOW_DIALOG:
      return {
        ...state,
        dialogWindow: action.value,
        spinnerValue: {
          type: 'none',
        },
        isLoadingTable: false,
        isLoadingDropdown: false,
      };
    case AppActionTypes.SHOW_SPINNER:
      return {
        ...state,
        spinnerValue: action.value,
      };
    case AppActionTypes.SET_OPEN_DRAWER:
      return {
        ...state,
        isOpenDrawer: action.value,
      };
    case AppActionTypes.SET_LOADING_DROPDOWN:
      return {
        ...state,
        isLoadingDropdown: action.value,
      };
    case AppActionTypes.SET_LOADING_TABLE:
      return {
        ...state,
        isLoadingTable: action.value,
      };
    case AppActionTypes.SET_LOADING_DRAWER:
      return {
        ...state,
        isLoadingDrawer: action.value,
      };
    case AppActionTypes.SET_LOADING_MODAL:
      return {
        ...state,
        isLoadingModal: action.value,
      };
    case AppActionTypes.SHOW_BACK:
      return {
        ...state,
        showBackButton: action.value,
        lastAction: AppActionTypes.SHOW_BACK,
      };
    case AppActionTypes.LAST_ACTION:
      return {
        ...state,
        lastAction: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
