// Libraries
import { to } from 'await-to-js';
import { cloneDeep, each, findIndex, map, pullAt, trim } from 'lodash';
import moment from 'moment';
import { batch } from 'react-redux';

// Services
import executeRequest from '../../Services/ServiceBase';

// Utils
import {
  dispatchLoadingTable,
  dispatchShowDialog,
  dispatchShowSpinner,
} from '../App/AppUtils';
import {
  concatResponse,
  dispatchDataResponse,
  parsingDates,
  validateErrorFromClientRequest,
} from '../ReducersUtils';

// External Actions
import { set_loading_dropdown } from '../App/AppActions';

// Constants
import Constants from '../../Utils/Constants';

export enum MedicalHistoryActionTypes {
  SET_FILTER_VALUE = 'medicalHistory/SET_FILTER_VALUE',
  SET_APPOINTMENTS_DATA = 'medicalHistory/SET_APPOINTMENTS_DATA',
  SET_SELECTED_APPOINTMENT = 'medicalHistory/SET_SELECTED_APPOINTMENT',
  SET_CURRENT_PAGE = 'medicalHistory/SET_CURRENT_PAGE',
  SET_ROWS_PER_PAGE = 'medicalHistory/SET_ROWS_PER_PAGE',
  SET_CURRENT_DOCTOR = 'medicalHistory/SET_CURRENT_DOCTOR',
  SET_DOCTORS_DATA = 'medicalHistory/SET_DOCTORS_DATA',
  SET_REPORT_DATA = 'medicalHistory/SET_REPORT_DATA',
  SET_REPORT_DATA_NO_EMAIL = 'medicalHistory/SET_REPORT_DATA_NO_EMAIL',
  SET_APPOINTMENT_SEARCH_TYPE = 'medicalHistory/SET_APPOINTMENT_SEARCH_TYPE',
  SET_SEARCH_PATIENT_TERM = 'medicalHistory/SET_SEARCH_PATIENT_TERM',
  SET_DROPDOWN_PATIENTS = 'medicalHistory/SET_DROPDOWN_PATIENTS',
  SET_SELECTED_DROPDOWN_PATIENT = 'medicalHistory/SET_SELECTED_DROPDOWN_PATIENT',
  SET_HISTORIC_APPOINTMENTS = 'medicalHistory/SET_HISTORIC_APPOINTMENTS',
  SET_PAYMENTS_METHODS = 'medicalHistory/SET_PAYMENTS_METHODS',
  SET_SELECTED_REPORT_TYPE = 'medicalHistory/SET_SELECTED_REPORT_TYPE',
  SET_LAST_ACTION = 'medicalHistory/SET_LAST_ACTION',
}

export const set_appointments_data = (value: { data: IPatientData[], count: number }): IAction => {
  const label: BeautyStatus = {
    CF: 'Sin Llegar',
    CE: 'En Espera',
    CM: 'En Atencion',
    CC: 'Atendido',
    AC: 'Sin Pagar',
  };

  const newData = map(value.data, (element) => {
    const key = element.appointmentState;
    const labelKey = label[key] as any;
    element.appointmentStateBeauty = labelKey ? labelKey : element.appointmentState;

    return element;
  });

  return {
    type: MedicalHistoryActionTypes.SET_APPOINTMENTS_DATA,
    value: { data: newData, count: value.count },
  };
};

export const set_last_action = (value?: MedicalHistoryActionTypes): IAction => ({ type: MedicalHistoryActionTypes.SET_LAST_ACTION, value });

export const set_current_page = (page: number): IAction => ({ type: MedicalHistoryActionTypes.SET_CURRENT_PAGE, value: page });

export const set_rows_per_page = (value: number): IAction => ({ type: MedicalHistoryActionTypes.SET_ROWS_PER_PAGE, value });

export const set_filter_value = (value: string): IAction => ({ type: MedicalHistoryActionTypes.SET_FILTER_VALUE, value });

export const set_doctors_data = (value: IOptionsData[]): IAction => ({ type: MedicalHistoryActionTypes.SET_DOCTORS_DATA, value });

export const set_report_data = (value: IReportInfo): IAction => ({ type: MedicalHistoryActionTypes.SET_REPORT_DATA, value });
export const set_selected_report_type = (value: IReportType): IAction => ({ type: MedicalHistoryActionTypes.SET_SELECTED_REPORT_TYPE, value });

export const set_payments_methods = (value: IPaymentMethods[]): IAction => ({ type: MedicalHistoryActionTypes.SET_PAYMENTS_METHODS, value });

export const set_selected_appointment = (value: IPatientData): IAction => ({ type: MedicalHistoryActionTypes.SET_SELECTED_APPOINTMENT, value });

export const set_search_patient_term = (value: string): IAction => ({ type: MedicalHistoryActionTypes.SET_SEARCH_PATIENT_TERM, value });

export const set_dropdown_patients = (value: IOptionsData[]): IAction => ({ type: MedicalHistoryActionTypes.SET_DROPDOWN_PATIENTS, value });

export const set_appointment_search_type = (value: AppointmentsSearchType): IThunkResult => {
  return async (dispatch, getState) => {
    const medicalHistory = getState().medicalHistory;
    const appointments = cloneDeep(value === 'historic' ? medicalHistory.historicAppointments : medicalHistory.appointments);

    dispatch({
      type: MedicalHistoryActionTypes.SET_APPOINTMENT_SEARCH_TYPE,
      value: {
        search_type: value,
        count: appointments.length,
      },
    });
  };
};

export const set_historic_appointments = (value: IPatientData[]): IAction => {
  return {
    type: MedicalHistoryActionTypes.SET_HISTORIC_APPOINTMENTS,
    value: {
      data: value,
      count: value.length,
    },
  };
};

export const set_selected_dropdown_patient = (value: IOptionsData): IThunkResult => {
  return async (dispatch) => {
    batch(() => {
      dispatch({ type: MedicalHistoryActionTypes.SET_SELECTED_DROPDOWN_PATIENT, value });
      dispatch(set_search_patient_term(value ? value.label : null));
      if (value) {
        dispatch(get_patient_history(value));
      }
    });
  };
};

export const get_patient_history = (selectedDropDownPatient: IOptionsData): IThunkResult => {
  return async (dispatch, getState) => {
    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    dispatchShowSpinner(dispatch, true);

    const params = {
      user_name: loggedUser.firstName,
      patient_id: selectedDropDownPatient.value,
    };

    const [error, patientsHisResponse] = await to<IPatientData[]>(executeRequest(`patient_attention_historic`, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, patientsHisResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(set_historic_appointments(patientsHisResponse));
    });

  };
};

export const get_patient_by_filter = (): IThunkResult => {
  return async (dispatch, getState) => {
    const loggedUser = getState().user.data;
    if (!loggedUser) {
      return;
    }

    const searchPatientTerm = getState().medicalHistory.searchPatientTerm;
    if (!searchPatientTerm) {
      dispatchShowDialog(dispatch, true, `Escriba el termino de búsqueda`, 'warning');
      return;
    }

    dispatch(set_loading_dropdown(true));

    const [error, patientsResponse] = await to<IUserInfo[]>(executeRequest(`get_user_info_filter`, { filter: searchPatientTerm }, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, patientsResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    const dropdownOptions: IOptionsData[] = [{ label: 'Seleccione un valor', value: 'default' }];

    map(patientsResponse, (eachPatient) => {
      const lastName = eachPatient.lastName ? `${eachPatient.lastName}` : '';
      const fullName = `${trim(eachPatient.firstName)} ${trim(lastName)}`;
      dropdownOptions.push({
        value: eachPatient.id,
        label: `${eachPatient.documentId} - ${fullName}`,
      });
    });

    batch(() => {
      dispatch(set_loading_dropdown(false));
      dispatch(set_dropdown_patients(dropdownOptions));
    });

  };
};

export const set_current_doctor = (value: IOptionsData, avoidAppointmentsRequest?: boolean,data?): IThunkResult => {
  return async (dispatch, getState) => {
    dispatch({ type: MedicalHistoryActionTypes.SET_CURRENT_DOCTOR, value });
    console.log(value.start_date)
    if (avoidAppointmentsRequest) {
      return;
    }

    return dispatch(get_appointments_data({
      page: 0,
      rowsPerPage: getState().medicalHistory.rowsPerPage,
      doRequest: true,
      reset: true,
      start_date:value.start_date,
      end_date:value.end_date
    },data));
  };
};

export const get_doctors_by_assitant = (): IThunkResult => {
  return async (dispatch, getState) => {
    dispatchShowSpinner(dispatch, true);

    const userId = getState().user.data.id;
    // doctor_id: works for id from medic and for id from assitant
    const [error, doctorsResponse] = await to<IDoctors[]>(executeRequest(`doctors`, { doctor_id: userId }, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, doctorsResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    const doctorsOptions: IOptionsData[] = [{ label: 'Seleccione un Médico', value: 'default' }];

    map(doctorsResponse, (doctor: IDoctors) => {
      doctorsOptions.push({
        label: doctor.doctorName,
        value: doctor.doctorUserId,
      });
    });

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(set_doctors_data(doctorsOptions));
    });
  };
};

/**
 * get_appointments_data
 * @param paginationObj List pagination obj (page, rowPerPage, doRequest, reset)
 */
export const get_appointments_data = (paginationObj: IPaginationObject,data?): IThunkResult => {
  return async (dispatch, getState) => {

    let data: IPatientData[] = [];
    let appointmentsCount: number = -1;

    if (paginationObj.doRequest) {
      dispatchLoadingTable(dispatch, true);
      const currentData = getState().medicalHistory.appointments;
      const page = paginationObj.reset ? 0 : currentData.length;

      const params = {
        appointment_state: '%',
        start_date: paginationObj.start_date?paginationObj.start_date:moment().format('YYYY/MM/DD'),
        end_date:paginationObj.end_date?paginationObj.end_date:moment().add(4,'days').format('YYYY/MM/DD'),
        doctor_id: getState().medicalHistory.currentDoctor.value,
        // date: '2020/08/21',
        // doctor_id: '5657B181-FA1F-415D-A147-19DDBA55CA87',
      };

      const [error, appointmetsResponse] = await to<IPatientData[]>(executeRequest(`appointments`, params, 'get'));
      const errorParsed = validateErrorFromClientRequest(error, appointmetsResponse);

      if (errorParsed) {
        dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
        return;
      }

      appointmentsCount = appointmetsResponse.length;
      const parsedData: IPatientData[] = parsingDates(appointmetsResponse, ['appointmentDate', 'arrivalTime'], true);
      data = concatResponse(currentData, parsedData, page);
    }

    dispatchDataResponse(dispatch, {
      setCurrentPageDispatch: set_current_page,
      setRowsPerPageDispatch: set_rows_per_page,
      setDataDispatch: set_appointments_data,
    }, paginationObj, data, appointmentsCount);
  };
};

export const update_arrival_time = (patientAppointment: IPatientData): IThunkResult => {
  return async (dispatch, getState) => {
    dispatchShowSpinner(dispatch, true);

    const params = {
      appointment_id: patientAppointment.appointmentId,
    };

    const [error, arrivalTimeResponse] = await to<IPatientData[]>(executeRequest(`update_arrival_date`, params, 'post'));
    const errorParsed = validateErrorFromClientRequest(error, arrivalTimeResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    const appointments = getState().medicalHistory.appointments;
    let findSomeCoincidence = false;

    map(appointments, (appointment) => {
      if (appointment.appointmentId === patientAppointment.appointmentId && !appointment.updateData) {
        appointment.appointmentState = 'CE';
        appointment.arrivalTime = moment().format();
        appointment.arrivalTimeBeauty = moment().format('hh:mm a');
        appointment.updateData = false;
        findSomeCoincidence = true;
      }

      return appointment;
    });

    dispatchShowSpinner(dispatch, false);

    if (!findSomeCoincidence) {
      dispatchShowDialog(dispatch, true, Constants.alert_messages.update_patient_data, 'info');
      return;
    }

    batch(() => {
      dispatchShowDialog(dispatch, true, Constants.alert_messages.register_updated, 'success');
      dispatch(set_appointments_data({ data: appointments, count: getState().medicalHistory.totalPatients }));
    });
  };
};

export const get_reports_data = (appointmetSelected: IPatientData, reportType: IReportType, sendEmail: Boolean): IThunkResult => {
  return async (dispatch, getState) => {
    batch(() => {
      dispatchShowSpinner(dispatch, true);
      dispatch(set_selected_appointment(appointmetSelected));
      dispatch(set_selected_report_type(reportType));
    });

    const loggedUser = getState().user.data;

    // bf60a5ba-4cc4-4efa-9efe-4e09ef2afd3a // evolution note
    // D309BCDD-701C-46F7-AB64-963A94FB149A // others
    const params: any = {
      appointment_id: appointmetSelected.appointmentId,
      patient_id: appointmetSelected.patientUserId,
      user_name: loggedUser.firstName,
    };

    if (appointmetSelected.attentionNumber) {
      params.attention_number = appointmetSelected.attentionNumber;
    }

    let requestName = 'get_reports_data';

    if (reportType === 'clinicHistory') {
      requestName = 'get_clinic_history';
    }

    const [error, reportDataResponse] = await to<IReportInfo>(executeRequest(requestName, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, reportDataResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    const patientInfo = reportDataResponse.patientInfo;
    if (patientInfo) {
      patientInfo.birthDate = moment(patientInfo.birthDate).format('DD/MM/YYYY');
    }

    const medicalDisability = reportDataResponse.medicalDisability;
    if (medicalDisability && medicalDisability.disabilityStartDate && medicalDisability.disabilityDays) {
      const originalStartDate = cloneDeep(medicalDisability.disabilityStartDate);
      const parsedStartDate = moment(medicalDisability.disabilityStartDate).format('DD/MM/YYYY');
      medicalDisability.disabilityStartDate = parsedStartDate;
      medicalDisability.disabilityEndDate = moment(originalStartDate).add(medicalDisability.disabilityDays, 'days').format('DD/MM/YYYY');
    }

    const medicalHistory = getState().medicalHistory;
    const appointmentSearchType = medicalHistory.appointmentSearchType;
    const appointments = cloneDeep(appointmentSearchType === 'historic' ? medicalHistory.historicAppointments : medicalHistory.appointments);

    each(appointments, (appointment) => {
      if (appointment.appointmentId === appointmetSelected.appointmentId) {
        appointment.readyToDownloadReport = true;
        return false;
      }
    });

    batch(() => {
      dispatchShowSpinner(dispatch, false);
      dispatch(set_report_data(reportDataResponse));
      if (appointmentSearchType === 'historic') {
        dispatch(set_historic_appointments(appointments));
      } else {
        dispatch(set_appointments_data({ data: appointments, count: getState().medicalHistory.totalPatients }));
      }
      
      if(!sendEmail){ 
        dispatchShowDialog(dispatch, true, Constants.alert_messages.pdf_report_success_no_email, 'success');
        dispatch(set_last_action(MedicalHistoryActionTypes.SET_REPORT_DATA_NO_EMAIL));
      }else{
        dispatchShowDialog(dispatch, true, Constants.alert_messages.pdf_report_success, 'success');
        dispatch(set_last_action(MedicalHistoryActionTypes.SET_REPORT_DATA));
      }     
    });
  };
};

export const send_report_email = (file: Blob, patient_id?: string): IThunkResult => {
  return async (dispatch, getState) => {
    const params = {
      patient_id: patient_id ? patient_id : getState().medicalHistory.selectedAppointment.patientUserId,
    };

    const [error, reportDataResponse] = await to<IReportInfo>(executeRequest(`send_pdf_email`, params, 'post', false, 20, file));
    const errorParsed = validateErrorFromClientRequest(error, reportDataResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }
  };
};

export const get_payment_methods = (): IThunkResult => {
  return async (dispatch, getState) => {

    dispatchShowSpinner(dispatch, true);

    const loggedUser = getState().user.data;
    const params = {
      user_name: loggedUser.firstName,
    };

    const [error, paymentsMethodsResponse] = await to<IPaymentMethods[]>(executeRequest(`payment_methods`, params, 'get'));
    const errorParsed = validateErrorFromClientRequest(error, paymentsMethodsResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    batch(() => {
      dispatch(set_payments_methods(paymentsMethodsResponse));
      dispatchShowSpinner(dispatch, false);
    });
  };
};

export const register_payment = (appointmetSelected: IPatientData, paymentOption: string): IThunkResult => {
  return async (dispatch, getState) => {

    dispatchShowSpinner(dispatch, true);

    const loggedUser = getState().user.data;

    const params = {
      appointment_id: appointmetSelected.appointmentId,
      appointment_value: appointmetSelected.payment,
      user_name: loggedUser.firstName,
      patient_id: appointmetSelected.patientUserId,
      payment_option: parseInt(paymentOption, 10),
    };

    const [error, paymentsResponse] = await to<IPaymentMethods[]>(executeRequest(`register_payment`, params, 'post'));
    const errorParsed = validateErrorFromClientRequest(error, paymentsResponse);

    if (errorParsed) {
      dispatchShowDialog(dispatch, true, errorParsed.message, 'error');
      return;
    }

    const medicalHistory = getState().medicalHistory;
    const appointments = cloneDeep(medicalHistory.appointments);

    for (const appointment of appointments) {
      if (appointment.appointmentId === appointmetSelected.appointmentId) {
        appointment.appointmentState = 'CF';
      }
    }

    batch(() => {
      dispatchShowDialog(dispatch, true, 'Pago registrado existosamente', 'success');
      dispatchShowSpinner(dispatch, false);
      dispatch(set_appointments_data({ data: appointments, count: appointments.length }));
    });
  };
};
