import { each, find, findIndex, forOwn, includes } from 'lodash';

import { copyObject } from './../../Utils/index';

export const buildVisualExamsFormat =
  (variablesResponse: IVariableExamsResponse[], preChargedVariables: IPreChargedVariables[]): IVariableVisualExams => {
    const validVisualAcuityMeasurements: string[] = ['AV', 'AV PH', 'AV P', 'AV CC', 'AV PCC', 'Lensometría', 'Adición'];
    const visualAcuity: IVariableVisualRow[] = formatVariableRow(variablesResponse, validVisualAcuityMeasurements, preChargedVariables);

    const validBinocularAcuityMeasurements: string[] = ['AV', 'AVP'];
    const binocularVisualAcuity: IVariableVisualRow[] = formatVariableRow(variablesResponse, validBinocularAcuityMeasurements, preChargedVariables);

    const validDominantEyeMeasurements: string[] = ['Ojo Dominante'];
    const dominantEye: IVariableVisualRow[] = formatVariableRow(variablesResponse, validDominantEyeMeasurements, preChargedVariables);

    const validKeratometryMeasurements: string[] = ['K1', 'K2', 'Eje'];
    const keratometry: IVariableVisualRow[] = formatVariableRow(variablesResponse, validKeratometryMeasurements, preChargedVariables);

    return {
      visualAcuity,
      binocularVisualAcuity,
      dominantEye,
      keratometry,
    };
  };

export const buildRefractionExamsFormat =
  (variablesResponse: IVariableExamsResponse[], preChargedVariables: IPreChargedVariables[]): IVariableRefractorExams => {
    const validObjectiveMeasurements: string[] = ['Esfera', 'Cilindro', 'Eje', 'AV'];
    const objectiveRefraction: IVariableVisualRow[] = formatVariableRow(variablesResponse, validObjectiveMeasurements, preChargedVariables, 'Rfo');

    const validSubjectiveMeasurements: string[] = ['Esfera', 'Cilindro', 'Eje', 'AV', 'ADD', 'AV P'];
    const subjectiveRefraction: IVariableVisualRow[] = formatVariableRow(variablesResponse, validSubjectiveMeasurements, preChargedVariables, 'Rfs');

    const validCycloplegicMeasurements: string[] = ['Esfera', 'Cilindro', 'Eje', 'AV', 'AV P'];
    const cycloplegicRefract: IVariableVisualRow[] = formatVariableRow(variablesResponse, validCycloplegicMeasurements, preChargedVariables, 'Rfc');

    return {
      cycloplegicRefraction: cycloplegicRefract,
      objectiveRefraction,
      subjectiveRefraction,
    };
  };

export const formatVariableRow: FormatVariableRowFunction =
  (variablesResponse, validMeasurements, preChargedVariables, extraFilter?): IVariableVisualRow[] => {
    const variableRow: IVariableVisualRow[] = [];

    each(variablesResponse, (eachVar) => {
      const examMeasurement = eachVar.examVariableLabel;

      if (includes(validMeasurements, examMeasurement)) {
        let isValidVar = true;
        if (extraFilter) {
          isValidVar = includes(eachVar.examVariableId, extraFilter);
        }

        if (!isValidVar) {
          return true; // Continue
        }

        let visualAcuityRow: IVariableVisualRow = {
          examVariableLabel: examMeasurement,
          dataTypeId: eachVar.dataTypeId,
        };

        const rowAlreadyExist = find(variableRow, { examVariableLabel: examMeasurement });
        if (rowAlreadyExist) {
          visualAcuityRow = rowAlreadyExist;
        }

        const isLeftEye = includes(eachVar.examVariable, 'quierdo');
        if (isLeftEye) {
          visualAcuityRow.examVariableId2 = eachVar.examVariableId;
          visualAcuityRow.id2 = eachVar.id;
          visualAcuityRow.dropdownItems2 = eachVar.list;
        } else {
          visualAcuityRow.examVariableId1 = eachVar.examVariableId;
          visualAcuityRow.id1 = eachVar.id;
          visualAcuityRow.dropdownItems1 = eachVar.list;
        }

        each(preChargedVariables, (preCharged) => {
          if (preCharged.examVariableId === visualAcuityRow.examVariableId1) {
            visualAcuityRow.value1 = preCharged.result;
            visualAcuityRow.isTest = true;
          }

          if (preCharged.examVariableId === visualAcuityRow.examVariableId2) {
            visualAcuityRow.value2 = preCharged.result;
            visualAcuityRow.isTest = true;
          }
        });

        if (!rowAlreadyExist) {
          variableRow.push(visualAcuityRow);
        }
      }
    });

    return variableRow;
  };

const getVariableList = (listsResponse: IVariableListResponse[], examVariable: string): IOptionsData[] => {
  const listItems: IOptionsData[] = [];

  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < listsResponse.length; i++) {
    const listItem = listsResponse[i];

    if (listItem.examVariable === examVariable) {
      listItems.push({
        value: listItem.examVariableControlValueId,
        label: listItem.examVariableControlValue,
      });
    }
  }

  return listItems;
};

export const buildExamsTypeInputFormat = (
  variablesResponse: IVariableExamsResponse[],
  listsResponse: IVariableListResponse[],
  preChargedVariables: IPreChargedVariables[],
): IFieldsGroup[] => {
  const fieldsGroup: IFieldsGroup[] = [];

  for (const variable of variablesResponse) {
    variable.list = getVariableList(listsResponse, variable.examVariable);

    if (!variable.examVariable.toLocaleLowerCase().includes('ojo')) {
      fieldsGroup.push({
        label: variable.examVariableLabel,
        fields: [variable],
      });
    }
    else {
      const fieldGroupAlreadyExist = find(fieldsGroup, { label: variable.examVariableLabel });

      each(preChargedVariables, (preCharged) => {
        const examVariableIdIsEqual = preCharged.examVariableId === variable.examVariableId;

        if (examVariableIdIsEqual && !variable.listId) {
          variable.isTest = true;
          variable.value = preCharged.result;
          variable.id = preCharged.id;
        }
        else if (examVariableIdIsEqual && variable.listId) {
          variable.isTest = true;
          variable.listValue = preCharged.result;
          variable.value = preCharged.observation;
          variable.id = preCharged.id;
        }
      });

      if (fieldGroupAlreadyExist) {
        const index = findIndex(fieldsGroup, { label: variable.examVariableLabel });
        const fieldGroup = fieldsGroup[index];
        fieldGroup.fields.push(variable);
      }
      else {
        fieldsGroup.push({
          label: variable.examVariableLabel,
          fields: [variable],
        });
      }
    }
  }

  return fieldsGroup;
};

export const buildSaveToMotilityAndColorExamsFormat = (
  physicalExam: IPhysicalExamReducerType,
  selectedEspecializedExams: number,
): [ISaveSpecializedExams[], IFieldsGroup[]] => {
  const examVariables: IFieldsGroup[] = copyObject(selectedEspecializedExams === 3 ? physicalExam.motility : physicalExam.colorTest);
  const examVariablesFormated: ISaveSpecializedExams[] = [];

  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < examVariables.length; i++) {
    const examVariable = examVariables[i];
    const fields = examVariable.fields;

    // tslint:disable-next-line:prefer-for-of
    for (let j = 0; j < fields.length; j++) {
      const field = fields[j];
      const haveValue = field.listValue || field.value;
      const inputsShouldNotRender = ['diagnóstico refractivo'];
      const setDefaultValue = field.dataTypeId === 2 && !field.listId && !inputsShouldNotRender.includes(field.examVariableLabel.toLowerCase());

      if (haveValue || setDefaultValue) {
        const observation = field.listValue ? field.value : null;
        let result = field.listValue ? field.listValue : field.value;

        if (!field.value && setDefaultValue) {
          result = 'Normal';
        }

        examVariablesFormated.push({
          exam_variable_id: field.examVariableId,
          id: field.id,
          is_edit: field.isTest,
          observation,
          result,
          status: 'A',
        });

        if (!field.value && setDefaultValue) {
          field.isTest = true;
          field.value = 'Normal';
        }
      }
    }
  }

  return [examVariablesFormated, examVariables];
};

/**
 * Build correct format for visual exams and refactor exams.
 * @param exams Exams block
 */
export const buildSaveExamsFormat = (exams: IVariableVisualExams | IVariableRefractorExams): ISaveSpecializedExams[] => {
  const specializedExamsToSave: ISaveSpecializedExams[] = [];
  forOwn(exams, (examsSections: IVariableVisualRow[], key) => {
    each(examsSections, (variable) => {
      if (variable.value1) {
        specializedExamsToSave.push({
          id: variable.id1,
          result: variable.value1,
          exam_variable_id: variable.examVariableId1,
          is_edit: variable.isTest,
          status: 'A',
          observation: null,
        });
      }

      if (variable.value2) {
        specializedExamsToSave.push({
          id: variable.id2,
          result: variable.value2,
          exam_variable_id: variable.examVariableId2,
          is_edit: variable.isTest,
          status: 'A',
          observation: null,
        });
      }
    });
  });

  return specializedExamsToSave;
};
