// Libraries
import { Reducer } from 'redux';

// Constants
import Constants from '../../Utils/Constants';
import { CupActionTypes } from './CupActions';

const initialState: ICupReducerType = {
  lastAction: null,
  tabPosition: 0,
  currentCup: null,
  previewCups: [],
  searchCupTerm: null,
  cupOptions: [],
  cupSelected: null,
  cupsDropdownResponse: null,
  currentPage: 0,
  rowsPerPage: Constants.limits.page_limit,
  totalCups: 0,
  searchTerm: null,
  historical: [],
  doctorLookUpDefault: false,
};

const reducer: Reducer<ICupReducerType, IAction> = (state = initialState, action: IAction): ICupReducerType => {
  switch (action.type) {
    case CupActionTypes.SET_LAST_ACTION:
      return {
        ...state,
        lastAction: action.value,
      };
    case CupActionTypes.SET_TAB_POSITION:
      return {
        ...state,
        tabPosition: action.value,
      };
    case CupActionTypes.SET_CURRENT_CUP:
      return {
        ...state,
        currentCup: action.value,
      };
    case CupActionTypes.SET_PREVIEW_CUPS:
      return {
        ...state,
        previewCups: action.value.data,
        totalCups: action.value.count,
      };
    case CupActionTypes.SET_SEARCH_CUP_TERM:
      return {
        ...state,
        searchCupTerm: action.value,
      };
    case CupActionTypes.SET_CUP_OPTIONS:
      return {
        ...state,
        cupOptions: action.value,
      };
    case CupActionTypes.SET_SELECTED_SEARCH_CUP:
      return {
        ...state,
        cupSelected: action.value,
      };
    case CupActionTypes.SET_CUP_RESPONSE:
      return {
        ...state,
        cupsDropdownResponse: action.value,
      };
    case CupActionTypes.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.value,
      };
    case CupActionTypes.SET_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.value,
      };
    case CupActionTypes.SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.value,
      };
    case CupActionTypes.SET_HISTORICAL_DATA:
      return {
        ...state,
        historical: action.value,
      };
    case CupActionTypes.SET_DEFAULT_LOOKUP:
      return {
        ...state,
        doctorLookUpDefault: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
